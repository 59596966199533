/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { PRODUCTSTATUS } from '../model/models';
import { ProductReq } from '../model/models';
import { ProductResponse } from '../model/models';
import { SORTORDER } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProductService {

    protected basePath = 'https://staging-api.papers-official.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param productReq 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProduct(productReq: ProductReq, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public createProduct(productReq: ProductReq, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public createProduct(productReq: ProductReq, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public createProduct(productReq: ProductReq, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productReq === null || productReq === undefined) {
            throw new Error('Required parameter productReq was null or undefined when calling createProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (jwt) required
        credential = this.configuration.lookupCredential('jwt');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductResponse>(`${this.configuration.basePath}/product/create`,
            productReq,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProduct(productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public deleteProduct(productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public deleteProduct(productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public deleteProduct(productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (jwt) required
        credential = this.configuration.lookupCredential('jwt');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<ProductResponse>(`${this.configuration.basePath}/product/delete/${encodeURIComponent(String(productId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param filter 
     * @param sortOrder 
     * @param sortBy 
     * @param userId 
     * @param currencyId 
     * @param productStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllProducts(pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, currencyId?: number, productStatus?: PRODUCTSTATUS, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getAllProducts(pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, currencyId?: number, productStatus?: PRODUCTSTATUS, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getAllProducts(pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, currencyId?: number, productStatus?: PRODUCTSTATUS, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getAllProducts(pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, currencyId?: number, productStatus?: PRODUCTSTATUS, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (currencyId !== undefined && currencyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currencyId, 'currencyId');
        }
        if (productStatus !== undefined && productStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productStatus, 'productStatus');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/list`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productId 
     * @param userId 
     * @param currencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProduct(productId: string, userId?: string, currencyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getProduct(productId: string, userId?: string, currencyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getProduct(productId: string, userId?: string, currencyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getProduct(productId: string, userId?: string, currencyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProduct.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (currencyId !== undefined && currencyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currencyId, 'currencyId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/detail/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param title 
     * @param pageNumber 
     * @param pageSize 
     * @param filter 
     * @param sortOrder 
     * @param sortBy 
     * @param userId 
     * @param tagId 
     * @param catId 
     * @param colorId 
     * @param currencyId 
     * @param collectionId 
     * @param brandId 
     * @param productStatus 
     * @param stockLocationIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductByAdvanceSearch(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getProductByAdvanceSearch(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getProductByAdvanceSearch(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getProductByAdvanceSearch(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (title !== undefined && title !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>title, 'title');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (tagId !== undefined && tagId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tagId, 'tagId');
        }
        if (catId !== undefined && catId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>catId, 'catId');
        }
        if (colorId !== undefined && colorId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>colorId, 'colorId');
        }
        if (currencyId !== undefined && currencyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currencyId, 'currencyId');
        }
        if (collectionId !== undefined && collectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>collectionId, 'collectionId');
        }
        if (brandId !== undefined && brandId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>brandId, 'brandId');
        }
        if (productStatus !== undefined && productStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productStatus, 'productStatus');
        }
        if (stockLocationIds !== undefined && stockLocationIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stockLocationIds, 'stockLocationIds');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/search/advance`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param title 
     * @param pageNumber 
     * @param pageSize 
     * @param filter 
     * @param sortOrder 
     * @param sortBy 
     * @param userId 
     * @param tagId 
     * @param catId 
     * @param colorId 
     * @param currencyId 
     * @param collectionId 
     * @param brandId 
     * @param productStatus 
     * @param stockLocationIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductByName(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getProductByName(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getProductByName(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getProductByName(title?: string, pageNumber?: number, pageSize?: number, filter?: string, sortOrder?: SORTORDER, sortBy?: string, userId?: string, tagId?: number, catId?: number, colorId?: number, currencyId?: number, collectionId?: number, brandId?: number, productStatus?: PRODUCTSTATUS, stockLocationIds?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (title !== undefined && title !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>title, 'title');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (tagId !== undefined && tagId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tagId, 'tagId');
        }
        if (catId !== undefined && catId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>catId, 'catId');
        }
        if (colorId !== undefined && colorId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>colorId, 'colorId');
        }
        if (currencyId !== undefined && currencyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currencyId, 'currencyId');
        }
        if (collectionId !== undefined && collectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>collectionId, 'collectionId');
        }
        if (brandId !== undefined && brandId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>brandId, 'brandId');
        }
        if (productStatus !== undefined && productStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productStatus, 'productStatus');
        }
        if (stockLocationIds !== undefined && stockLocationIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stockLocationIds, 'stockLocationIds');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductRelatedColor(productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getProductRelatedColor(productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getProductRelatedColor(productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getProductRelatedColor(productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductRelatedColor.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/related-color/${encodeURIComponent(String(productId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductRelatedProduct(productId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public getProductRelatedProduct(productId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public getProductRelatedProduct(productId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public getProductRelatedProduct(productId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductRelatedProduct.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProductResponse>(`${this.configuration.basePath}/product/related-product/${encodeURIComponent(String(productId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productId 
     * @param productReq 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProduct(productId: string, productReq: ProductReq, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProductResponse>;
    public updateProduct(productId: string, productReq: ProductReq, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProductResponse>>;
    public updateProduct(productId: string, productReq: ProductReq, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProductResponse>>;
    public updateProduct(productId: string, productReq: ProductReq, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProduct.');
        }
        if (productReq === null || productReq === undefined) {
            throw new Error('Required parameter productReq was null or undefined when calling updateProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (jwt) required
        credential = this.configuration.lookupCredential('jwt');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductResponse>(`${this.configuration.basePath}/product/update/${encodeURIComponent(String(productId))}`,
            productReq,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
