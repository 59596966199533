import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Api} from '../services/api.service';
import {LoaderService} from '../services/loader.service';
import {AlertService} from '../services/alert.service';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {TableDataSource} from '../common/interfaces/common.interface';
import {SettingsService as ApiSettingService} from '../../../client/api/settings.service';
import {
    SettingsPayload,
    SettingsResponse,
    IUpdateMainBanners,
    ISocialRefs,
    IUpdateProductNewIn,
    IUpdateContentSettings,
    IMemberSettings
} from '../../../client';
import {IUpdateSeasonBanners} from '../../../client/model/iUpdateSeasonBanners';
import {IUpdateBottomBanner} from '../../../client/model/iUpdateBottomBanner';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiSettingService: ApiSettingService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) {
    }

    updateMainBanners(objectBanner: IUpdateMainBanners) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiSettingService.updateMainBanners(objectBanner).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateBottomBanner(objectBanner: IUpdateBottomBanner) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiSettingService.updateBottomBanner(objectBanner).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateSeasonBanners(objectBanner: IUpdateSeasonBanners) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiSettingService.updateSeasonBanners(objectBanner).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateNewIn(obj: IUpdateProductNewIn) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiSettingService.updateProductNewIn(obj).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateSocial(youtube, instaImages) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const obj = {
                yt_url: youtube,
                instaImages: instaImages,
            } as ISocialRefs;
            const seq = this.apiSettingService.updateSocialRefs(obj).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    getAllBanner() {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiSettingService.getSettings().pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                console.log(err);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateTextDisplay(pickUpTextMsg) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const obj = {
                pickUpTextMsg: pickUpTextMsg,
            } as IUpdateContentSettings;
            const seq = this.apiSettingService.updateContentSettings(obj).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    updateBonusSetting(bonusMultiplier, bonusMonth) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const obj = {
                bonusMultiplier: bonusMultiplier,
                bonusMonth: bonusMonth
            } as IMemberSettings;
            const seq = this.apiSettingService.updateMemberSettings(obj).pipe(share());
            seq.subscribe((res: SettingsResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                    this.authService.logout();
                    this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }
}
