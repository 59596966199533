import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AlertService  } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TableDataSource } from '../common/interfaces/common.interface';
import { ContentService as ApiContentService } from '../../../client/api/content.service';
import {
    ContentPayload,
    ContentResponse,
    IContent
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiContentService: ApiContentService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) { }currency

    createContent(content: IContent) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiContentService.createContent(content).pipe(share());
          seq.subscribe((res: ContentResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              // if (err && err?.error?.name === 'NoAuthorization') {
              //   this.authService.logout();
              //   this.router.navigateByUrl('login');
              // }
              reject();
          });
      });
    }

    updateContent(id, data: IContent) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiContentService.updateContent(id, data).pipe(share());
          seq.subscribe((res: ContentResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              // if (err && err?.error?.name === 'NoAuthorization') {
              //   this.authService.logout();
              //   this.router.navigateByUrl('login');
              // }
              reject();
          });
      });
    }
    
    deleteContentById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiContentService.deleteContent(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              // if (err && err?.error?.name === 'NoAuthorization') {
              //   this.authService.logout();
              //   this.router.navigateByUrl('login');
              // }
              reject();
          });
      });
    }

    getAllContents(tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiContentService.getAllContents(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: ContentResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
              this.loadingService.showLoader(false);
              // if (err && err?.error?.name === 'NoAuthorization') {
              //   this.authService.logout();
              //   this.router.navigateByUrl('login');
              // }
              reject();
          });
      });
    }
      

      getContentById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiContentService.getContent(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              // if (err && err?.error?.name === 'NoAuthorization') {
              //   this.authService.logout();
              //   this.router.navigateByUrl('login');
              // }
              reject();
          });
      });
    }
}
