import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guards';

const routes: Routes = [
  { path: '', loadChildren: () => import('./authentication/login/login.module').then(m => m.LoginModule),},
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./authentication/login/login.module').then(m => m.LoginModule),
      }
    ]
  },
  // { path: '', loadChildren: () => import('./authentication/main-authentication/main-authentication.module').then(m => m.MainAuthenticationModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.DashboardModule) },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
