
<ng-container *ngIf="isAddress">
    <h2 mat-dialog-title>กรุณาเลือก :: <strong>ที่อยู่</strong></h2>
    <mat-dialog-content class="mat-typography">
        <div fxLayout="row wrap" *ngIf="this.listLength !== 0">
            <div fxFlex="100">
                <div>
                    <div class="row" *ngFor="let data of this.addressList;">
                        <div class="col-1" style="margin-top: 15px;">
                            <input type="radio" id="html" class="custom-radio" name="fav_language" 
                            [checked]="data.id === this.address?.id" 
                            [value]="true"
                            (change)="handleChangeAddress($event, data)">
                        </div>
                        <div class="col-8">
                        <label class="d-block font14-re-70 mt-3">{{ this.case?.name}}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.address }}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.address2 }}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.province }}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.zipcode }}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.country }}</label>
                        <label class="d-block font14-re-70 mt-1">{{ data?.contactNum }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>
<ng-container *ngIf="!isAddress">
    <h2 mat-dialog-title>กรุณาเลือก :: <strong>ลูกค้า</strong></h2>
    <mat-dialog-content class="mat-typography">
        <div fxLayout="row justify-content-end">
            <div class="col-4 pe-2">
                <mat-form-field>
                  <mat-label>ประเภทลูกค้า</mat-label>
                  <select matNativeControl (change)="selectedCustomerSearch($event)" [(ngModel)]="selectedCustomer">
                    <option value="username">username</option>
                    <option value="firstname">ชื่อ</option>
                    <option value="lastname">นามสกุล</option>
                    <option value="contactNum">เบอร์</option>
                  </select>
                </mat-form-field>
              </div>
            <div class="col-4">
                <mat-form-field>
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหารายชื่อ">
                  <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
                </mat-form-field>
              </div>
        </div>
        <app-blank *ngIf="this.listLength === 0"></app-blank>
        <div fxLayout="row wrap" *ngIf="this.listLength !== 0">
            <div fxFlex="100">
                <mat-card>
                <mat-card-content>
                    <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
                        <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            <input type="radio" value="item"    [checked]="item === this.local_data"  (change)="onSelectCustomer(item)" >
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> ชื่อ </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.firstName }} 
                        </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="surname">
                            <th mat-header-cell *matHeaderCellDef> นามสกุล </th>
                            <td mat-cell *matCellDef="let item; let i = index;"> 
                                {{ item.lastName }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> อีเมล </th>
                            <td mat-cell *matCellDef="let item; let i = index;"> 
                                {{ item.email }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef> เบอร์โทร </th>
                            <td mat-cell *matCellDef="let item; let i = index;"> 
                                {{ item.contactNum }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> สถานะ </th>
                            <td mat-cell *matCellDef="let item; let i = index;"> 
                                {{ item.Member?.memberType }} 
                            </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator class="pagination" [length]="listLength" [pageSize]="tableOptions.pageSize"
                        [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
                    </mat-paginator>
                    </div>
                </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>
<div mat-dialog-actions align="end">
    <button (click)="doAction()" mat-flat-button color="warn">Select</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
  