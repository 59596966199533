
<div fxLayout="row wrap">
  <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
      <h2 class="page-title m-0">รายการขอคืน</h2>
      <small class="page-title m-0">จำนวน {{ userLength }} รายการ</small>
  </div>
</div>
<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="align-items-center">
          <div fxFlex="80">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา">
              <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20" class="text-center">
            <!-- <button mat-raised-button (click)="openDialog('Add',{})" color="primary">เพิ่มสินค้าใหม่</button> -->
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<app-blank *ngIf="this.userLength === 0"></app-blank>
<div fxLayout="row wrap" *ngIf="this.userLength !== 0">
  <div fxFlex="40" class="text-right">
  </div>
  <div fxFlex="20" style="padding: 20px;" class="text-right">
    <mat-form-field>
      <mat-label>Status</mat-label>
      <select matNativeControl (change)="selectReturnStatusFn($event)">
        <option *ngFor="let option of returnStatusList;" [value]="option.value"
                [selected]="selectReturnStatus === option.value">{{ option.text }}</option>
      </select>
    </mat-form-field>
  </div>
  <div fxFlex="20" style="padding: 20px;" class="text-right">
    <mat-form-field>
      <mat-label>Sort By</mat-label>
      <select matNativeControl (change)="selectSort($event)">
        <option *ngFor="let option of sortList;" [value]="option.value"
                [selected]="selectedSort === option.value">{{ option.text }}</option>
      </select>
    </mat-form-field>
  </div>
  <div fxFlex="20" style="padding: 20px;" class="text-right">
    <mat-form-field>
      <mat-label>Arrange By</mat-label>
      <select matNativeControl (change)="selectSortBy($event)" [(ngModel)]="selectedSortBy">
        <option value="asc">ASC</option>
        <option value="desc">DESC</option>
      </select>
    </mat-form-field>
  </div>
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> รายการ </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <!-- <img class="mat-card-avatar" [src]='element.imagePath'> -->
                  <div class="m-l-15">
                    <a (click)="gotoDetail(element)" target="_blank"><p class="font-bold m-b-0 m-t-0 cursor-pointer">{{ element.name }}</p></a>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="customer">
              <th mat-header-cell *matHeaderCellDef> ชื่อเล่น </th>
              <td mat-cell *matCellDef="let element"> 
                <a (click)="gotoCustomerDetail(element)" target="_blank"><p class="font-bold m-b-0 m-t-0 cursor-pointer">{{ element.customerName }}</p></a>
              </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef> มูลค่า </th>
              <td mat-cell *matCellDef="let element"> {{element.creditsEarned | number }} ({{element.totalQty | number }} QTY) </td>
            </ng-container>

            <ng-container matColumnDef="orderDate">
              <th mat-header-cell *matHeaderCellDef> วันที่ส่งสินค้า </th>
              <td mat-cell *matCellDef="let element"> {{element.orderDate | date: 'dd/MM/yyyy':'UTC+7' }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> สถานะนำส่ง </th>
              <td mat-cell *matCellDef="let element">
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-warning' : element.active === 'PENDING_DLVR'}" *ngIf="element.active === 'PENDING_DLVR'">{{ element.active === 'PENDING_DLVR' ? 'รอส่ง' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-danger' : element.active === 'CANCEL'}" *ngIf="element.active === 'CANCEL'">{{ element.active === 'CANCEL' ? 'ยกเลิก' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-primary' : element.active === 'SUCCESS'}" *ngIf="element.active === 'SUCCESS'">{{ element.active === 'SUCCESS' ? 'สำเร็จ' : '-' }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="payment">
              <th mat-header-cell *matHeaderCellDef> วันที่ลูกค้าส่งคืน </th>
              <td mat-cell *matCellDef="let element"> 
                {{ (element.trackingDate | date: 'dd/MM/yyyy':'UTC+7') || '-' }} 
              </td>
            </ng-container>

            <ng-container matColumnDef="tracking">
              <th mat-header-cell *matHeaderCellDef> สถานะการคืน </th>
              <td mat-cell *matCellDef="let element; let i = index;"> 
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-warning' : element.returnStatus === 'OTW'}" *ngIf="element.returnStatus === 'OTW'">{{ element.returnStatus === 'OTW' ? 'กำลังส่งคืน' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-danger' : element.returnStatus === 'NONE'}" *ngIf="element.returnStatus === 'NONE'">{{ element.returnStatus === 'NONE' ? 'ปฏิเสธ' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-primary' : element.returnStatus === 'DONE'}" *ngIf="element.returnStatus === 'DONE'">{{ element.returnStatus === 'DONE' ? 'คืนสำเร็จ' : '-' }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> tracking No (ลูกค้า) </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ item.returnTrackingNo || '-' }} </td>
            </ng-container>

            <ng-container matColumnDef="tracking2">
              <th mat-header-cell *matHeaderCellDef> tracking No (admin) </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ item.adminConfirmTrackingNo || '-' }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-link">
                <a (click)="addTracking(element, true)" class="m-r-10 cursor-pointer text-dark"><mat-icon>keyboard_return</mat-icon></a>
                <a (click)="addTracking(element, false)" class="m-r-10 cursor-pointer text-dark"><mat-icon>more_vert</mat-icon></a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="pagination" [length]="userLength" [pageSize]="tableOptions.pageSize"
            [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
          </mat-paginator>
          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
