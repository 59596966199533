<ng-container>
    <h2 mat-dialog-title>Credit List</h2>
    <mat-dialog-content class="mat-typography">
        <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
  
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> index </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ i + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> เครดิต </th>
              <td mat-cell *matCellDef="let element"> {{element.amount | number }} </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef> วันที่ </th>
              <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/yyyy':'UTC+7'}} </td>
            </ng-container>

            <ng-container matColumnDef="expireDate">
              <th mat-header-cell *matHeaderCellDef> วันที่หมดอายุ </th>
              <td mat-cell *matCellDef="let element"> {{element.expireDate | date: 'dd/MM/yyyy':'UTC+7' }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </mat-dialog-content>
  
    <div mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
    </div>
  </ng-container>