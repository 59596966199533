<div *ngIf="isShow" class="loader-bg">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>


<!-- <div class="preloader"  *ngIf="isShow">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div> -->