<ng-container *ngIf="status === 'add'">
  <h2 mat-dialog-title>Row Action :: <strong>เพิ่ม Tracking No</strong></h2>
  <mat-dialog-content class="mat-typography">
    <form #userForm="ngForm">
    <mat-form-field *ngIf="this.deliveryMethod !== 'PICK_UP'">
      <input type="text" matInput required id="trackingNo" name="trackingNo" [(ngModel)]="trackingNo"
        placeholder="trackingNo">
    </mat-form-field>

    <div *ngIf="this.deliveryMethod === 'PICK_UP'">
      <mat-form-field>
        <mat-label></mat-label>
        <input matInput [matDatepicker]="pickered"
                [(ngModel)]="pickupDate"
                [ngModelOptions]="{standalone: true}"
                readonly
               (dateInput)="addEvent($event)" (dateChange)="addEvent($event)">
        <mat-datepicker-toggle matSuffix [for]="pickered"></mat-datepicker-toggle>
        <mat-datepicker #pickered></mat-datepicker>
      </mat-form-field>
    </div>
    </form>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">Save</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
  </div>
</ng-container>

<ng-container *ngIf="status === 'delete'">
  <h2 mat-dialog-title>Row Action :: <strong>ยกเลิก Order</strong></h2>
  <mat-dialog-content class="mat-typography">
    <form #userForm="ngForm">
    <mat-form-field>
      <input type="text" matInput required id="trackingNo" name="trackingNo" [(ngModel)]="trackingNo"
        placeholder="เหตุผล">
    </mat-form-field>
    </form>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">Save</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
  </div>
</ng-container>



<ng-container *ngIf="status === 'deleted'">
  <h2 mat-dialog-title>Row Action :: <strong>ต้องการยกเลิก order หรือไม่?</strong></h2>
  <div mat-dialog-actions align="end">
    <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">Save</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
  </div>
</ng-container>