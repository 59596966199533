import { Component, OnInit, Inject, Optional, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { AlertService } from '../services/alert.service';
import { USER_TYPE } from '../common/constants';
import { Router } from '@angular/router';
import { OrderService } from '../services/order.service';
import { ISafeStockLocation, OrderReq, OrderResponse, ReturnOptConfirm, RETURNSTATUS, SORTORDER, StockLocationResponse } from '../../../client';
import { environment } from '../../environments/environment';
import { add } from 'lodash';
import * as _ from 'lodash';
import { StocksService } from '../services/stock.service';
@Component({
  selector: 'app-admin-sales-return',
  templateUrl: './admin-sales-return.component.html',
  styleUrls: ['./admin-sales-return.component.scss']
})
export class AdminSalesReturnComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'name', 'customer', 'price', 'orderDate', 'payment', 'status', 'tracking', 'date', 'tracking2', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  userLength: any;
  selectedSort = 'invoiceId';
  selectedSortBy = SORTORDER.Desc;
  selectReturnStatus = 'ALL';
  sortList = [{
    value: 'invoiceId',
    text: 'invoice'
  },{
    value: 'updatedAt',
    text: 'Last Updated'
  }];

  returnStatusList = [{
    value: 'ALL',
    text: 'ทั้งหมด'
  },{
    value: RETURNSTATUS.DONE,
    text: 'สำเร็จ'
  },{
    value: RETURNSTATUS.OTW,
    text: 'กำลังนำส่ง'
  },{
    value: RETURNSTATUS.NONE,
    text: 'ปฏิเสธ'
  }];
  mapdata;
  orderSum;
  stockLocationList: ISafeStockLocation[];
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(
    public dialog: MatDialog,
    public router: Router,
    public alertService: AlertService,
    public orderService: OrderService,
    private stockService: StocksService,
    public userService: UserService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getData();
    this.getStockLocationList();
  }

  

  async getStockLocationList() {
    const tableOptions = {
        pageNumber: 0,
        pageSize: 100,
        filter: null,
        sortOrder: SORTORDER.Desc
    };
    const data = await this.stockService.getStockLocation(tableOptions) as StockLocationResponse;
    this.stockLocationList = data.payload.stockLocations;
  }

  async getData(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    this.tableOptions.sortBy = this.selectedSort;
    const result = await this.orderService.getAllOrder(null, null, null, null, this.selectReturnStatus, null, this.tableOptions) as any;
    if(result && result.payload.orders.length > 0) {

      this.mapdata = result.payload.orders.map(it => {
        return {
          id: it.id,
          date: it.updatedAt,
          name: it.invoiceId,
          // color: !!it.product.Colors ? `(${it.product.Colors[0]?.title})` : '',
          customerName: `${it.customer?.firstName} ${it.customer?.lastName}`,
          customerId: it.customer?.id,
          price: it.total,
          orderDate: it.delivery.deliveryDate,
          trackingDate: it.orderProducts.find(dd => dd.returnOpt && !!dd.returnOpt?.trackingNo)?.returnOpt?.trackingDate,
          adminConfirmDate: it.orderProducts.find(dd => dd.returnOpt && !!dd.returnOpt?.adminConfirmTrackingNo)?.returnOpt?.adminConfirmDate,
          active: it.orderStatus,
          payment: it.paymentType,
          returnStatus: it.orderProducts[0].returnOpt.status,
          orderProducts: it.orderProducts,
          returnTrackingNo: it.orderProducts.find(dd => dd?.returnOpt && !!dd.returnOpt?.trackingNo)?.returnOpt?.trackingNo,
          adminConfirmTrackingNo: it.orderProducts.find(dd => dd?.returnOpt && !!dd.returnOpt?.adminConfirmTrackingNo)?.returnOpt?.adminConfirmTrackingNo,
          totalQty: it.orderProducts.map(dd => dd.returnOpt.qty).reduce((a, b) => a + b),
          totalPrice: it.orderProducts.map(dd => Number(dd.returnOpt.qty) * Number(dd.trade_price)).reduce((a, b) => a + b),
          additionalInfo: it.additionalInfo,
          creditsEarned: it.creditsEarned
        }
      });
      console.log(this.mapdata);
      this.dataSource = new MatTableDataSource(this.mapdata);
      this.userLength = result.payload.listLength;
      // TODO:: มูลค่าที่ลูกค้าขอคืน Confirm
      this.orderSum = result.payload.ordersSum;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
      }
    }
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: this.selectedSortBy,
      sortBy: this.selectedSort
    };
  }

  gotoDetail(el) {
    this.router.navigate(['/dashboard/sales/'+el.id]);
  }

  async applyFilter(data, pageIndex?: any) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    }
      if (data.length === 0) {
          this.getData();
          this.setupTableOptions();
          this.searchText = null;
      } else {
        this.searchText = data;
      }

    if (data.length > 2) {
      const result = await this.orderService.getAllOrder(this.searchText,null,null,null, 'ALL', null, this.tableOptions) as any;
      if (result) {
        if(result && result.payload.orders.length > 0) {
          const mapdata = result.payload.orders.map(it => {
            return {
              id: it.id,
              date: it.updatedAt,
              name: it.invoiceId,
              color: !!it.product?.Colors ? `(${it.product?.Colors[0]?.title})` : '',
              customerName: `${it.customer?.firstName} ${it.customer?.lastName}`,
              customerId: it.customer?.id,
              price: it.total,
              orderDate: it.delivery.deliveryDate,
              active: it.orderStatus,
              payment: it.paymentType,
              trackingNo: it.trackingNo,
              returnStatus: it.orderProducts[0].returnOpt.status,
              orderProducts: it.orderProducts,
              trackingDate: it.orderProducts.find(dd => dd.returnOpt && !!dd.returnOpt.trackingNo)?.returnOpt?.trackingDate,
              returnTrackingNo: it.orderProducts.find(dd => dd.returnOpt && !!dd.returnOpt.trackingNo)?.returnOpt?.trackingNo,
              adminConfirmTrackingNo: it.orderProducts.find(dd => dd.returnOpt && !!dd.returnOpt.adminConfirmTrackingNo)?.returnOpt?.adminConfirmTrackingNo,
              totalQty: it.orderProducts.map(dd => dd.returnOpt.qty).reduce((a, b) => a + b),
              totalPrice: it.orderProducts.map(dd => Number(dd.returnOpt.qty) * Number(dd.trade_price)).reduce((a, b) => a + b),
              additionalInfo: it.additionalInfo,
              creditsEarned: it.creditsEarned
            }
          });
          console.log(mapdata);
          this.dataSource = new MatTableDataSource(mapdata);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          this.userLength = result.payload.listLength;
          this.orderSum = result.payload.ordersSum;
        } else {
          this.dataSource = new MatTableDataSource();
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          this.userLength = 0;
        }
      } else {
          this.dataSource = new MatTableDataSource();
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          this.userLength = 0;
      }
    }
  }

  openDialog(a, b) {

  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    if(!!!this.searchText) {
      this.getData(pageObj.pageIndex);
    } else {
      this.applyFilter(this.searchText, pageObj.pageIndex);
    }
  }

  selectSort(e) {
    console.log(e.target.value);
    this.selectedSort = e.target.value;
    this.setupTableOptions();
    this.getData();
  }

  selectSortBy(e) {
    this.selectedSortBy = e.target.value;
    this.setupTableOptions();
    this.getData();
  }

  selectReturnStatusFn(e) {
    this.selectReturnStatus = e.target.value;
    this.setupTableOptions();
    this.getData();
  }


  addTracking(e, isApprove) {
    const dialogRef = this.dialog.open(ReturnApproverDialogContent, {
      data: [e, isApprove, this.stockLocationList],
      minWidth: '700px'
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);
      if(!!!result || result.event === 'Cancel') {
        return;
      } else {
        
      }

      let req = {
        orderId: e.id,
        orderProducts: e.orderProducts.map(it => {
          return {
            orderProductId: it.id,
            status: result.data[0] === 'Submit' ? 'DONE' : 'NONE',
            comment: result.data[0] !== 'Submit' ? result.data[1] : '',
            adminConfirmTrackingNo: '-',
            targetStockLocationId: result.data[2]?.find(dd => dd.id === it.id)?.targetStockLocationId || ''
          }
        })
      } as ReturnOptConfirm;
      const resultReq = await this.orderService.saveComfirmReturnOrder(req);
      if(resultReq) {
        this.getData(this.tableOptions.pageNumber);
      }
    });
  }

  gotoCustomerDetail(el) {
    this.router.navigate(['/dashboard/customer/'+el.customerId]);
  }

}





@Component({
  selector: 'return-confirm-dialog',
  templateUrl: 'return-confirm-dialog.html',
})
export class ReturnApproverDialogContent {
  displayedColumns22: string[] = ['name', 'reason', 'qty', 'price', 'saleItem', 'image', 'status', 'comment', 'stock'];
  dataSource22 = new MatTableDataSource();
  isApprove = false;
  btnTxt = 'ปฏิเสธ';
  comment = null;
  status;
  returnTrackingNo;
  stockLocationList: ISafeStockLocation[];
  constructor(
      public dialogRef: MatDialogRef<ReturnApproverDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: Location) {
      this.setting(data[0]);
      this.isApprove = data[1];
      this.stockLocationList = data[2];
  }

  setting(element) {
    if(!element && element.length === 0) {
      return;
    }
    console.log(element);
    let dataList;
    if(element.orderProducts?.length > 0) {
      dataList = element.orderProducts.map(it => {
        this.comment = it.returnOpt.comment;
        const saleItemModel = !!element.additionalInfo?.salesPerItem ? element.additionalInfo?.salesPerItem.find(dd => it.ProductId === dd.productId) : null;
        return {
          name: it.product?.title,
          reason: it.returnOpt?.reason?.toString(),
          qty: it.returnOpt?.qty,
          id: it.id,
          color: !!it.product?.Colors ? `(${it.product?.Colors[0]?.title})` : '',
          price: Number(it.returnOpt?.qty) * Number(it.trade_price),
          status: it.returnOpt?.status === 'NONE' ? 'Reject' : it.returnOpt?.status,
          comment: it.returnOpt?.comment || '-',
          saleItem: saleItemModel,
          priceTotal: (Number(it.product.price) - (saleItemModel?.total || 0)) * Number(it.qty),
          targetStockLocationId: it.returnOpt?.targetStockLocationId,
          stockName: it.returnOpt?.targetStockLocationId ? this.getStockName(it.returnOpt?.targetStockLocationId) : '',
          returnImgs: it.returnOpt?.returnImgs?.map(ad => {
            return {
              img: `${environment.backendUri}/image/`+ ad.id,
              path: ad.path
            }
          })
        }
       });
    this.status = dataList[0].status;
    } else {
      dataList = [];
    }

    // this.instaImages = data.payload.settings.socialRefs.instaImages.map(it => {
    //   return {
    //     img: `${environment.backendUri}/image/`+ it.img.id,
    //     bannerUrl: it.bannerUrl
    //   }
    // });
    console.log(dataList);
    this.returnTrackingNo = element.returnTrackingNo;
    this.dataSource22 = new MatTableDataSource(dataList); 
    this.dataSource22._updateChangeSubscription();
  }

  getStockName(id) {
    const findStockname = this.stockLocationList?.find(it => id = it.id);
    return findStockname?.title;
  }

  doAction(action) {
    if(action === 'Reject' && this.comment === null) {
      this.btnTxt = 'ยืนยันปฏิเสธ';
    } else {
      this.dialogRef.close({ event: action, data: [action, this.comment, this.dataSource22.data] });
    }
  }


  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }
}

