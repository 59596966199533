<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="align-items-center">
          <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="50">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา">
              <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex.gt-md="75" fxFlex="25" class="text-center" style="padding-left: 15px;">
          <mat-form-field>
            <mat-select placeholder="ประเภท" [(ngModel)]="taxType" [ngModelOptions]="{standalone: true}" (selectionChange)="onSelectionChange($event)">
              <mat-option [value]="data" *ngFor="let data of taxTypeLists">
                {{ data }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div fxFlex.gt-md="75" fxFlex="25"class="text-right">
            <button mat-raised-button (click)="openDialog('Add', {})" color="primary">เพิ่ม taxonomy</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<app-blank *ngIf="this.taxonomyLength === 0"></app-blank>
<div fxLayout="row wrap" *ngIf="this.taxonomyLength > 0">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> title </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.title }} 
              </td>
            </ng-container>

            <ng-container matColumnDef="title_th">
              <th mat-header-cell *matHeaderCellDef> title_th </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.title_th }} 
              </td>
            </ng-container>

            <ng-container matColumnDef="tax_type">
              <th mat-header-cell *matHeaderCellDef> tax_type </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.tax_type }} 
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> สถานะ </th>
              <td mat-cell *matCellDef="let element">  <mat-icon>{{ element.isActive ? 'check' : 'clear '}}</mat-icon></td>
            </ng-container>

            <ng-container matColumnDef="indexNumber">
              <th mat-header-cell *matHeaderCellDef> indexNo </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.index }} 
              </td>
            </ng-container>
            
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-link">
                  <a (click)="openDialog('Update',element)" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                  <a (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer text-dark"><mat-icon style="color: #fc4b6c;">delete</mat-icon></a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="pagination" [length]="taxonomyLength" [pageSize]="tableOptions.pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageChanged($event)">
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
