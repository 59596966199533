/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NOTIFICATIONTYPE = 'HEADER_BANNER' | 'BIRTHDAY_PROMO' | 'ORDER_NOTIFICATION';

export const NOTIFICATIONTYPE = {
    HEADERBANNER: 'HEADER_BANNER' as NOTIFICATIONTYPE,
    BIRTHDAYPROMO: 'BIRTHDAY_PROMO' as NOTIFICATIONTYPE,
    ORDERNOTIFICATION: 'ORDER_NOTIFICATION' as NOTIFICATIONTYPE
};

