import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationResponse } from '../../../client';
import { TableDataSource } from '../common/interfaces/common.interface';
import { NotificationService } from '../services/notification.service';
import { AlertService } from '../services/alert.service';
import { NOTIFICATION_TYPE } from '../common/constants';
import * as $ from 'jquery';
@Component({
  selector: 'app-admin-notification',
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.scss']
})
export class AdminNotificationComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'topic', 'link', 'status', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  notificationsLength: any;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(private dialog: MatDialog,
              private notificationService: NotificationService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getNotification();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }


  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getNotification(pageObj.pageIndex);
  }

  async getNotification(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    const data = await this.notificationService.getAllNotifcation(null, this.tableOptions) as NotificationResponse;
    if(data && data.success) {
      this.dataSource = new MatTableDataSource(data.payload.notifications);
      this.dataSource._updateChangeSubscription();
      this.notificationsLength = data.payload.listLength;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
    }
    }
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
          this.getNotification(0);
      }

    if (data.length > 2) {
      const result = await this.notificationService.getNotificationBySearch(data, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.notifications);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          if (result.notifications.length === 0) {
              this.notificationsLength = 0;
          } else {
              this.notificationsLength = result.listLength;
          }
      } else {
          this.notificationsLength = 0;
      }
    }
  }

  openDialog(action: string, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(NotificationDialogContent, {
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result.event === undefined) {
          return;
        }
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        }
    });
  }

  // tslint:disable-next-line:variable-name
  async addRowData(row_obj: any) {
    row_obj['noti_type'] = NOTIFICATION_TYPE.HEADER_BANNER;
    const data = await this.notificationService.createNotification(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getNotification();
     }

  }
  // tslint:disable-next-line:variable-name
  async updateRowData(row_obj: any) {
    const data = await this.notificationService.updateNotificaiton(row_obj.id, row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getNotification();
    }
  }
  // tslint:disable-next-line:variable-name
  async deleteRowData(row_obj: any) {
    const data = await this.notificationService.deleteNotificationById(row_obj.id);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getNotification();
    }
  }
}




@Component({
  // tslint:disable-next-line: component-selector
  selector: 'notification-dialog',
  templateUrl: 'notification-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class NotificationDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  constructor(
      public dialogRef: MatDialogRef<NotificationDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      if(data.action === 'Add') {
        data.active = true;
      }
      this.local_data = { ...data };
      this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.active = event;
  }

  checkDisabled(data) {
    if (data.action === 'Add') {
        if (!!data.message && !!data.url) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Update') {
      if (!!data.message && !!data.url) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleEvent(e) {
    // const a = document.getElementsByClassName("tox-notifications-container").namedItem;
    // document.getElementsByName(a.name).forEach(it => {
    //   it.style.display = "none";
    // });
    if(e.event) {
      setTimeout(() => 
        this.toDoSome()
      , 1000);
      // document.querySelectorAll(".tox-notifications-container").forEach(it => {
      //   it.remove();
      // });

    }
  }

  handleEvent2(e) {
    setTimeout(() => 
      this.toDoSome()
  , 1000);
  }

  toDoSome() {
    $('.tox-notifications-container').attr('style', 'display:none')  // option 2
    $('.tox-notifications-container').attr('style', 'visibility:hidden') // option 3
    $('.tox-notifications-container').remove();  // option 4
    $('.tox-notifications-container').hide();   // option 5
  }
}




