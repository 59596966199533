/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PAYMENTTYPE = 'QR' | 'PAYGATE' | 'MANUAL' | 'EXCHANGE' | 'NONE';

export const PAYMENTTYPE = {
    QR: 'QR' as PAYMENTTYPE,
    PAYGATE: 'PAYGATE' as PAYMENTTYPE,
    MANUAL: 'MANUAL' as PAYMENTTYPE,
    EXCHANGE: 'EXCHANGE' as PAYMENTTYPE,
    NONE: 'NONE' as PAYMENTTYPE
};

