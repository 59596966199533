import { share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TableDataSource } from '../common/interfaces/common.interface';
import { BehaviorSubject } from 'rxjs';
import { StockService as ApiStockService} from '../../../client/api/stock.service';
import { StockLocationService as ApiStockLocationService} from '../../../client/api/stockLocation.service';
import {
    StockReq,
    StockPayload,
    StockResponse,
    StockLocationReq,
    StockLocationResponse,
    StockLocationPayload
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class StocksService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiStockService: ApiStockService,
        private apiStockLocationService: ApiStockLocationService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    getStock(title?: any, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiStockService.getAllStocks(title, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder, tableOptions.sortBy).pipe(share());
          seq.subscribe((res: StockResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }


    getStockLocation(tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiStockLocationService.getAllStockLocations(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: StockLocationResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    createStock(stockLocationReq: StockLocationReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiStockLocationService.createStockLocation(stockLocationReq).pipe(share());
          seq.subscribe((res: StockLocationResponse) => {
              if (res) {
                    this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                    this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
              console.log(err);
              this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateStockByStockId(stockId: string, stockReq: StockReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiStockService.updateStock(stockId, stockReq).pipe(share());
          seq.subscribe((res: StockResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    deleteStockByStockId(stockId: string) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiStockService.deleteStock(stockId).pipe(share());
        seq.subscribe((res: StockResponse) => {
            if (res) {
              this.loadingService.showLoader(false);
                resolve(res);
            } else {
              this.loadingService.showLoader(false);
                reject();
            }
        }, err => {
          this.loadingService.showLoader(false);
            console.log(err);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            }
            reject();
        });
    });
  }
}
