import { map, share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TableDataSource } from '../common/interfaces/common.interface';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { OrderService as ApiOrderService } from '../../../client/api/order.service';
import {
    ORDERSOURCE,
    ORDERSTATUS,
    OrderReq,
    OrderPayload,
    OrderResponse,
    OrderReturn,
    UpdateOrderTracking,
    ReturnOptConfirm,
    CancelOrderReq,
    OrderReqManual,
    OrderCreditCardRequest,
  } from '../../../client';
import { ORDER_SOURCE } from '../common/constants';
import { OrderQRCashImageResponse } from '../../../client/model/orderQRCashImageResponse';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiOrderService: ApiOrderService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    createOrder(order: OrderReq): Promise<OrderResponse> {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiOrderService.createOrder(order).pipe(share());
          seq.subscribe((res: OrderResponse) => {
            this.loadingService.showLoader(false);
              if (res) {
                  resolve(res);
              } else {
                    this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                      duration: 2000,
                      panelClass: 'blue-snackbar',
                      horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,
                  });
                  reject();
              }
          }, err => {
                this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              } else {
                this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                  duration: 2000,
                  panelClass: 'blue-snackbar',
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
              });
              }
              reject();
          });
      });
    }

    createOrderManual(order: OrderReqManual) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiOrderService.createOrderManual(order).pipe(share());
        seq.subscribe((res: OrderResponse) => {
          this.loadingService.showLoader(false);
            if (res) {
                resolve(res);
            } else {
                  this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                    duration: 2000,
                    panelClass: 'blue-snackbar',
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                reject();
            }
        }, err => {
              this.loadingService.showLoader(false);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            } else {
              this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                duration: 2000,
                panelClass: 'blue-snackbar',
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
            }
            reject();
        });
    });
  }

    getAllOrder(invoiceId?: string,searchStr?: string,cusSearch?: string, userId?: any, returnStatus?: any, paymentStatus?: any, tableOptions?: TableDataSource, orderSource?: any, orderStatus?: any, deliveryMethod?: any) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiOrderService.listOrders(invoiceId, searchStr, cusSearch, returnStatus, paymentStatus,orderSource, orderStatus, deliveryMethod || null, userId, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder, tableOptions.sortBy).pipe(share());
          seq.subscribe((res: OrderResponse) => {
            this.loadingService.showLoader(false);
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getOrderById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiOrderService.getOrder(id).pipe(share());
          seq.subscribe((res: OrderResponse) => {
            this.loadingService.showLoader(false);
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateOrderById(id, data) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiOrderService.updateOrder(id, data).pipe(share());
        seq.subscribe((res: OrderResponse) => {
          this.loadingService.showLoader(false);
            if (res) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.log(err);
            this.loadingService.showLoader(false);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            }
            reject();
        });
    });
  }

  cancelOrder(invoiceId, cancelReason: CancelOrderReq) {
    this.loadingService.showLoader(true);
    return new Promise((resolve, reject) => {
      const seq = this.apiOrderService.cancelOrder(invoiceId, cancelReason).pipe(share());
      seq.subscribe((res: OrderResponse) => {
        this.loadingService.showLoader(false);
          if (res) {
              resolve(res);
          } else {
              reject();
          }
      }, err => {
          console.log(err);
          this.loadingService.showLoader(false);
          if (err && err?.error?.name === 'NoAuthorization') {
            this.authService.logout();
            this.router.navigateByUrl('login');
          }
          reject();
      });
  });
  }

    updateOrderTracking(updateOrderTracking: UpdateOrderTracking) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiOrderService.updateOrderTracking(updateOrderTracking).pipe(share());
        seq.subscribe((res: OrderResponse) => {
          this.loadingService.showLoader(false);
            if (res) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.log(err);
            this.loadingService.showLoader(false);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            }
            reject();
        });
    });
  }

  updateOrderPickup(updateOrderTracking: UpdateOrderTracking) {
    this.loadingService.showLoader(true);
    return new Promise((resolve, reject) => {
      const seq = this.apiOrderService.updateOrderPickupTracking(updateOrderTracking).pipe(share());
      seq.subscribe((res: OrderResponse) => {
        this.loadingService.showLoader(false);
          if (res) {
              resolve(res);
          } else {
              reject();
          }
      }, err => {
          console.log(err);
          this.loadingService.showLoader(false);
          if (err && err?.error?.name === 'NoAuthorization') {
            this.authService.logout();
            this.router.navigateByUrl('login');
          }
          reject();
      });
  });
}

    getOrderSimulate(data) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiOrderService.simulateOrder(data).pipe(share());
        seq.subscribe((res: OrderResponse) => {
          this.loadingService.showLoader(false);
            if (res) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.log(err);
            this.loadingService.showLoader(false);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            }
            reject();
        });
    });
  }

  getOrderSimulateManual(data) {
    this.loadingService.showLoader(true);
    return new Promise((resolve, reject) => {
      const seq = this.apiOrderService.simulateOrderManual(data).pipe(share());
      seq.subscribe((res: OrderResponse) => {
        this.loadingService.showLoader(false);
          if (res) {
              resolve(res);
          } else {
              reject();
          }
      }, err => {
          console.log(err);
          this.loadingService.showLoader(false);
          if (err && err?.error?.name === 'NoAuthorization') {
            this.authService.logout();
            this.router.navigateByUrl('login');
          }
          reject();
      });
  });
}

  getCurrentInvoiceId(type: ORDER_SOURCE) {
  this.loadingService.showLoader(true);
  return new Promise((resolve, reject) => {
    const seq = this.apiOrderService.getCurrentInvoiceId(type).pipe(share());
    seq.subscribe((res: OrderResponse) => {
      this.loadingService.showLoader(false);
        if (res) {
            resolve(res);
        } else {
            reject();
        }
    }, err => {
        console.log(err);
        this.loadingService.showLoader(false);
        if (err && err?.error?.name === 'NoAuthorization') {
          this.authService.logout();
          this.router.navigateByUrl('login');
        }
        reject();
    });
  });
  }


  saveReturnOrder(orderReturn: OrderReturn) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiOrderService.returnOrder(orderReturn).pipe(share());
        seq.subscribe((res: OrderResponse) => {
          this.loadingService.showLoader(false);
            if (res) {
                resolve(res);
            } else {
                this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                  duration: 2000,
                    panelClass: 'blue-snackbar',
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                reject();
            }
        }, err => {
            console.log(err);
            this.loadingService.showLoader(false);
            if (err && err?.error?.name === 'NoAuthorization') {
              this.authService.logout();
              this.router.navigateByUrl('login');
            } else {
              this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                duration: 2000,
                panelClass: 'blue-snackbar',
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
            }
            reject();
        });
    });
  }

  saveComfirmReturnOrder(orderReturn: ReturnOptConfirm) {
    this.loadingService.showLoader(true);
    return new Promise((resolve, reject) => {
      const seq = this.apiOrderService.returnOptConfirm(orderReturn).pipe(share());
      seq.subscribe((res: OrderResponse) => {
        this.loadingService.showLoader(false);
          if (res) {
              resolve(res);
          } else {
              this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
                duration: 2000,
                  panelClass: 'blue-snackbar',
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
              });
              reject();
          }
      }, err => {
          console.log(err);
          this.loadingService.showLoader(false);
          if (err && err?.error?.name === 'NoAuthorization') {
            this.authService.logout();
            this.router.navigateByUrl('login');
          } else {
            this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
              duration: 2000,
              panelClass: 'blue-snackbar',
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
          reject();
      });
  });
}

  async creditCardProcess(orderCreditReq: OrderCreditCardRequest) {
    try {
      this.loadingService.showLoader(true);
      return await this.apiOrderService.processCreditCard(orderCreditReq).toPromise();
    } catch (err) {
      this.loadingService.showLoader(false);

      if (err && err?.error?.name === 'NoAuthorization') {
        this.authService.logout();
        this.router.navigateByUrl('login');
      } else {
        this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
          duration: 2000,
          panelClass: 'blue-snackbar',
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }
  }

  async processQrCash(invoiceId: string): Promise<string> {
    try {
      this.loadingService.showLoader(true);
      const qrResp: OrderQRCashImageResponse = await this.apiOrderService.generateQRCash({
        invoiceId,
      }).toPromise();
      console.debug('qrResp :', qrResp);

      this.loadingService.showLoader(false);

      return qrResp.image;
    } catch (err) {
      this.loadingService.showLoader(false);

      if (err && err?.error?.name === 'NoAuthorization') {
        this.authService.logout();
        this.router.navigateByUrl('login');
      } else {
        this.snackBar.open('คุณทำรายการไม่สำเร็จ', '', {
          duration: 2000,
          panelClass: 'blue-snackbar',
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }
  }

  async processQrCashVerify(invoiceId: string): Promise<boolean> {
    try {
      const resp = await this.apiOrderService.verifyQRCash(invoiceId).toPromise();

      return resp?.success || false;
    } catch (err) {
      if (err && err?.error?.name === 'NoAuthorization') {
        this.authService.logout();
        this.router.navigateByUrl('login');
      } else {
        this.snackBar.open('ไม่สามารถตรวจสอบการชำระเงิน QR Code', '', {
          duration: 2000,
          panelClass: 'blue-snackbar',
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }
  }

  b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

}
