/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SORTORDER = 'desc' | 'asc';

export const SORTORDER = {
    Desc: 'desc' as SORTORDER,
    Asc: 'asc' as SORTORDER
};

