/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LANGUAGE = 'EN' | 'TH';

export const LANGUAGE = {
    EN: 'EN' as LANGUAGE,
    TH: 'TH' as LANGUAGE
};

