<mat-card class="no-bg">
  <mat-card-content>
    <div class="d-flex align-items-center">
      <h2 class="m-t-0">เพิ่มสินค้าใหม่</h2>
      <!-- <div class="ml-auto">
        <button mat-raised-button class="btn-grey" (click)="onSubmit()" [disabled]="this.form.invalid">SAVE</button>
      </div> -->
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form">
    <div class="d-flex align-items-center">
      <h3 class="m-t-0">รายละเอียดสินค้า</h3>
    </div>
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="ชื่อสินค้า" formControlName="title">
        </mat-form-field>
        <small *ngIf="form.controls.title.hasError('required') && form.controls.title.touched" class="text-danger support-text">product name is required.</small>
      </div>
      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="รหัส" formControlName="sku">
        </mat-form-field>
        <small *ngIf="form.controls.sku.hasError('required') && form.controls.sku.touched" class="text-danger support-text">sku is required.</small>
      </div>
      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="ราคา" formControlName="price" type="number">
        </mat-form-field>
        <small *ngIf="form.controls.price.hasError('required') && form.controls.price.touched" class="text-danger support-text">price is required.</small>
      </div>

      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <section class="example-section">
          <mat-checkbox class="example-margin" (change)="selectPromoEligibility($event)" formControlName="promoEligibility">ไม่สามารถใช้ promocode ได้</mat-checkbox>
        </section>
      </div>

      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10" [formGroup]="form.controls.salesDiscount">
        <!-- <mat-form-field>
          <input matInput placeholder="ส่วนลดราคา (คิดเป็น percent)" formControlName="discountAmt" type="number" min="0">
          <span matTextSuffix>%</span>
        </mat-form-field> -->
        <mat-form-field appearance="outline">
          <mat-label>ส่วนลดราคา</mat-label>
          <input matInput placeholder="0" formControlName="discountAmt" type="number">
          <mat-icon matSuffix>percent</mat-icon>
          <mat-hint>(คิดเป็นส่วนลดเท่ากับ {{ calDiscount()[0] }} บาท ราคาหลังลดเท่ากับ {{ calDiscount()[1] }} บาท)</mat-hint>
        </mat-form-field>
        <small *ngIf="form.get('salesDiscount.discountAmt').hasError('required') && form.get('salesDiscount.discountAmt').touched" class="text-danger support-text">Please insert only digit number.</small>
      </div>

      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <!-- <mat-form-field>
          <textarea matInput placeholder="รายละเอียด" formControlName="details"></textarea>
        </mat-form-field> -->
        <h2>Details</h2>
        <editor
        apiKey="fukxwabok6bqa0howwhwi0txrj7ydcuog1yj583e14tvbq6r"
        id="mytextarea1"
        class="editor1"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="details"
        initialValue="Type Here...."
        (onInit)="handleEvent($event)"
        (onNodeChange)="handleEvent2($event)"
        [init]="{
          selector: '.editor1',
          width: 600,
          sheight: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | bold italic underline forecolor backcolor | \
          alignleft aligncenter alignright alignjustify'
        }"
      ></editor>
      </div>
      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <!-- <mat-form-field>
          <textarea matInput placeholder="careInstruction" formControlName="careInstruction"></textarea>
        </mat-form-field> -->
        <h2>Care Instruction</h2>
        <editor
        apiKey="fukxwabok6bqa0howwhwi0txrj7ydcuog1yj583e14tvbq6r"
        id="textcare"
        class="editor"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="careInstruction"
        initialValue="Type Here...."
        (onInit)="handleEvent($event)"
        (onNodeChange)="handleEvent2($event)"
        [init]="{
          selector: '.editor',
          width: 600,
          sheight: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | bold italic underline forecolor backcolor | \
          alignleft aligncenter alignright alignjustify'
        }"
      ></editor>
      </div>
    </div>

        
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="33" fxFlex="100" class="p-10">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #colorListt aria-label="#color">
            <mat-chip
              *ngFor="let data of colorLists"
              [selectable]="true"
              [removable]="true"
              (removed)="removeColor(data)">
              {{data.title}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="color..."
              #colorInput
              [formControl]="colorCtrl"
              [matAutocomplete]="colorauto"
              [matChipInputFor]="colorListt"
              (matChipInputTokenEnd)="addColor($event)">
          </mat-chip-list>
          <mat-autocomplete #colorauto="matAutocomplete" (optionSelected)="selectedColor($event)">
            <mat-option *ngFor="let color of filterColors | async" [value]="color">
              {{ color.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="33" fxFlex="100" class="p-10">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #collectionListt aria-label="#collection">
            <mat-chip
              *ngFor="let data of collectionLists"
              [selectable]="true"
              [removable]="true"
              (removed)="removeCollection(data)">
              {{data.title}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="collection..."
              #collectionInput
              [matAutocomplete]="collectionauto"
              [matChipInputFor]="collectionListt"
              (matChipInputTokenEnd)="addCollection($event)">
          </mat-chip-list>
          <mat-autocomplete #collectionauto="matAutocomplete" (optionSelected)="selectedCollection($event)">
            <mat-option *ngFor="let collection of collectionList" [value]="collection">
              {{ collection.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="33" fxFlex="100" class="p-10">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #categoryListt aria-label="#category">
            <mat-chip
              *ngFor="let data of categoryLists"
              [selectable]="true"
              [removable]="true"
              (removed)="removeCategory(data)">
              {{data.title}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="category..."
              #categoryInput
              [matAutocomplete]="categoryauto"
              [matChipInputFor]="categoryListt"
              (matChipInputTokenEnd)="addCategory($event)">
          </mat-chip-list>
          <mat-autocomplete #categoryauto="matAutocomplete" (optionSelected)="selectedCategory($event)">
            <mat-option *ngFor="let category of categoryList" [value]="category">
              {{ category.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="33" fxFlex="100" class="p-10">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #taxList aria-label="#tag">
            <mat-chip
              *ngFor="let data of tagLists"
              [selectable]="true"
              [removable]="true"
              (removed)="remove(data)">
              {{data.title}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="tag..."
              #taxInput
              [matAutocomplete]="taxauto"
              [matChipInputFor]="taxList"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #taxauto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of taxonomyList" [value]="tag">
              {{ tag.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="33" fxFlex="100" class="p-10">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #brandListt aria-label="#brand">
            <mat-chip
              *ngFor="let data of brandLists"
              [selectable]="true"
              [removable]="true"
              (removed)="removeBrand(data)">
              {{data.title }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="brand..."
              #brandInput
              [matAutocomplete]="brandauto"
              [matChipInputFor]="brandListt"
              (matChipInputTokenEnd)="addBrand($event)">
          </mat-chip-list>
          <mat-autocomplete #brandauto="matAutocomplete" (optionSelected)="selectedBrand($event)">
            <mat-option *ngFor="let brand of brandList" [value]="brand">
              {{ brand.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row wrap">
      <div fxFlex="100">
          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond11
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles11"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 11)"
            (onremovefile)="pondHandleRemoveFile($event, 11)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" step="1" [(ngModel)]="productImg.pond11" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond12
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles12"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 12)"
            (onremovefile)="pondHandleRemoveFile($event, 12)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond12" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond13
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles13"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 13)"
            (onremovefile)="pondHandleRemoveFile($event, 13)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond13" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond14
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles14"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 14)"
            (onremovefile)="pondHandleRemoveFile($event, 14)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond14" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- another upload images -->
    <div fxLayout="row wrap">
      <div fxFlex="100">
          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond15
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles15"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 15)"
            (onremovefile)="pondHandleRemoveFile($event, 15)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" step="1" [(ngModel)]="productImg.pond15" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond16
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles16"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 16)"
            (onremovefile)="pondHandleRemoveFile($event, 16)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond16" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond17
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles17"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 17)"
            (onremovefile)="pondHandleRemoveFile($event, 17)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond17" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          </div>

          <div fxFlex="25" class="text-center p-10">
            <file-pond
            #myPond18
            className="my-class" 
            labelIdle="Drop files here..."
            allowMultiple="false"
            acceptedFileTypes="image/jpeg, image/png"
            *ngIf="pondOptions"
            [options]="pondOptions"
            [files]="this.pondFiles18"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, '')"
            (onprocessfile)="pondHandleProcessFile($event, 18)"
            (onremovefile)="pondHandleRemoveFile($event, 18)"
          >
          </file-pond>
          <mat-form-field>
            <input matInput type="number" placeholder="ลำดับ" [(ngModel)]="productImg.pond18" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- End -->
    <br>
    
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <mat-radio-group formControlName="product_type" (change)="radioChange($event)">
          <mat-radio-button  color="primary"  class="example-margin" value="CLOTHES">CLOTHES</mat-radio-button>
          <mat-radio-button color="warn" class="example-margin" value="SHOES">SHOES</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <label class="p-10" for="size">ขนาด</label>
    <div fxLayout="row wrap">
      <div fxFlex.gt-md="100" class="text-right" style="right: 20px;">
        <button mat-raised-button (click)="openDialog('Add',{})" color="primary">เพิ่ม stock</button>
      </div>
      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <app-blank *ngIf="!!!this.dataSource || this.dataSource.data.length === 0"></app-blank>
        <div class="responsive-table" *ngIf="this.dataSource && this.dataSource.data.length > 0">
          <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
              <ng-container matColumnDef="size">
                  <th mat-header-cell *matHeaderCellDef> Size. </th>
                  <td mat-cell *matCellDef="let element"> {{ element.sizeDis }} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="stock">
                  <th mat-header-cell *matHeaderCellDef> Stock </th>
                  <td mat-cell *matCellDef="let element"> {{ element.stockName }} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="quantity">
                  <th mat-header-cell *matHeaderCellDef> Quantity </th>
                  <td mat-cell *matCellDef="let element"> {{ element.quantity }} </td>
              </ng-container>

              <ng-container matColumnDef="preOrderDays">
                <th mat-header-cell *matHeaderCellDef> preOrderDays </th>
                <td mat-cell *matCellDef="let element"> {{ element.preOrderDays || '-' }} </td>
            </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="action-link">
                  <a (click)="openDialog('Update',element)" *ngIf="!this.editMode" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                  <a (click)="openDialog('Delete',element)" *ngIf="!this.editMode" class="m-r-10 cursor-pointer text-dark">
                    <mat-icon style="color: #fc4b6c;">delete</mat-icon>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>

      </div>

      <!-- <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <mat-form-field *ngIf="this.form.get('product_type').value === 'CLOTHES'">
          <mat-select placeholder="size">
            <mat-option value="data.value" *ngFor="let data of clothLists">
              {{ data.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.form.get('product_type').value !== 'CLOTHES'">
          <mat-select placeholder="size">
            <mat-option value="data" *ngFor="let data of shoeLists">
              {{ data }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <mat-form-field>
          <mat-select placeholder="คลังสินค้า">
            <mat-option value="option">Option</mat-option>
            <mat-option value="option">Option2</mat-option>
            <mat-option value="option">Option3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="25" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput type="number" placeholder="จำนวน" step="1">
        </mat-form-field>
      </div> -->
    </div>


    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="50" fxFlex="50" class="p-10">
        <label class="p-10" for="size">Size chart</label>
        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field>
            <textarea matInput placeholder="Type here" formControlName="sizeChartText"></textarea>
          </mat-form-field>
          <br>
            <file-pond
            #myPond2
            *ngIf="pondOptions2"
            [options]="pondOptions2"
            [files]="pondFiles2"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event, 2)"
            (onprocessfile)="pondHandleProcessFile($event, 2)"
            (onremovefile)="pondHandleRemoveFile($event, 2)"
          >
          </file-pond>
        </div>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="50" class="p-10">
        <label class="p-10" for="size">Model size</label>
        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field>
            <textarea matInput placeholder="Type here" formControlName="modelSizeText"></textarea>
          </mat-form-field>
          <br>
          <file-pond
          #myPond3
          *ngIf="pondOptions3"
          [options]="pondOptions3"
          [files]="pondFiles3"
          (oninit)="pondHandleInit()"
          (onaddfile)="pondHandleAddFile($event, 3)"
          (onprocessfile)="pondHandleProcessFile($event, 3)"
          (onremovefile)="pondHandleRemoveFile($event, 3)"
        >
        </file-pond>
        </div>
      </div>
    </div>

    <div fxLayout="row wrap" *ngIf="this.editMode">
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899; text-align: center;">     
          <span *ngIf="!!relatedProducts1">{{ this.relatedProducts1.title }} ({{ this.relatedProducts1?.Colors[0]?.title }})
            <a (click)="relatedProducts1 = null" class="m-r-10 cursor-pointer text-dark">
              <mat-icon style="color: grey;">delete</mat-icon>
            </a>
          </span>
          <button *ngIf="!!!relatedProducts1" mat-raised-button (click)="selectProduct(0)" color="primary">เพิ่ม relate product</button>
      </div>
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">       
        <span *ngIf="!!relatedProducts2">{{ this.relatedProducts2.title }} ({{ this.relatedProducts2?.Colors[0]?.title }})
          <a (click)="relatedProducts2 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>
        <button *ngIf="!!!relatedProducts2" mat-raised-button (click)="selectProduct(1)" color="primary">เพิ่ม relate product</button>
      </div> 
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">    
        <span *ngIf="!!relatedProducts3">{{ this.relatedProducts3.title }} ({{ this.relatedProducts3?.Colors[0]?.title }})
          <a (click)="relatedProducts3 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>   
        <button *ngIf="!!!relatedProducts3" mat-raised-button (click)="selectProduct(2)" color="primary">เพิ่ม relate product</button>
      </div>
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">   
        <span *ngIf="!!relatedProducts4">{{ this.relatedProducts4.title }}  ({{ this.relatedProducts4?.Colors[0]?.title }})
          <a (click)="relatedProducts4 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>   
        <button *ngIf="!!!relatedProducts4" mat-raised-button (click)="selectProduct(3)" color="primary">เพิ่ม relate product</button> 
      </div>
    </div>

    <br><br>

    <div fxLayout="row wrap" *ngIf="this.editMode">
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899; text-align: center;">     
          <span *ngIf="!!colorProducts1">{{ this.colorProducts1.title }} ({{ this.colorProducts1?.Colors[0]?.title }})
            <a (click)="colorProducts1 = null" class="m-r-10 cursor-pointer text-dark">
              <mat-icon style="color: grey;">delete</mat-icon>
            </a>
          </span>
          <button *ngIf="!!!colorProducts1" mat-raised-button (click)="selectColorProduct(0)" color="primary">เพิ่ม relate color</button>
      </div>
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">       
        <span *ngIf="!!colorProducts2">{{ this.colorProducts2.title }}  ({{ this.colorProducts2?.Colors[0]?.title }})
          <a (click)="colorProducts2 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>
        <button *ngIf="!!!colorProducts2" mat-raised-button (click)="selectColorProduct(1)" color="primary">เพิ่ม relate color</button>
      </div> 
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">    
        <span *ngIf="!!colorProducts3">{{ this.colorProducts3.title }} ({{ this.colorProducts3?.Colors[0]?.title }})
          <a (click)="colorProducts3 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>   
        <button *ngIf="!!!colorProducts3" mat-raised-button (click)="selectColorProduct(2)" color="primary">เพิ่ม relate color</button>
      </div>
      <div class="p-10" fxFlex="25" style="border: 1px solid #e89899;  text-align: center;">   
        <span *ngIf="!!colorProducts4">{{ this.colorProducts4.title }} ({{ this.colorProducts4?.Colors[0]?.title }})
          <a (click)="colorProducts4 = null" class="m-r-10 cursor-pointer text-dark">
            <mat-icon style="color: grey;">delete</mat-icon>
          </a>
        </span>   
        <button *ngIf="!!!colorProducts4" mat-raised-button (click)="selectColorProduct(3)" color="primary">เพิ่ม relate color</button> 
      </div>
    </div>

    <!-- <<label class="p-10" for="size">Promotion</label>

    div fxLayout="row wrap">
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="Promo code">
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="Discount">
        </mat-form-field>
      </div>
    </div> -->
    <br>
    <div fxLayout="row wrap">
      <div fxFlex="80" class="p-10 text-right"> 
        <section class="example-section">
          <label class="example-margin">Status:</label>
          <mat-radio-group formControlName="product_status" (change)="productStatusChange($event)">
            <mat-radio-button class="example-margin" value="INACTIVE">Inactive</mat-radio-button>
            <mat-radio-button class="example-margin" value="DRAFT">Draft</mat-radio-button>
            <mat-radio-button class="example-margin" value="PUBLISH">Publish</mat-radio-button>
            <mat-radio-button class="example-margin" value="SCHEDULE">Schedule</mat-radio-button>
          </mat-radio-group>
          <br>
          <ng-container *ngIf="form.get('product_status').value === 'SCHEDULE'">
            <div style="width: 280px; float: right; padding-top: 15px;">
              <ejs-datetimepicker [min]="minDate" formControlName="publishDate"></ejs-datetimepicker>
            </div>
          </ng-container>
        </section>
      </div>
      <div fxFlex="20" class="p-10 text-right">
        <div class="d-flex align-items-center">
          <h2 class="m-t-0"></h2>
          <div class="ml-auto">
            <button mat-raised-button class="btn-grey" (click)="onSubmit()" [disabled]="this.form.invalid">SAVE</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  </mat-card-content>
</mat-card>


<mat-card class="no-bg">
  <mat-card-content>
  </mat-card-content>
</mat-card>


