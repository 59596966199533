import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AlertService  } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TableDataSource } from '../common/interfaces/common.interface';
import { ShippingService as ApiShippingService } from '../../../client/api/shipping.service';
import {
    ShippingResponse,
    ShippingPayload,
    ShippingReq
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class ShippingService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiShippingService: ApiShippingService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) { }currency

    createShipping(shipping: ShippingReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.createShipping(shipping).pipe(share());
          seq.subscribe((res: ShippingResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateShipping(id, data: ShippingReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.updateShipping(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
    
    deleteShippingById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.deleteShipping(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getAllShipping(tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.getAllShippings(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: ShippingResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
            
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

  getShippingBySearch(params?: string, tableOptions?: TableDataSource) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiShippingService.getShippingByCountryCode(params,null, null, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: ShippingResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          console.error('ERROR', err);
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }

    getShippingByCountryCode(params?: string, tableOptions?: TableDataSource) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiShippingService.getShippingByCountryCode(null,params, null, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: ShippingResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          console.error('ERROR', err);
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }

      getShippingById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.getShipping(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getCountries() {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiShippingService.getCountries().pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
}
