<h2 mat-dialog-title><strong>add</strong></h2>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา">
          <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> ชื่อสินค้า </th>
                <td mat-cell *matCellDef="let item; let i = index;"> 
                    <mat-radio-button class="example-margin" value="item" (change)="selected = item"></mat-radio-button>
                </td>
              </ng-container>
          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> รหัสสินค้า </th>
            <td mat-cell *matCellDef="let item; let i = index;"> 
              {{ item.sku }} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> ชื่อสินค้า </th>
            <td mat-cell *matCellDef="let item; let i = index;"> 
              {{ item.title }} {{ displayColor(item) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator class="pagination" [length]="listLength" [pageSize]="tableOptions.pageSize"
          [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
        </mat-paginator>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
      </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="!!!selected">OK</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
