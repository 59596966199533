import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AlertService  } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TableDataSource } from '../common/interfaces/common.interface';
import { NotificationService as ApiNotificationService } from '../../../client/api/notification.service';
import { NOTIFICATIONTYPE } from '../../../client/model/nOTIFICATIONTYPE';
import {
    NotificationPayload,
    NotificationReq,
    NotificationResponse
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiNotificationService: ApiNotificationService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) { }

    createNotification(notification: NotificationReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.createNotification(notification).pipe(share());
          seq.subscribe((res: NotificationResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateNotificaiton(id, data: NotificationReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.updateNotification(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
    
    deleteNotificationById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.deleteNotification(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getAllNotifcation(active, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.getAllNotifications(active, null, NOTIFICATIONTYPE.HEADERBANNER ,tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: NotificationResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getNotificationBySearch(params?: string, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.getNotificationByName(params, null, null, NOTIFICATIONTYPE.HEADERBANNER,tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          // If the API returned a successful response, mark the user as logged in
          seq.subscribe((res: NotificationResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res) {
              this.loadingService.showLoader(false);
              resolve(res.payload as NotificationPayload);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            console.error('ERROR', err);
            this.loadingService.showLoader(false);
            // show error alert
            reject();
          });
        });
      }

      getNotificationByUserId(userId) {
        const tableOptions = {
          pageNumber: 0,
          pageSize: 50,
        } as TableDataSource;
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiNotificationService.getAllNotifications(null, userId,NOTIFICATIONTYPE.BIRTHDAYPROMO ,tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: NotificationResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
            
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
}
