/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PRODUCTTYPE = 'CLOTHES' | 'SHOES';

export const PRODUCTTYPE = {
    CLOTHES: 'CLOTHES' as PRODUCTTYPE,
    SHOES: 'SHOES' as PRODUCTTYPE
};

