<h2 mat-dialog-title>Row Action :: <strong>รายการคืนสินค้า</strong></h2>
<mat-dialog-content class="mat-typography">
  <ng-container>
    <div fxLayout="row wrap">
        <div fxFlex="100">
          <mat-card>
            <mat-card-content>
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource22" class="table employee-list no-wrap">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> ชื่อ </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0">{{ element.name }} {{ element?.color }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef> เหตุผล </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0">{{ element.reason }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef> จำนวน </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0" style="color: #e3c37d;">{{ element.qty }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> ราคา </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0" style="color: #e3c37d;">{{ element.price }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="saleItem">
                    <th mat-header-cell *matHeaderCellDef> ส่วนลด item </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!!element?.saleItem">{{element?.saleItem?.total }} ({{element?.saleItem?.percent }} %)</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> image </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                      <div *ngFor="let data of element.returnImgs; let i = index;">
                        <a target="_blank" href="{{ data.img }}">
                          <img src="{{ data.img }}"  class="d-block img-crop" width="auto" height="200"></a>
                    </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> สถานะการคืน </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0">{{ element.status }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef> comment </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="m-b-0 m-t-0">{{ element.comment }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="stock">
                    <th mat-header-cell *matHeaderCellDef> stock ย้ายไปที่ </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15" *ngIf="!this.isApprove">
                          <p class="m-b-0 m-t-0">{{ element.stockName || '-' }}</p>
                        </div>
                        <div class="m-l-15" *ngIf="status !== 'DONE' && this.isApprove">
                          <mat-form-field>
                            <mat-select placeholder="คลังสินค้า" [(ngModel)]="element.targetStockLocationId" [ngModelOptions]="{standalone: true}">
                              <mat-option [value]="data.id" *ngFor="let data of stockLocationList">
                                {{ data.title }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="m-l-15" *ngIf="status === 'DONE' && this.isApprove">
                          <p class="m-b-0 m-t-0">{{ element.stockName || '-' }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns22"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns22;"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
  </ng-container>
</mat-dialog-content>

<div mat-dialog-actions>
  <ng-container *ngIf="btnTxt === 'ปฏิเสธ' && (status !== 'DONE')">
    <mat-form-field>
      <input type="text" matInput required id="comment" name="comment" [(ngModel)]="comment"
        placeholder="comment">
    </mat-form-field>
  </ng-container>
  <!-- <ng-container *ngIf="btnTxt !== 'ปฏิเสธ' && this.isApprove">
    <mat-form-field>
      <input type="text" matInput required id="trackingNo" name="comment" [(ngModel)]="comment"
        placeholder="trackingNo">
    </mat-form-field>
  </ng-container> -->
  <div class="text-left">
    <button (click)="doAction('Reject')" mat-flat-button color="warn" *ngIf="status !== 'DONE'">{{ btnTxt }}</button>
  </div>
  <div class="align-right" [ngClass]="{'align-right-100': btnTxt === 'ปฏิเสธ'}">
    <button (click)="doAction('Submit')" mat-flat-button color="primary" *ngIf="this.isApprove && !!returnTrackingNo && (status === 'OTW')">ยืนยัน</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
  </div>
</div>