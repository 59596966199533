/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CLOTHESIZE = 'FREE_SIZE' | 'MINI' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | '12_18M' | '18_24M' | 'Y1_2' | 'Y2_3' | 'Y2_4' | 'Y3_4' | 'Y4_5' | 'Y4_6' | 'Y5_6';

export const CLOTHESIZE = {
    FREESIZE: 'FREE_SIZE' as CLOTHESIZE,
    MINI: 'MINI' as CLOTHESIZE,
    XS: 'XS' as CLOTHESIZE,
    S: 'S' as CLOTHESIZE,
    M: 'M' as CLOTHESIZE,
    L: 'L' as CLOTHESIZE,
    XL: 'XL' as CLOTHESIZE,
    XXL: 'XXL' as CLOTHESIZE,
    _1218M: '12_18M' as CLOTHESIZE,
    _1824M: '18_24M' as CLOTHESIZE,
    Y12: 'Y1_2' as CLOTHESIZE,
    Y23: 'Y2_3' as CLOTHESIZE,
    Y24: 'Y2_4' as CLOTHESIZE,
    Y34: 'Y3_4' as CLOTHESIZE,
    Y45: 'Y4_5' as CLOTHESIZE,
    Y46: 'Y4_6' as CLOTHESIZE,
    Y56: 'Y5_6' as CLOTHESIZE
};

