import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AlertService  } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TableDataSource } from '../common/interfaces/common.interface';
import { CurrencyService as ApiCurrencyService } from '../../../client/api/currency.service';
import {
    CurrencyPayload,
    CurrencyReq,
    CurrencyResponse
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiCurrencyService: ApiCurrencyService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) { }currency

    createCurrency(currency: CurrencyReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiCurrencyService.createCurrency(currency).pipe(share());
          seq.subscribe((res: CurrencyResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateCurrency(id, data: CurrencyReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiCurrencyService.updateCurrency(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
    
    deleteCurrencyById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiCurrencyService.deleteCurrency(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getAllCurrency(tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiCurrencyService.getAllCurrencys(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: CurrencyResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getCurrencyBySearch(params?: string, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiCurrencyService.getCurrencyByName(params, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          // If the API returned a successful response, mark the user as logged in
          seq.subscribe((res: CurrencyResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res) {
              this.loadingService.showLoader(false);
              resolve(res.payload);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            console.error('ERROR', err);
            this.loadingService.showLoader(false);
            // show error alert
            reject();
          });
        });
      }


}
