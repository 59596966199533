import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ContentResponse, CONTENTTYPE } from '../../../client';
import { TableDataSource } from '../common/interfaces/common.interface';
import { ContentService } from '../services/content.service';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrls: ['./admin-content.component.scss']
})
export class AdminContentComponent implements OnInit {
  displayedColumns: string[] = ['id', 'title', 'content_type', 'orderNo', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  contentLength: any;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(
    private dialog: MatDialog,
    private contentService: ContentService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getContent();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getContent(pageObj.pageIndex);
  }

  async getContent(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    const data = await this.contentService.getAllContents(this.tableOptions) as ContentResponse;
    if (data && data.success) {
      this.dataSource = new MatTableDataSource(data.payload.contents);
      this.dataSource._updateChangeSubscription();
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
      }
      this.contentLength = data.payload.listLength;
    }
  }

  openDialog(action: string, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(ManageContentDialogContent, {
        minWidth: 900,
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!!!result) {
        return;
      }
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        }
    });
  }

  async addRowData(row_obj: any) {
    const data = await this.contentService.createContent(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getContent();
     }
  }

  async updateRowData(row_obj: any) {
    const data = await this.contentService.updateContent(row_obj.id, row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getContent();
    }
  }

  async deleteRowData(row_obj: any) {
    const data = await this.contentService.deleteContentById(row_obj.id);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getContent();
    }
  }
}



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'manage-content-dialog',
  templateUrl: 'manage-content-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class ManageContentDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  contentTypeList = [{
    value: CONTENTTYPE.LINK,
    name: 'link'
  }, {
    value: CONTENTTYPE.TEXT,
    name: 'text'
  }];
  constructor(
      public dialogRef: MatDialogRef<ManageContentDialogContent>,
      public authService: AuthService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      if(data.action === 'Add') {
        data.content_type = CONTENTTYPE.TEXT;
      }
      this.local_data = { ...data };
      this.action = this.local_data.action;
  }


  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }


  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }


  checkDisabled(data) {
    const details = data.content_type === CONTENTTYPE.LINK ? data.url : data.body;
    if (data.action === 'Add') {
        if (!!data.title && !!data.content_type && !!details) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Update') {
      if (!!data.title && !!data.content_type && !!details) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }

  handleEvent(e) {
    // const a = document.getElementsByClassName("tox-notifications-container").namedItem;
    // document.getElementsByName(a.name).forEach(it => {
    //   it.style.display = "none";
    // });
    if(e.event) {
      setTimeout(() => 
        this.toDoSome()
      , 1000);
      // document.querySelectorAll(".tox-notifications-container").forEach(it => {
      //   it.remove();
      // });

    }
  }

  handleEvent2(e) {
    setTimeout(() => 
      this.toDoSome()
  , 1000);
  }

  toDoSome() {
    $('.tox-notifications-container').attr('style', 'display:none')  // option 2
    $('.tox-notifications-container').attr('style', 'visibility:hidden') // option 3
    $('.tox-notifications-container').remove();  // option 4
    $('.tox-notifications-container').hide();   // option 5
  }
}
