import { share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TableDataSource } from '../common/interfaces/common.interface';
import { BehaviorSubject } from 'rxjs';
import { TaxonomyService as ApiTaxonomyService} from '../../../client/api/taxonomy.service';
import {
    TaxonomyResponse,
    TaxonomyPayload,
    TaxonomyReq,
    TaxUpdateReq,
    TAXTYPE,
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class TaxonomyService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiTaxonomyService: ApiTaxonomyService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    getTaxonomyByName(name, tableOptions?: TableDataSource) {
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.getTaxonomyByName(name, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: TaxonomyResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getAllTaxonomies(tableOptions?: TableDataSource) {
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.getAllTaxonomies(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          seq.subscribe((res: TaxonomyResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    createCurrency(currency: TaxonomyReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.createTaxonomy(currency).pipe(share());
          seq.subscribe((res: TaxonomyResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updateCurrency(id, data: TaxUpdateReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.updateTaxonomy(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
    
    deleteCurrencyById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.deleteTaxonomy(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getTaxonomyBySearch(params?: TAXTYPE, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiTaxonomyService.getTaxonomiesByTaxType(params, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          // If the API returned a successful response, mark the user as logged in
          seq.subscribe((res: TaxonomyResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res) {
              this.loadingService.showLoader(false);
              resolve(res.payload);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            console.error('ERROR', err);
            this.loadingService.showLoader(false);
            // show error alert
            reject();
          });
        });
      }
}
