import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ShippingResponse } from '../../../client';
import { TableDataSource } from '../common/interfaces/common.interface';
import { ShippingService } from '../services/shipping.service';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-admin-shipping',
  templateUrl: './admin-shipping.component.html',
  styleUrls: ['./admin-shipping.component.scss']
})
export class AdminShippingComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'title', 'country', 'rate1', 'rate2', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  shippingLength: any;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(private dialog: MatDialog,
              private shippingService: ShippingService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getShipping();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getShipping(pageObj.pageIndex);
  }

  async getShipping(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    const data = await this.shippingService.getAllShipping(this.tableOptions) as ShippingResponse;
    if (data && data.success) {
      this.dataSource = new MatTableDataSource(data.payload.shipments);
      this.dataSource._updateChangeSubscription();
      this.shippingLength = data.payload.listLength;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
      }
    }
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
          this.getShipping(0);
      }

    if (data.length > 1) {
      const result = await this.shippingService.getShippingBySearch(data, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.shipments);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          if (result.listLength === 0) {
              this.shippingLength = 0;
          } else {
              this.shippingLength = result.listLength;
          }
      } else {
          this.shippingLength = 0;
      }
    }
  }

  openDialog(action: string, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(ShippingDialogContent, {
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!!!result) {
        return;
      }
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        }
    });
  }

  // tslint:disable-next-line:variable-name
  async addRowData(row_obj: any) {
    const data = await this.shippingService.createShipping(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getShipping();
     }

  }
  // tslint:disable-next-line:variable-name
  async updateRowData(row_obj: any) {
    const data = await this.shippingService.updateShipping(row_obj.id, row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getShipping();
    }
  }
  // tslint:disable-next-line:variable-name
  async deleteRowData(row_obj: any) {
    const data = await this.shippingService.deleteShippingById(row_obj.id);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getShipping();
    }
  }
}




@Component({
  // tslint:disable-next-line: component-selector
  selector: 'shipping-dialog',
  templateUrl: 'shipping-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class ShippingDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  countries;
  filteredStates: Observable<[]>;
  stateCtrl = new FormControl();
  constructor(
      public dialogRef: MatDialogRef<ShippingDialogContent>,
      public authService: AuthService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      // if(data.action === 'Add') {
      //   data.status = true;
      // }
      this.local_data = { ...data };
      this.action = this.local_data.action;
      this.getCountries();

      this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterStates(state) : this.countries.slice())
      );
  }

  private _filterStates(value: string):[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  async getCountries() {
    const data = await this.authService.getCountries();
    this.countries = JSON.parse(data);
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.status = event;
  }

  updateCountryCode(event, country) {
    console.log(country);
    this.local_data.country_code = country.code;
  }

  checkDisabled(data) {
    const isCountry = this.countries.some(it => it.name === data.country_name);
    if (data.action === 'Add') {
        if (!!data.title && (!!data.primary_rate || data.primary_rate === 0) && (!!data.additional_rate || data.additional_rate === 0) && !!data.country_name && isCountry) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Update') {
      if (!!data.title && (!!data.primary_rate || data.primary_rate === 0) && (!!data.additional_rate || data.additional_rate === 0) && !!data.country_name && isCountry) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
