import { share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { UserService as ApiUserService,} from '../../../client/api/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AlertService } from '../services/alert.service';
import {
  AddressAddReq,
  AddressRemoveReq,
  AddressUpdateReq,
  CustomerCreateReq,
  InlineResponse200,
  InlineResponse200Payload,
  MemberUpdateReq,
  OverridePassReq,
  UserCreateReq,
  UserPayload,
  UserResetPassReq,
  UserResponse,
  UserUpdateReq
} from '../../../client';
import { from } from 'rxjs';
import { TableDataSource } from '../common/interfaces/common.interface';
import { USER_TYPE } from '../common/constants';
@Injectable({
    providedIn: 'root'
})
export class UserService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiUserService: ApiUserService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
        private alertService: AlertService
    ) { }


    getAllUser() {
        return new Promise((resolve, reject) => {
            const seq = this.apiUserService.getAllUsers().pipe(share());
            seq.subscribe((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.error('ERROR', err);
                // show error alert
                reject();
            });
        });
    }

    createUser(params: UserCreateReq) {
        return new Promise((resolve, reject) => {
            const seq = this.apiUserService.createUser(params).pipe(share());
            seq.subscribe((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    this.snackBar.open('คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง', '', {
                        duration: 2000,
                        panelClass: 'blue-snackbar',
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                    reject();
                }
            }, err => {
                this.snackBar.open('คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง', '', {
                    duration: 2000,
                    panelClass: 'blue-snackbar',
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                reject();
            });
        }).then();
    }

    createCustomer(params: CustomerCreateReq) {
      return new Promise((resolve, reject) => {
          const seq = this.apiUserService.createCustomer(params).pipe(share());
          seq.subscribe((res: any) => {
              if (res) {
                  resolve(res);
              } else {
                console.log('res');
                console.log(res);
                  this.snackBar.open('คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง', '', {
                      duration: 2000,
                      panelClass: 'blue-snackbar',
                      horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,
                  });
                  reject();
              }
          }, err => {
            console.log(err);
            let txt = 'คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง';
            if(err.error.errors[0].message === 'email must be unique') {
              txt = 'Email ได้ถูกใช้งานแล้ว กรุณาสมัครด้วย Email อื่น'
            }
              this.snackBar.open(txt, '', {
                  duration: 2000,
                  panelClass: 'blue-snackbar',
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
              });
              reject();
          });
      }).then();
  }

    updateUser(id, params: UserUpdateReq) {
      this.loadingService.showLoader(true);
        const userParam = {
          userId: id,
          userUpdateReq: params
        };
        return new Promise((resolve, reject) => {
          const seq = this.apiUserService.updateUser(id, params).pipe(share());
          seq.subscribe((res: any) => {
            if (res.success) {
              this.alertService.success('ทำรายการสำเร็จ');
              this.loadingService.showLoader(false);
              resolve(res);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            this.loadingService.showLoader(false);
              reject();
          });
        });
      }

      deleteuser(params) {
        const userParam = {
          user: params
        };
        return new Promise((resolve, reject) => {
            const seq = this.apiUserService.deleteUser(params.id).pipe(share());
            seq.subscribe((res: any) => {
                // If the API returned a successful response, mark the user as logged in
                if (res.success) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.error('ERROR', err);
                // show error alert
                reject();
            });
        });
    }

    getUserById(params?: string): Promise<UserPayload> {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.getUser(params).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: UserResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload as UserPayload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }

    getUserBySearch(params?: string, tableOptions?: TableDataSource, hasCredit?: boolean) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.searchUser(params, '', '', '', true, hasCredit, tableOptions.pageNumber,'', tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: UserResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload as UserPayload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          console.error('ERROR', err);
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }

    getUserByType(type,contactNum, tableOptions?: TableDataSource) {
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.getUserByUserType(type, '', '', '', '', null, contactNum, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
        seq.subscribe((res) => {
            if (res) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.log(err);
            reject();
        });
    });
    }

    getUserByTypeAndSearchData(usertype, email, firstName, lastName,contactNum, tableOptions?: TableDataSource) {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.getUserByUserType(usertype, '', email, firstName, lastName, null,contactNum, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: UserResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload as UserPayload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          console.error('ERROR', err);
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }



    createAddress(params: AddressAddReq) {
      return new Promise((resolve, reject) => {
          const seq = this.apiUserService.addAddress(params).pipe(share());
          seq.subscribe((res: any) => {
              if (res) {
                  resolve(res);
              } else {
                  this.snackBar.open('คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง', '', {
                      duration: 2000,
                      panelClass: 'blue-snackbar',
                      horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,
                  });
                  reject();
              }
          }, err => {
              this.snackBar.open('คุณ กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง', '', {
                  duration: 2000,
                  panelClass: 'blue-snackbar',
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
              });
              reject();
          });
      }).then();
  }

    updateAddress(params) {
      this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const data = {
            address: params
          };
          const seq = this.apiUserService.updateAddress(params).pipe(share());
          seq.subscribe((res: any) => {
            if (res.success) {
              this.alertService.success('ทำรายการสำเร็จ');
              this.loadingService.showLoader(false);
              resolve(res);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            this.loadingService.showLoader(false);
              reject();
          });
        });
      }

      deleteAddress(params: AddressRemoveReq) {
        return new Promise((resolve, reject) => {
            const seq = this.apiUserService.removeAddress(params).pipe(share());
            seq.subscribe((res: any) => {
                // If the API returned a successful response, mark the user as logged in
                if (res.success) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.error('ERROR', err);
                // show error alert
                reject();
            });
        });
    }

    resetPassByUser(req: UserResetPassReq) {
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.resetPassReq(req).pipe(share());
        seq.subscribe((res: UserResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res.success) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.error('ERROR', err);
            // show error alert
            reject();
        });
     });
    }

    resetPassByAdmin(req: OverridePassReq) {
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.overridePass(req).pipe(share());
        seq.subscribe((res: UserResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res.success) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.error('ERROR', err);
            // show error alert
            reject();
        });
     });
    }

    updateMemberScore(req: MemberUpdateReq) {
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.memberUpdate(req).pipe(share());
        seq.subscribe((res: UserResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res.success) {
                resolve(res);
            } else {
                reject();
            }
        }, err => {
            console.error('ERROR', err);
            // show error alert
            reject();
        });
     });
    }

    advanceSearchCustomer(obj, tableOptions: TableDataSource) {
     this.loadingService.showLoader(true);
     return new Promise((resolve, reject) => {
      const seq = this.apiUserService.getUserByUserType(USER_TYPE.CUSTOMER,null,obj.email, obj.firstname, obj.lastname, true, obj.contactNum, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
       // If the API returned a successful response, mark the user as logged in
       seq.subscribe((res: UserResponse) => {
         // If the API returned a successful response, mark the user as logged in
         if (res) {
           this.loadingService.showLoader(false);
           resolve(res.payload as UserPayload);
         } else {
           this.loadingService.showLoader(false);
           reject();
         }
       }, err => {
         console.error('ERROR', err);
         this.loadingService.showLoader(false);
         // show error alert
         reject();
       });
     });
    }

    getUserDasshbaordById(params?: string): Promise<UserPayload> {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.getUserDashboardDetails(params).pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res: UserResponse) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload as UserPayload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }

    getUserDasshbaord(): Promise<InlineResponse200Payload> {
      this.loadingService.showLoader(true);
      return new Promise((resolve, reject) => {
        const seq = this.apiUserService.getDashboardData().pipe(share());
        // If the API returned a successful response, mark the user as logged in
        seq.subscribe((res) => {
          // If the API returned a successful response, mark the user as logged in
          if (res) {
            this.loadingService.showLoader(false);
            resolve(res.payload as InlineResponse200Payload);
          } else {
            this.loadingService.showLoader(false);
            reject();
          }
        }, err => {
          this.loadingService.showLoader(false);
          // show error alert
          reject();
        });
      });
    }
}
