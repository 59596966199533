/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type STOCKTXNACTION = 'ADD' | 'WITHDRAW' | 'ORDER';

export const STOCKTXNACTION = {
    ADD: 'ADD' as STOCKTXNACTION,
    WITHDRAW: 'WITHDRAW' as STOCKTXNACTION,
    ORDER: 'ORDER' as STOCKTXNACTION
};

