<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
    <mat-form-field>
      <input type="text" matInput required id="firstName" name="firstName" [(ngModel)]="local_data.firstName"
        placeholder="ชื่อ">
    </mat-form-field>
    <mat-form-field>
        <input type="text" matInput required id="lastName" name="lastName" [(ngModel)]="local_data.lastName"
          placeholder="นามสกุล">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput required id="nickName" name="nickName" [(ngModel)]="local_data.nickName"
          placeholder="ชื่อเล่น">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput required id="contactNum" name="contactNum" [(ngModel)]="local_data.contactNum"
          placeholder="เบอร์มือถือ">
      </mat-form-field>
      <mat-form-field *ngIf="action === 'Add'">
        <input type="password" matInput required id="password" name="password" [(ngModel)]="local_data.password"
          placeholder="password">
      </mat-form-field>
      <mat-form-field *ngIf="action === 'Add'">
          <mat-label>วันเกิด</mat-label>
          <input matInput [matDatepicker]="picker"
                  readonly
                  touchUi
                  startView="year"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="วันเกิด"
                  [(ngModel)]="local_data.dob"
                 (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="action === 'Update'">
        <input type="text" matInput id="dobtxt" name="dobtxt" [(ngModel)]="local_data.dobtxt"
          placeholder="วันเกิด" disabled>
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput required id="email" name="email" [(ngModel)]="local_data.email"
          placeholder="email">
      </mat-form-field>
      <mat-form-field>
        <mat-label>default language</mat-label>
        <select matNativeControl required name="status" [(ngModel)]="local_data.settings.defaultLanguage">
          <option value="EN">English</option>
          <option value="TH">Thai</option>
        </select>
      </mat-form-field>
      <ng-container *ngIf="role !== 'admin'">
        <mat-form-field>
          <input type="number" matInput required id="bust" name="bust" [(ngModel)]="local_data.bodyProportion.bust"
            placeholder="bust">
        </mat-form-field>
        <mat-form-field>
          <input type="number" matInput required id="waist" name="waist" [(ngModel)]="local_data.bodyProportion.waist"
            placeholder="waist">
        </mat-form-field>
        <mat-form-field>
          <input type="number" matInput required id="hip" name="hip" [(ngModel)]="local_data.bodyProportion.hip"
            placeholder="hip">
        </mat-form-field>
        <mat-form-field>
          <input type="number" matInput required id="height" name="height" [(ngModel)]="local_data.bodyProportion.height"
            placeholder="height">
        </mat-form-field>
      </ng-container>
      <section class="example-section">
        <label style="margin: 0 10px;">status:</label>
        <mat-radio-group>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(true)" [checked]="local_data.active === true" value="true">Active</mat-radio-button>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(false)" [checked]="!!!local_data.active" value="false">Deactive</mat-radio-button>
        </mat-radio-group>
      </section>
  
      <!-- <mat-form-field>
        <mat-label>User Type</mat-label>
        <select matNativeControl required name="user_type" [(ngModel)]="local_data.user_type">
          <option value="CUSTOMER">STAFF</option>
          <option value="STAFF">STAFF</option>
          <option value="ADMIN">ADMIN</option>
        </select>
      </mat-form-field> -->
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>

<mat-dialog-content class="mat-typography" *ngIf="action === 'Reset'">
  กรุณาใส่รหัสผ่านใหม่ของคุณ <b>{{local_data.title}}</b>
  <form #userForm="ngForm">
   <mat-form-field>
     <input type="text" matInput required id="password" name="password" [(ngModel)]="password"
       placeholder="password">
   </mat-form-field>
   </form>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="action === 'Score'">
  กรุณากรอก Membership
  <form #userForm="ngForm">
   <mat-form-field>
     <input type="text" matInput required id="memberScore" name="memberScore" [(ngModel)]="member.memberScore"
       placeholder="score">
   </mat-form-field>
   <mat-form-field>
    <mat-label>Member type</mat-label>
    <select matNativeControl required name="memberType" [(ngModel)]="member.memberType">
      <option value="VIP">VIP</option>
      <option value="STANDARD">STANDARD</option>
    </select>
  </mat-form-field>
  <mat-form-field *ngIf="member.memberType === 'VIP'">
    <mat-label>Expired Date</mat-label>
    <input matInput [matDatepicker]="picker"
              readonly
              touchUi
              [ngModelOptions]="{standalone: true}"
              placeholder="expired"
              [(ngModel)]="member.expDate"
            (dateInput)="addEventExpired('input', $event)" (dateChange)="addEventExpired('change', $event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
   </form>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
