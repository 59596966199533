import { map, share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TableDataSource } from '../common/interfaces/common.interface';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ProductService as ApiProductService} from '../../../client/api/product.service';
import { ImageService as ApiImageService } from '../../../client/api/image.service';
import { WishlistService as ApiWishListService } from '../../../client/api/wishlist.service';
import {
    ProductPayload,
    ProductReq,
    ProductResponse,
    ImageResponse,
    WishListResponse,
    WishListAddReq,
    TAXTYPE,
    IProduct,
    PRODUCTSTATUS
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiProductService: ApiProductService,
        private apiImageService: ApiImageService,
        private apiWishListService: ApiWishListService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    createProduct(product: ProductReq) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.createProduct(product).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject(err);
          });
      });
    }

    updateProductById(id, data: ProductReq) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.updateProduct(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getProductRelatedById(productId) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.getProductRelatedProduct(productId).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getProductColorById(productId) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.getProductRelatedColor(productId).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    uploadImage(file: Blob) {
        return new Promise((resolve, reject) => {
            const seq = this.apiImageService.uploadProduct(file).pipe(share());
            seq.subscribe((res: ImageResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    getImageById(id) {
        return new Promise((resolve, reject) => {
          const seq = this.apiImageService.getImage(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    deleteImageById(id) {
        return new Promise((resolve, reject) => {
          const seq = this.apiImageService._delete(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    uploadModelSize(file: Blob) {
        return new Promise((resolve, reject) => {
            const seq = this.apiImageService.uploadModelSize(file).pipe(share());
            seq.subscribe((res: ImageResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    uploadSizeChart(file: Blob) {
        return new Promise((resolve, reject) => {
            const seq = this.apiImageService.uploadSizeChart(file).pipe(share());
            seq.subscribe((res: ImageResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    getAllProduct(tableOptions?: TableDataSource, selectedStatus?: any) {
        console.log(selectedStatus);
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.getAllProducts(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder, tableOptions.sortBy, null, 4, selectedStatus || null).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                    this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                    this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
              console.log(err);
              this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getProductById(id, currencyId) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.getProduct(id, null, currencyId).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    deleteProductById(id) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.deleteProduct(id).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getNewestProductList(currencyId) {
        return new Promise((resolve, reject) => {
          const seq = this.apiProductService.getProductByName('', 0, 4, null, null, null, null, 12, null, null, currencyId, null, 41, PRODUCTSTATUS.PUBLISH).pipe(share());
          seq.subscribe((res: ProductResponse) => {
              if (res) {
                  resolve(res);
              } else {
                  reject();
              }
          }, err => {
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getWishListByUserId(userId) {
        return new Promise((resolve, reject) => {
            const seq = this.apiWishListService.getWishlist(userId).pipe(share());
            seq.subscribe(async (res: WishListResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.log(err);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    attachWishList(wishListAddReq: WishListAddReq) {
        return new Promise((resolve, reject) => {
            const seq = this.apiWishListService.attachWishList(wishListAddReq).pipe(share());
            seq.subscribe((res: WishListResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.log(err);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    detachWishList(wishListAddReq: WishListAddReq) {
        return new Promise((resolve, reject) => {
            const seq = this.apiWishListService.detachWishList(wishListAddReq).pipe(share());
            seq.subscribe((res: WishListResponse) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.log(err);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    searchProduct(myTax, currencyId, tableOption: TableDataSource) {
        return new Promise((resolve, reject) => {
            const seq = this.apiProductService.getProductByName('', tableOption.pageNumber, tableOption.pageSize, tableOption.filter, tableOption.sortOrder, tableOption.sortBy, null, myTax.tax_type === TAXTYPE.TAG ? myTax.id : null, myTax.tax_type === TAXTYPE.CATEGORY ? myTax.id : null, null, currencyId, myTax.tax_type === TAXTYPE.COLLECTION ? myTax.id : null, myTax.tax_type === TAXTYPE.BRAND ? Number(myTax.id) : 41, PRODUCTSTATUS.PUBLISH).pipe(share());
            seq.subscribe((res) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, err => {
                console.log(err);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    searchProductName(title, table, isFromAdmin?: boolean) {
        let search = null;
        if(!isFromAdmin) {
            search = PRODUCTSTATUS.PUBLISH;
        }
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiProductService.getProductByName(title, table.pageNumber, table.pageSize, table.filter, table.sortOrder, table.sortBy, null,null,null,null,null, null, null, search).pipe(share());
            seq.subscribe((res: ProductResponse) => {
                if (res) {
                    this.loadingService.showLoader(false);
                    resolve(res.payload);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                console.log(err);
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    advanceSearch(title, table) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
            const seq = this.apiProductService.getProductByAdvanceSearch(title, table.pageNumber, table.pageSize, table.filter, table.sortOrder, table.sortBy, null,null,null,null,null, null, null, PRODUCTSTATUS.PUBLISH).pipe(share());
            seq.subscribe((res: ProductResponse) => {
                if (res) {
                    console.log(res);
                    this.loadingService.showLoader(false);
                    resolve(res.payload);
                } else {
                    this.loadingService.showLoader(false);
                    reject();
                }
            }, err => {
                console.log(err);
                this.loadingService.showLoader(false);
                if (err && err?.error?.name === 'NoAuthorization') {
                  this.authService.logout();
                  this.router.navigateByUrl('login');
                }
                reject();
            });
        });
    }

    
}
