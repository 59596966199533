import {share} from 'rxjs/operators';
import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {Api} from '../services/api.service';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ReqNewPass} from '../common/interfaces/user.interface';
import {ApiResponse} from '../common/interfaces/api-response.interface';
import {AuthService as ApiAuthService} from '../../../client/api/auth.service';
import {UserService as ApiUserService} from '../../../client/api/user.service';
import {ShippingService} from '../services/shipping.service';
import {CurrencyService} from '../services/currency.service';
import {ProductService} from '../services/product.service';
import {TaxonomyService} from './../services/taxonomy.service';
import {
    AuthPayload,
    AuthRequest,
    AuthResponses,
    ProductResponse,
    ResetPassReq,
    SORTORDER,
    TaxonomyResponse,
    UserPayload,
    UserResponse
} from '../../../client';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { LoaderService } from './../services/loader.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    isBrowser: boolean;
    user: any;
    token: string;
    authenticationState = new BehaviorSubject(false);
    private isTaxLoad = new Subject<boolean>();
    private isTaxHightLightLoad = new Subject<boolean>();
    private isCurrencyLoad = new Subject<boolean>();

    constructor(
        public api: Api,
        @Inject(PLATFORM_ID) private platformId: any,
        private loadingService: LoaderService,
        private apiAuthService: ApiAuthService,
        private apiUserService: ApiUserService,
        private shippingService: ShippingService,
        private currencyService: CurrencyService,
        private productService: ProductService,
        private taxonomyService: TaxonomyService,
        private snackBar: MatSnackBar,
        public router: Router
    ) {
        this.checkIsPlatformBrowser(platformId);
    }

    async checkIsPlatformBrowser(platformId) {
        if (isPlatformBrowser(platformId)) {
            this.isBrowser = true;
        } else {
            this.isBrowser = false;
        }
        await this.checkToken();
    }

    isFromBroswer() {
        const userAgent = window.navigator.userAgent;
        if(this.isBrowser) {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return false;
            } else {
                return true;
            }
        }
    }

    async checkToken() {
        if (this.isBrowser) {
            const token = await localStorage.getItem('tokenOnlyBU');
            // && !this.tokenExpired(token)
            if (token) {
                this.authenticationState.next(true);
            } else {
                this.authenticationState.next(false);
            }
        }
    }

    confirmAccount(params) {
        return new Promise((resolve, reject) => {
            const data = {
                confirmToken: params
            };
            const seq = this.apiAuthService.confirmAccount(data).pipe(share());
            seq.subscribe((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    this.snackBar.open('พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง', '', {
                        duration: 2000,
                        panelClass: 'blue-snackbar',
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                    reject();
                }
            }, err => {
                this.snackBar.open('พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง', '', {
                    duration: 2000,
                    panelClass: 'blue-snackbar',
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                reject();
            });
        }).then();
    }

    async tokenExpired(tk) {
        if (tk) {
            const expiry = (JSON.parse(atob(tk.split('.')[1]))).exp;
            return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
        }
    }

    async logout() {
        if (this.isBrowser) {
            await localStorage.removeItem('tokenOnlyBU');
            await localStorage.removeItem('user');
            await localStorage.removeItem('localWishList');
            await localStorage.removeItem('cart');
            this.authenticationState.next(false);
            this.router.navigate(['login']);
        }

    }

    async isAuthenticated() {
        const user = await this.getLocalUser();
        return (this.authenticationState.value && user) ? true : false;
    }


    /**
     * Send a POST request to our login endpoint with the data
     * the user entered on the form.
     */
    login(accountInfo: AuthRequest) {
        return new Promise((resolve, reject) => {
            const seq = this.apiAuthService.auth(accountInfo).pipe(share());
            seq.subscribe(async (res: AuthResponses) => {
                // If the API returned a successful response, mark the user as logged in
                if (res) {
                    if(!res.payload.active) {
                        this.snackBar.open('พบข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่', '', {
                            duration: 2000,
                            panelClass: 'blue-snackbar',
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                        resolve('isInactive');
                    } else {
                        const user = res.payload.user as any;
                        if (res.success === false || user.active === false) {
                            this.snackBar.open('พบข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่', '', {
                                duration: 2000,
                                panelClass: 'blue-snackbar',
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                            reject();
                            return;
                        }
                        await this.storeUserData(res.payload);
                        await this.storeCountries();
                        resolve(res.payload.user);
                    }
                } else {
                    reject();
                }

            }, err => {
                console.error('ERROR', err);
                // show error alert
                reject();
            });
        });
    }

    /**
     * Send a POST request to our signup endpoint with the data
     * the user entered on the form.
     */

    signup(accountInfo: any) {

        return new Promise((resolve, reject) => {
            const seq = this.apiUserService.createUser(accountInfo).pipe(share());
            seq.subscribe((res: UserResponse) => {
                // If the API returned a successful response, mark the user as logged in
                if (res) {
                    this.storeUserData(res.payload);
                    resolve(res.payload.user);
                } else {
                    console.error('ERROR', res);
                    reject();
                }

            }, err => {
                console.error('ERROR', err.errors[0].message);
                // show error alert
                reject();
            });
        });

    }

    forgotPassword(passForm) {
        const reqOpts = {
            observe: 'response'
        };

        return new Promise(async (resolve, reject) => {
            const params = {
                email: passForm
            };

            const seq = this.api.directPost(`auth/forgot-pass`, params, reqOpts).pipe(share());

            seq.subscribe((res: any) => {
                // If the API returned a successful response, mark the user as logged in
                if (res.status === 200) {
                    resolve(res);
                }

            }, err => {
                // show error alert
                this.api.shorErrowMessage(err.error.message);
                reject(err.error.message);
            });
        });
    }

    setupNewPass(passForm: ReqNewPass) {
        const reqOpts = {
            observe: 'response'
        };

        return new Promise(async (resolve, reject) => {
            const params = {
                passForm
            };

            const seq = this.api.directPost(`users/resetpass/${passForm.userId}`, params, reqOpts).pipe(share());

            seq.subscribe((res: any) => {
                // If the API returned a successful response, mark the user as logged in
                if (res.status === 200) {
                    this.updateUserTokenData(res.body.result);
                    resolve(res.body.result);
                }

            }, err => {
                console.error('ERROR', err);
                // show error alert
                reject(err.error.result);
            });
        });
    }

    resetPassByUser(req: ResetPassReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiAuthService.resetPassword(req).pipe(share());
          seq.subscribe((res: AuthResponses) => {
              // If the API returned a successful response, mark the user as logged in
              if (res.success) {
                this.loadingService.showLoader(false);
                // TODO:: PUI this.updateUserTokenDataNew(res.payload);
                  this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
              this.loadingService.showLoader(false);
              console.error('ERROR', err);
              // show error alert
              reject();
          });
       });
      }

    /**
     * Process a login/signup response to store user data
     */
    async storeUserData(resp: AuthPayload | UserPayload) {
        if (this.isBrowser) {
            this.user = resp.user;
            this.token = resp.token;
            localStorage.setItem('tokenOnlyBU', this.token);
            localStorage.setItem('user', JSON.stringify(this.user));
            localStorage.setItem('localWishList', JSON.stringify([]));
            localStorage.setItem('cart', JSON.stringify([]));
            await this.getWishList(JSON.stringify(this.user));
            this.authenticationState.next(true);
        }
    }

    async getWishList(user) {
        const data = JSON.parse(user);
        const result = await this.productService.getWishListByUserId(data.id) as any;
        const saveData = !!result.payload.wishlist ? result.payload.wishlist.map(it => it.id) : [];
        this.storeMyWishList(saveData);
    }

    async storeCountries() {
        const result = await this.shippingService.getCountries() as any;
        if (result && this.isBrowser) {
            const noThList = result.payload.countries.filter(it => it.code !== 'TH');
            const thModel = result.payload.countries.filter(it => it.code === 'TH');
            localStorage.setItem('countries', JSON.stringify(thModel.concat(noThList)));
        }
    }

    async storeCurrency() {
        const table = {
            pageNumber: 0,
            pageSize: 30,
            filter: null,
            sortOrder: SORTORDER.Desc
        };
        const result = await this.currencyService.getAllCurrency(table) as any;
        if (result) {
            if (this.isBrowser) {
                localStorage.setItem('currency', JSON.stringify(result.payload.currencies));
                this.isCurrencyLoad.next(true);
            }

        }
    }

    async storeTaxonomy() {
        const table = {
            pageNumber: 0,
            pageSize: 500,
            filter: null,
            sortOrder: SORTORDER.Desc
        };
        const data = await this.taxonomyService.getAllTaxonomies(table) as TaxonomyResponse;
        if (data) {
            if (this.isBrowser) {
                localStorage.setItem('taxonomy', JSON.stringify(data.payload.taxonomies));
                this.isTaxLoad.next(true);
            }
        }
    }

    async storeTaxonomyHightLight(id) {
        const currencyId = await this.getMyCurrency();
        const table = {
            pageNumber: 0,
            pageSize: 4,
            filter: null,
            sortOrder: SORTORDER.Desc
        };
        const data = await this.productService.searchProduct(id, currencyId, table) as ProductResponse;
        if (data && this.isBrowser) {

            localStorage.setItem('highlight', JSON.stringify(data.payload.products));
            this.isTaxHightLightLoad.next(true);
        }
    }

    async storeMyCurrency(currency) {
        if (this.isBrowser) {
            localStorage.setItem('localCurrency', currency);
        }
    }

    async storeMyWishList(wishListId) {
        if (this.isBrowser) {
            localStorage.setItem('localWishList', JSON.stringify(wishListId));
        }

    }

    updateUserTokenData(resp: ApiResponse) {
        if (this.isBrowser) {
            this.user = resp.data.user;
            this.token = resp.data.token;
            // set a key/value
            localStorage.set('tokenOnlyBU', this.token);
            localStorage.set('user', this.user);
        }

    }

    updateUserTokenDataNew(resp) {
        if (this.isBrowser) {
            this.user = resp.user;
            this.token = resp.token;
            localStorage.set('tokenOnlyBU', this.token);
            localStorage.set('user', this.user);
        }
    }

    isTaxOnLoad(): Observable<boolean> {
        return this.isTaxLoad.asObservable();
    }

    isTaxHightLightOnLoad(): Observable<boolean> {
        return this.isTaxHightLightLoad.asObservable();
    }

    isCurrencyOnLoad(): Observable<boolean> {
        return this.isCurrencyLoad.asObservable();
    }


    async getToken() {
        if (this.isBrowser) {
            const token = await localStorage.getItem('tokenOnlyBU');
            return token;
        }

    }


    async getLocalUser() {
        if (this.isBrowser) {
            const user = await localStorage.getItem('user');
            return user;
        }

    }

    async getCountries() {
        if (this.isBrowser) {
            const countries = await localStorage.getItem('countries');
            return countries;
        }

    }

    async getCurrency() {
        if (this.isBrowser) {
            const currency = await localStorage.getItem('currency');
            return currency;
        }

    }

    async getTaxonomy() {
        if (this.isBrowser) {
            const taxonomy = await localStorage.getItem('taxonomy');
            return taxonomy;
        }

    }

    async getTaxonomyHighLight() {
        if (this.isBrowser) {
            const taxonomy = await localStorage.getItem('highlight');
            return taxonomy;
        }

    }

    async getMyCurrency() {
        if (this.isBrowser) {
            const localCurrency = await localStorage.getItem('localCurrency');
            return localCurrency;
        }
    }

    async getMyLocalWishList() {
        if (this.isBrowser) {
            const localWishList = await localStorage.getItem('localWishList');
            return localWishList;
        }

    }

    async setLocalUser(user) {
        if (this.isBrowser) {
            localStorage.setItem('user', user);
        }
    }
}
