import { Component, OnInit, Inject, Optional, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { USER_TYPE } from '../common/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-credit-customer',
  templateUrl: './admin-credit-customer.component.html',
  styleUrls: ['./admin-credit-customer.component.scss']
})
export class AdminCreditCustomerComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'firstName', 'lastName', 'username', 'role', 'status', 'credit', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  userLength: any;
  selectedCustomer = 'username';
  searchModel;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(public userService: UserService,
              public router: Router,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.searchModel = {
      email: null,
      firstname: null,
      lastname: null
    };
    this.setupTableOptions();
    this.start();
  }

  start() {
    this.getUser();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  async getUser(pageIndex?: number) {
    if (pageIndex) {
        this.tableOptions.pageNumber = pageIndex;
      } else {
        this.setupTableOptions();
      }
    const result: any = await this.userService.getUserBySearch(null, this.tableOptions, true);
    if (result) {
      this.dataSource = new MatTableDataSource(result.users);
      console.log(result);
      this.userLength = result.userLength;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
      }
    }
  }

  openCreditDialog(obj: any) {
    const dialogRef = this.dialog.open(CreditDialogContent, {
      data: obj,
  });

  dialogRef.afterClosed().subscribe(result => {
    if(!!!result) {
      return;
    }
  });
  }

  openDialog(obj: any) {
    this.router.navigate(['/dashboard/customer/'+obj.id]);
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
        this.searchModel = {
          email: null,
          firstname: null,
          lastname: null
        };
        this.searchText = '';
        this.getUser(0);
      }

    if (data.length > 2) {
      this.searchModel = {
        email: this.selectedCustomer === 'username' ? this.searchText : null,
        firstname: this.selectedCustomer === 'firstname' ? this.searchText : null,
        lastname: this.selectedCustomer === 'lastname' ? this.searchText : null,
        contactNum: this.selectedCustomer === 'contactNum' ? this.searchText : null
      };
      this.searchText = data;
      const result = await this.userService.getUserByTypeAndSearchData(USER_TYPE.CUSTOMER, this.searchModel.email, this.searchModel.firstname, this.searchModel.lastname, this.searchModel.contactNum, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.users);
          this.dataSource._updateChangeSubscription();
          if(this.paginator) {
            if (this.paginator.pageIndex) {
              this.paginator.pageIndex = 0;
            }
          }
          if (result.users.length === 0) {
              this.userLength = 0;
          } else {
              this.userLength = result.userformLengthLength;
          }
      } else {
          this.userLength = 0;
      }
    }
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getUser(pageObj.pageIndex);
  }

  selectedCustomerSearch(e) {
    this.selectedCustomer = e.target.value;
    this.searchModel = {
      email: this.selectedCustomer === 'username' ? this.searchText : null,
      firstname: this.selectedCustomer === 'firstname' ? this.searchText : null,
      lastname: this.selectedCustomer === 'lastname' ? this.searchText : null,
      contactNum: this.selectedCustomer === 'contactNum' ? this.searchText : null
    };
    this.getUser();
  }

}



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'credit-dialog',
  templateUrl: 'credit-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class CreditDialogContent {
  local_data: any;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index', 'amount', 'createdAt', 'expireDate'];
  pageSizeOptions = ['10', '30', '50'];
  constructor(
      public dialogRef: MatDialogRef<CreditDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
        this.local_data = { ...data };
        this.dataSource = new MatTableDataSource(this.local_data.credits);
        this.dataSource._updateChangeSubscription();

  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
}
}
