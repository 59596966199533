<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography">
  <form #productForm="ngForm">
    <div class="text-right">
        <span class="label font-bold m-b-0 m-t-0" 
            [ngClass]="{'label-primary': local_data.detail.stock.warehouse === 'Office', 
            'label-warning': local_data.detail.stock.warehouse === 'Warehouse', 
            'label-danger': local_data.detail.stock.warehouse === 'Preorder'}">
            {{ local_data.detail.stock.warehouse }}
        </span>
    </div>
    <div class="text-right">
        <span>{{ local_data.size }}</span>
    </div>
    <mat-form-field>
      <input matInput type="number" placeholder="จำนวน" step="0" min="0" [(ngModel)]="local_data.quantity" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field *ngIf="local_data.StockLocationId === 3">
        <input matInput type="number" placeholder="จำนวน preorderDay" step="0" min="0" [(ngModel)]="local_data.preOrderDays" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
