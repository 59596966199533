/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AMOUNTTYPE = 'PERCENTAGE' | 'FLAT';

export const AMOUNTTYPE = {
    PERCENTAGE: 'PERCENTAGE' as AMOUNTTYPE,
    FLAT: 'FLAT' as AMOUNTTYPE
};

