<div class="header">
    <h3 mat-dialog-title>{{ data.title }}</h3>
</div>
<div class="text-center" mat-dialog-content>
    <div class="icon">
        <p class="dialog-message" [innerHTML]="data.message"></p>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-flat-button color="warn" (click)="confirm()">{{ data.confirmText }}</button>
    <button mat-button mat-dialog-close mat-flat-button>{{ data.cancelText }}</button>
</mat-dialog-actions>

