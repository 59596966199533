<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
      <mat-form-field>
        <input type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
          placeholder="title">
      </mat-form-field>
      <mat-form-field>
        <input type="number" matInput required id="primary_rate" name="primary_rate" [(ngModel)]="local_data.primary_rate"
          placeholder="primary_rate">
      </mat-form-field>
      <mat-form-field>
        <input type="number" matInput required id="additional_rate"[min]="1" name="additional_rate" [(ngModel)]="local_data.additional_rate"
          placeholder="additional_rate">
      </mat-form-field>
      <mat-form-field>
        <mat-label>เลือกประเทศ</mat-label>
        <input matInput
               [(ngModel)]="local_data.country_name"
               aria-label="State"
               [matAutocomplete]="auto"
               [formControl]="stateCtrl">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let state of filteredStates | async" (onSelectionChange)="updateCountryCode($event, state)" [value]="state.name">
            <span>{{state.name}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
