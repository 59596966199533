<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
      <mat-form-field>
        <input type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
          placeholder="title">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput required id="title_th" name="title_th" [(ngModel)]="local_data.title_th"
          placeholder="title_th">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput required id="index" name="index" [(ngModel)]="local_data.index"
          placeholder="index">
      </mat-form-field>
      <!-- <mat-form-field>
        <input type="text" matInput required id="tax_type" name="tax_type" [(ngModel)]="local_data.tax_type"
          placeholder="tax_type">
      </mat-form-field> -->
      <mat-form-field>
        <mat-select placeholder="ประเภท"  id="tax_type" name="tax_type" [(ngModel)]="local_data.tax_type" [ngModelOptions]="{standalone: true}" (selectionChange)="onSelectionChange($event)">
          <mat-option [value]="data" *ngFor="let data of taxTypeLists">
            {{ data }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <section class="example-section">
        <label style="margin: 0 10px;">status:</label>
        <mat-radio-group>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(true)" [checked]="local_data.isActive === true" value="true">Active</mat-radio-button>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(false)" [checked]="!!!local_data.isActive" value="false">Deactive</mat-radio-button>
        </mat-radio-group>
      </section>
      <file-pond
        *ngIf="local_data.tax_type === 'COLOR'"
        #myPond
        [options]="pondOptions"
        [files]="pondFiles"
        (oninit)="pondHandleInit()"
        (onaddfile)="pondHandleAddFile($event, '')"
        (onprocessfile)="pondHandleProcessFile($event)"
        (onremovefile)="pondHandleRemoveFile($event, '')"
        >
        </file-pond>
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
