import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TableDataSource } from '../common/interfaces/common.interface';
import { PageEvent } from '@angular/material/paginator';
import { ProductService } from '../services/product.service';
import { PRODUCTSTATUS, SORTORDER } from '../../../client';
import * as _ from 'lodash';

@Component({
    selector: 'select-product-dialog',
    templateUrl: 'select-product-dialog.html',
  })
  export class SelectProductDialogContent {
    action: string;
    local_data: any;
    tableOptions: TableDataSource;
    pageSizeOptions = ['10', '30', '50'];
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['select', 'sku', 'title', 'detail', 'stock', 'price'];
    displayedColumns2: string[] = ['select', 'sku', 'title', 'detail'];
    listLength: any;
    productList;
    isNoAction = false;
    constructor(
        public dialogRef: MatDialogRef<SelectProductDialogContent>,
        public productService: ProductService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data) {
        this.productList = data[0];
        this.isNoAction = data[1];
        this.setupTableOptions();
        this.fetchProductData();
    }
  
    setupTableOptions() {
      this.tableOptions = {
        pageNumber: 0,
        pageSize: 10,
        filter: null,
        sortOrder: SORTORDER.Desc,
        sortBy: 'updatedAt'
      };
    }
  
    async fetchProductData(pageIndex?: number) {
      if (pageIndex) {
        this.tableOptions.pageNumber = pageIndex;
      } else {
        this.setupTableOptions();
      }
      const result = await this.productService.getAllProduct(this.tableOptions, PRODUCTSTATUS.PUBLISH) as any;
      if(!!result) {
        let productList;
        productList = result.payload.products.map(it => ({
            id: it.id,
            price: it.price,
            sku: it.sku,
            title: it.title,
            stock: _.sortBy(it.Stocks.filter(aa => aa.StockLocationId === 1 && aa.quantity > 0), 'size').reverse(),
            detail: it.Colors.map(it => it.title).toString(),
            productObj: it
        }));
        if(this.productList && this.productList.length > 0) {
            for(const result of productList) {
                const product = this.productList.filter(it => it.id === result.id);
                if(product) {
                  for(const dd of product) {
                    result.stock = result.stock?.filter(it => dd.size?.size !== it.size);
                  }
                }
            }
        }
        this.dataSource = new MatTableDataSource(productList);
        this.listLength = result.payload.listLength;
      }
    }
  
    onPageChanged(pageObj: PageEvent) {
      if (pageObj.pageIndex) {
        this.tableOptions.pageNumber = pageObj.pageIndex;
    } else {
        this.setupTableOptions();
    }
      this.fetchProductData(pageObj.pageIndex);
    }

    async applyFilter(data) {
        this.setupTableOptions();
        if (data.length === 0) {
              this.fetchProductData(0);
          }
    
        if (data.length > 2) {
          const result = await this.productService.searchProductName(data, this.tableOptions) as any;
          if (result) {
                let productList;
                productList = result.products.map(it => ({
                    id: it.id,
                    price: it.price,
                    sku: it.sku,
                    title: it.title,
                    stock: it.Stocks.filter(aa => aa.StockLocationId === 1 && aa.quantity > 0).sort((a, b) => b.size - a.size),
                    detail: it.Colors.map(it => it.title).toString(),
                    productObj: it
                }));
                if(this.productList && this.productList.length > 0) {
                    for(const result of productList) {
                        const product = this.productList.filter(it => it.id === result.id);
                        if(product) {
                          for(const dd of product) {
                            result.stock = result.stock?.filter(it => dd.size?.size !== it.size);
                          }
                        }
                    }
                }

              this.dataSource = new MatTableDataSource(productList);
              if (result.products.length === 0) {
                  this.listLength = 0;
              } else {
                  this.listLength = result.listLength;
              }
          } else {
              this.listLength = 0;
          }
        }
      }
  
    onSelectProduct(item) {
        item.productObj['size'] = item.size;
        this.local_data = item.productObj;
        this.local_data['size'] = null;
    }
  
    doAction() {
        this.dialogRef.close({ event: 'Submit', data: this.local_data });
    }
  
    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }

    onChangeSize(e) {
        this.local_data.size = e;
    }

    checkDisable() {
      let isDis = true;

      if(!!this.local_data) {
        isDis = false;
      }

      if(!this.isNoAction && !!!this.local_data?.size) {
        isDis = true;
      }
      return isDis;
    }
  }
  