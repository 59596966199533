<mat-tab-group [selectedIndex]="selectIndex">
  <mat-tab label="Main Banner">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-card>
          <mat-card-content>
            <div fxLayout="row wrap" class="align-items-center">
              <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                <mat-card-title>Main Banners</mat-card-title>
                <br>
               <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div style="float: right; margin-right: 30px; padding-bottom: 30px;">
                    <button mat-raised-button (click)="openDialog('Add', {}, '')" color="primary">เพิ่ม banner</button>
                  </div>
                </div>
               </div>
               <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
    
                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> เลขที่ </th>
                  <td mat-cell *matCellDef="let item; let i = index;"> 
                    {{ i + 1 }} </td>
                </ng-container>
    
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef> ภาพ </th>
                  <td mat-cell *matCellDef="let element; let i = index;"> 
                    <div>
                      <img src="{{ element.img }}" alt="" width="400" height="auto">
                      <img src="{{ element.imgMobile }}" alt="" width="400" height="auto">
                    </div>
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="url">
                  <th mat-header-cell *matHeaderCellDef> url </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <div class="m-l-15">
                        <p class="font-bold m-b-0 m-t-0">{{ element.bannerUrl }}</p>
                      </div>
                    </div>
                  </td>
                </ng-container>
    
    
                <ng-container matColumnDef="orderNo">
                  <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
                  <td mat-cell *matCellDef="let item; let i = index;"> 
                    {{ item.orderNo }} </td>
                </ng-container>
    
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                      <a (click)="openDialog('Update',element, '')" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                      <a (click)="openDialog('Delete',element, '')" class="m-r-10 cursor-pointer text-dark">
                        <mat-icon style="color: #fc4b6c;">delete</mat-icon>
                      </a>
                  </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
              </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Season Banner">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-card>
          <mat-card-content>
            <div fxLayout="row wrap" class="align-items-center">
              <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                <mat-card-title>Seasonal Banners</mat-card-title>
                <br>
                <table mat-table [dataSource]="dataSourceSeason" class="table employee-list no-wrap">
    
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> เลขที่ </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                      {{ i + 1 }} </td>
                  </ng-container>
    
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> หัวข้อ </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="font-bold m-b-0 m-t-0">{{ element.name }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> ชื่อหัวข้อ </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="font-bold m-b-0 m-t-0">{{ element.title }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> ภาพ </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> 
                      <div>
                        <img src="{{ element.img }}" alt="" width="200px" height="200px">
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> url </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="font-bold m-b-0 m-t-0">{{ element.bannerUrl }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                        <a (click)="openDialog('Update',element, 'season')" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                    </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="seasonDisplayedSColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: seasonDisplayedSColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Bottom Banner">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-card>
          <mat-card-content>
            <div fxLayout="row wrap" class="align-items-center">
              <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                <mat-card-title>Bottom Banners</mat-card-title>
                <br>
                <table mat-table [dataSource]="dataSourceBottom" class="table employee-list no-wrap">
    
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> เลขที่ </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                      {{ i + 1 }} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> ภาพ </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> 
                      <div>
                        <img src="{{ element.img }}" alt="" width="200px" height="200px">
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> url </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="font-bold m-b-0 m-t-0">{{ element.bannerUrl }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                        <a (click)="openDialog('Update',element, 'bottom')" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                    </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Youtube"> 
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-card>
          <mat-card-content>
            <div fxLayout="row wrap" class="align-items-center">
              <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                <mat-card-title>LINK YOUTUBE (ใส่แค่ id เช่น https://www.youtube.com/watch?v=DSWYAclv2I8 เอาแต่ DSWYAclv2I8 มาแป๊ะ)</mat-card-title>
                  <div fxLayout="row wrap">
                    <div fxFlex="80">
                        <!-- <input type="text" required id="ัlinkYoutube" name="ัlinkYoutube" [(ngModel)]="linkYoutube"
                        placeholder="linkYoutube"> -->
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="linkYoutube" [(ngModel)]="linkYoutube">
                        </mat-form-field>
                      
                    </div>
                    <div fxFlex="20">
                      <button (click)="saveYoutube()" mat-flat-button color="warn" [disabled]="!linkYoutube">UPDATE</button>
                    </div>
                  </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Gallery">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-card>
          <mat-card-content>
            <div fxLayout="row wrap" class="align-items-center">
              <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                <mat-card-title>GALLERY</mat-card-title>
                <br>
                <div fxLayout="row wrap">
                 <div fxFlex="100">
                   <div style="float: right; margin-right: 30px; padding-bottom: 30px;">
                     <button mat-raised-button (click)="openDialog('Add', {}, 'gallery')" color="primary">เพิ่ม GALLERY</button>
                   </div>
                 </div>
                </div>
                <table mat-table [dataSource]="dataSourceGallery" class="table employee-list no-wrap">
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> เลขที่ </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                      {{ i + 1 }} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> ภาพ </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> 
                      <div>
                        <img src="{{ element.img }}" alt="" width="200px" height="200px">
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> url </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex">
                        <div class="m-l-15">
                          <p class="font-bold m-b-0 m-t-0">{{ element.bannerUrl }}</p>
                        </div>
                      </div>
                    </td>
                  </ng-container>
      
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                        <a (click)="openDialog('Update',element, 'gallery')" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                        <a (click)="openDialog('Delete',element, 'gallery')" class="m-r-10 cursor-pointer text-dark">
                          <mat-icon style="color: #fc4b6c;">delete</mat-icon>
                        </a>
                    </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="seasonDisplayedSColumns2"></tr>
                  <tr mat-row *matRowDef="let row; columns: seasonDisplayedSColumns2;"></tr>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>



