<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <div class="blank-lists-container">
      <div class="blank-icon">
        <mat-icon>search</mat-icon>
        <div class="txt-message">ไม่มีข้อมูล</div>
      </div>
  </div>
  </mat-card-content>
</mat-card>