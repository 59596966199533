import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './api/auth.service';
import { ContentService } from './api/content.service';
import { CurrencyService } from './api/currency.service';
import { DeliveryService } from './api/delivery.service';
import { HealthCheckService } from './api/healthCheck.service';
import { ImageService } from './api/image.service';
import { NotificationService } from './api/notification.service';
import { OrderService } from './api/order.service';
import { ProductService } from './api/product.service';
import { PromotionService } from './api/promotion.service';
import { SettingsService } from './api/settings.service';
import { ShippingService } from './api/shipping.service';
import { StockService } from './api/stock.service';
import { StockLocationService } from './api/stockLocation.service';
import { TaxonomyService } from './api/taxonomy.service';
import { TxnLogService } from './api/txnLog.service';
import { UserService } from './api/user.service';
import { WishlistService } from './api/wishlist.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
