

<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
        <mat-form-field *ngIf="whereToPost === 'season'">
          <input type="text" matInput required id="name" name="name" [(ngModel)]="local_data.name"
            placeholder="name" readonly>
        </mat-form-field>
        <label for="">WEB: (required)</label>
        <file-pond
        #myPond
        *ngIf="pondOptions"
        [options]="pondOptions"
        [files]="pondFiles"
        (oninit)="pondHandleInit()"
        (onaddfile)="pondHandleAddFile($event, '')"
        (onprocessfile)="pondHandleProcessFile($event)"
        (onremovefile)="pondHandleRemoveFile($event, '')"
        >
        </file-pond>
        <label for="">MOBILE: (optional)</label>
        <file-pond
        #myPond2
        *ngIf="pondOptions && whereToPost === ''"
        [options]="pondOptions"
        [files]="pondFiles2"
        (oninit)="pondHandleInit()"
        (onaddfile)="pondHandleAddFile($event, '')"
        (onprocessfile)="pondHandleProcessFileMobile($event)"
        (onremovefile)="pondHandleRemoveFile($event, '')"
        >
        </file-pond>
      <mat-form-field *ngIf="whereToPost === ''">
          <input type="number" matInput required id="orderNo" name="orderNo" [(ngModel)]="local_data.orderNo"
            placeholder="orderNo">
        </mat-form-field>
      <mat-form-field *ngIf="whereToPost === 'season'">
        <input type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
          placeholder="title">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput id="bannerUrl" name="bannerUrl" [(ngModel)]="local_data.bannerUrl"
          placeholder="bannerUrl">
      </mat-form-field>
      <mat-form-field *ngIf="whereToPost === ''">
        <input type="text" matInput id="bannerMobileUrl" name="bannerMobileUrl" [(ngModel)]="local_data.bannerMobileUrls"
          placeholder="bannerMobileUrl">
      </mat-form-field>
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.message}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>

