import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './angular-material.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExcelService } from '../services/excel.service';
import { BlankComponent } from '../common/components/blank-component/BlankComponent';
import { LoaderComponent } from '../common/components/loader/loader.component';
import { AlertComponent } from '../common/components/alert/alert.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DialogComponent } from '../common/components/dialogs/dialog/dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexLayoutServerModule } from '@angular/flex-layout/server';
import { DialogsModule } from '../services/dialog/dialog.module';
import { MenuItems } from './menu-items/menu-items';
import { SpinnerComponent } from '../shared/spinner.component';
import { UserDialogContent,AddressDialogContent } from '../admin-user/admin-user.component';
import { ProductDialogContent, ProductAddDialogContent } from '../admin-products/product-add/product-add.component';
import { NotificationDialogContent } from '../admin-notification/admin-notification.component';
import { CurrencyDialogContent } from '../admin-currency/admin-currency.component';
import { ShippingDialogContent } from '../admin-shipping/admin-shipping.component';
import { PromotionDialogContent } from '../admin-promocode/admin-promocode.component';
import { BannerDialogContent } from '../admin-banner/admin-banner.component';
import { TaxonomyDialogContent } from '../admin-taxonomy/admin-taxonomy.component';
import { TrackingNoDialogContent } from '../admin-sales/admin-sales.component';
import { ReturnApproverDialogContent } from '../admin-sales-return/admin-sales-return.component';
import { ManageContentDialogContent } from '../admin-content/admin-content.component';
import { CreditDialogContent } from '../admin-credit-customer/admin-credit-customer.component';
import { CustomerSearchDialogContent } from '../admin-order/customer-search.dialog.component';
import { SelectProductDialogContent } from '../admin-order/select-product-dialog.component';
import { UpdateStockDialogContent } from '../admin-stock/admin-stock.component';

import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { ModalGeneralComponent } from '../../app/common/components/modal-general/modal-general.component';

import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { DateRangePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginImagePreview);

import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { ChartsModule } from 'ng2-charts';

import { EditorModule } from '@tinymce/tinymce-angular';
import { SafePipePipe } from '../safe-pipe.pipe';
import { SafeUrlPipe } from '../common/filter/safe-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    BlankComponent,
    LoaderComponent,
    AlertComponent,
    DialogComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    UserDialogContent,
    AddressDialogContent,
    ProductDialogContent,
    ProductAddDialogContent,
    NotificationDialogContent,
    CurrencyDialogContent,
    ShippingDialogContent,
    PromotionDialogContent,
    BannerDialogContent,
    TrackingNoDialogContent,
    TaxonomyDialogContent,
    ReturnApproverDialogContent,
    ManageContentDialogContent,
    CreditDialogContent,
    CustomerSearchDialogContent,
    SelectProductDialogContent,
    UpdateStockDialogContent,
    ModalGeneralComponent,
    SafePipePipe,
    SafeUrlPipe
  ],
  providers: [
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    ExcelService,
    NgxImageCompressService,
    MenuItems
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule ,
    CommonModule,
    AngularMaterialModule,
    DialogsModule,
    FlexLayoutModule,
    FilePondModule,
    ChartsModule,
    RouterModule,
    DateRangePickerModule,
    DateTimePickerModule,
    EditorModule,
    TranslateModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AngularMaterialModule,
    DialogsModule,
    FlexLayoutModule,
    FilePondModule,
    ChartsModule,
    FlexLayoutServerModule,
    LoaderComponent,
    AlertComponent,
    BlankComponent,
    ModalGeneralComponent,
    UserDialogContent,
    AddressDialogContent,
    ProductDialogContent,
    ProductAddDialogContent,
    NotificationDialogContent,
    CurrencyDialogContent,
    ShippingDialogContent,
    PromotionDialogContent,
    BannerDialogContent,
    TaxonomyDialogContent,
    TrackingNoDialogContent,
    UpdateStockDialogContent,
    ReturnApproverDialogContent,
    ManageContentDialogContent,
    CreditDialogContent,
    CustomerSearchDialogContent,
    SelectProductDialogContent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DateRangePickerModule,
    DateTimePickerModule,
    EditorModule,
    SafePipePipe,
    SafeUrlPipe,
    TranslateModule
  ],
  entryComponents: [
    AlertComponent,
    DialogComponent,
    UserDialogContent,
    AddressDialogContent,
    ProductDialogContent,
    ProductAddDialogContent,
    NotificationDialogContent,
    CurrencyDialogContent,
    ShippingDialogContent,
    PromotionDialogContent,
    BannerDialogContent,
    TaxonomyDialogContent,
    TrackingNoDialogContent,
    UpdateStockDialogContent,
    ReturnApproverDialogContent,
    ManageContentDialogContent,
    CreditDialogContent,
    CustomerSearchDialogContent,
    SelectProductDialogContent
  ],
})
export class SharedModule {
}
