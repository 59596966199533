<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="align-items-center">
          <div fxFlex="80">
            <!-- <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา">
              <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field> -->
          </div>
          <div fxFlex="20" class="text-center">
            <button mat-raised-button (click)="openDialog('Add', {})" color="primary">เพิ่ม content</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<app-blank *ngIf="this.contentLength === 0"></app-blank>
<div fxLayout="row wrap" *ngIf="this.contentLength !== 0">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> title </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.title }} 
              </td>
            </ng-container>
            
            <ng-container matColumnDef="content_type">
              <th mat-header-cell *matHeaderCellDef> ประเภท </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <div class="m-l-15">
                    <p class="font-bold m-b-0 m-t-0">{{ element.content_type }}</p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="orderNo">
              <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                {{ item.orderNo }} 
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-link">
                  <a (click)="openDialog('Update',element)" class="m-r-10 cursor-pointer text-dark"><mat-icon>edit</mat-icon></a>
                  <a (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer text-dark"><mat-icon style="color: #fc4b6c;">delete</mat-icon></a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="pagination" [length]="contentLength" [pageSize]="tableOptions.pageSize"
            [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
