/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LOGACTION = 'ADD' | 'WITHDRAW' | 'ORDER' | 'UPDATE_MEMBER' | 'CUSTOMER_RETURN' | 'CUSTOMER_RETURN_CANCELED' | 'ADMIN_CONFIRM_RETURN' | 'ADMIN_REJECT_RETURN';

export const LOGACTION = {
    ADD: 'ADD' as LOGACTION,
    WITHDRAW: 'WITHDRAW' as LOGACTION,
    ORDER: 'ORDER' as LOGACTION,
    UPDATEMEMBER: 'UPDATE_MEMBER' as LOGACTION,
    CUSTOMERRETURN: 'CUSTOMER_RETURN' as LOGACTION,
    CUSTOMERRETURNCANCELED: 'CUSTOMER_RETURN_CANCELED' as LOGACTION,
    ADMINCONFIRMRETURN: 'ADMIN_CONFIRM_RETURN' as LOGACTION,
    ADMINREJECTRETURN: 'ADMIN_REJECT_RETURN' as LOGACTION
};

