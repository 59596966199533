/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TAXTYPE = 'CATEGORY' | 'TAG' | 'COLOR' | 'COLLECTION' | 'BRAND';

export const TAXTYPE = {
    CATEGORY: 'CATEGORY' as TAXTYPE,
    TAG: 'TAG' as TAXTYPE,
    COLOR: 'COLOR' as TAXTYPE,
    COLLECTION: 'COLLECTION' as TAXTYPE,
    BRAND: 'BRAND' as TAXTYPE
};

