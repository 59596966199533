<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
      <mat-form-field>
        <input type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
          placeholder="title">
      </mat-form-field>

      <mat-form-field>
        <mat-label>เลือกประเภท</mat-label>
        <mat-select [(ngModel)]="local_data.content_type" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let dd of contentTypeList" [value]="dd.value">
            {{ dd.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field *ngIf="local_data.content_type === 'TEXT'">
        <textarea matInput required id="body" name="body" [(ngModel)]="local_data.body"
          placeholder="body"></textarea>
      </mat-form-field> -->

      <ng-container *ngIf="local_data.content_type === 'TEXT'">
        <label>English</label>
        <editor
        apiKey="fukxwabok6bqa0howwhwi0txrj7ydcuog1yj583e14tvbq6r"
        id="mytextarea33"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="local_data.body"
        initialValue="Type Here...."
        (onInit)="handleEvent($event)"
        (onNodeChange)="handleEvent2($event)"
        [init]="{
          selector: 'textarea',
          width: 900,
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | bold italic underline forecolor backcolor | \
          alignleft aligncenter alignright alignjustify'
        }"
      ></editor>
        <br>
      <label>Thai</label>
      <editor
      apiKey="fukxwabok6bqa0howwhwi0txrj7ydcuog1yj583e14tvbq6r"
      id="mytextarea5"
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="local_data.bodyTh"
      initialValue="Type Here...."
      (onInit)="handleEvent($event)"
      (onNodeChange)="handleEvent2($event)"
      [init]="{
        selector: 'textarea',
        width: 900,
        height: 600,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | bold italic underline forecolor backcolor | \
        alignleft aligncenter alignright alignjustify'
      }"
    ></editor>
      </ng-container>

      <mat-form-field *ngIf="local_data.content_type === 'LINK'">
        <input type="text" matInput required id="url" name="url" [(ngModel)]="local_data.url"
          placeholder="url">
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput required id="orderNo" name="orderNo" [(ngModel)]="local_data.orderNo"
          placeholder="orderNo">
      </mat-form-field>
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
