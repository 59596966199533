import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Api } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { AlertService  } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TableDataSource } from '../common/interfaces/common.interface';
import { PromotionService as ApiPromotionService } from '../../../client/api/promotion.service';
import {
  PROMOCLASS,
    PromotionPayload,
    PromotionReq,
    PromotionResponse,
    PromoVerify
  } from '../../../client';

@Injectable({
    providedIn: 'root'
})
export class PromotionService {
    constructor(
        private api: Api,
        private loadingService: LoaderService,
        private apiPromotionService: ApiPromotionService,
        private authService: AuthService,
        private alertSservice: AlertService,
        private router: Router,
    ) { }currency

    createPromotion(promotion: PromotionReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.createPromotion(promotion).pipe(share());
          seq.subscribe((res: PromotionResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    updatePromotion(id, data: PromotionReq) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.updatePromotion(id, data).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }
    
    deletePromotionById(id) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.deletePromotion(id).pipe(share());
          seq.subscribe((res) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getAllPromotion(tableOptions?: TableDataSource, promocodeClass?: PROMOCLASS) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.getAllPromotions(tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder, promocodeClass).pipe(share());
          seq.subscribe((res: PromotionResponse) => {
              if (res) {
                this.loadingService.showLoader(false);
                  resolve(res);
              } else {
                this.loadingService.showLoader(false);
                  reject();
              }
          }, err => {
            this.loadingService.showLoader(false);
            
              console.log(err);
              if (err && err?.error?.name === 'NoAuthorization') {
                this.authService.logout();
                this.router.navigateByUrl('login');
              }
              reject();
          });
      });
    }

    getPromotionBySearch(params?: string, tableOptions?: TableDataSource) {
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.getPromotionByName(params, tableOptions.pageNumber, tableOptions.pageSize, tableOptions.filter, tableOptions.sortOrder).pipe(share());
          // If the API returned a successful response, mark the user as logged in
          seq.subscribe((res: PromotionResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res) {
              this.loadingService.showLoader(false);
              resolve(res.payload);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            console.error('ERROR', err);
            this.loadingService.showLoader(false);
            // show error alert
            reject();
          });
        });
      }

      checkPromoCode(params?: string) {
        const data = {
          promo_code: params
        } as PromoVerify;
        this.loadingService.showLoader(true);
        return new Promise((resolve, reject) => {
          const seq = this.apiPromotionService.verifyCode(data).pipe(share());
          // If the API returned a successful response, mark the user as logged in
          seq.subscribe((res: PromotionResponse) => {
            // If the API returned a successful response, mark the user as logged in
            if (res) {
              this.loadingService.showLoader(false);
              resolve(res.payload.promotion);
            } else {
              this.loadingService.showLoader(false);
              reject();
            }
          }, err => {
            console.error('ERROR', err);
            this.loadingService.showLoader(false);
            // show error alert
            reject();
          });
        });
      }

}
