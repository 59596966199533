/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ORDERSTATUS = 'SUCCESS' | 'PENDING_DLVR' | 'CANCEL';

export const ORDERSTATUS = {
    SUCCESS: 'SUCCESS' as ORDERSTATUS,
    PENDINGDLVR: 'PENDING_DLVR' as ORDERSTATUS,
    CANCEL: 'CANCEL' as ORDERSTATUS
};

