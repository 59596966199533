import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CurrencyResponse, IBannerImg, ISeasonBanners, SettingsResponse} from '../../../client';
import {TableDataSource} from '../common/interfaces/common.interface';
import {SettingService} from '../services/setting.service';
import {AlertService} from '../services/alert.service';
import {environment} from '../../../src/environments/environment';
import {FilePondInitialFile} from 'filepond';
import {AuthService} from '../services/auth.service';
import {ProductService} from '../services/product.service';

@Component({
  selector: 'app-admin-banner',
  templateUrl: './admin-banner.component.html',
  styleUrls: ['./admin-banner.component.scss']
})
export class AdminBannerComponent implements OnInit {
  displayedColumns: string[] = ['index', 'image', 'url', 'action'];
  displayedColumns2: string[] = ['index', 'image', 'url', 'orderNo', 'action'];
  seasonDisplayedSColumns: string[] = ['index', 'name', 'title', 'image', 'url', 'action'];
  seasonDisplayedSColumns2: string[] = ['index', 'image', 'url', 'action'];
  dataSource = new MatTableDataSource();
  dataSourceSeason = new MatTableDataSource();
  dataSourceBottom = new MatTableDataSource();
  dataSourceGallery = new MatTableDataSource();
  pondOptions: any;
  mainBanners: IBannerImg[] = [];
  seasonBanners: ISeasonBanners;
  bottomBanner: IBannerImg;
  productImages = [];
  productImagesSeason = [];
  productImagesBottom = [];
  linkYoutube: string;
  imageGallery = [];
  selectIndex = 0;

  constructor(private dialog: MatDialog,
              private settingService: SettingService,
              private authService: AuthService,
              private productService: ProductService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.getBanner();
    this.setupFilePond();
  }

  private setupFilePond() {
    this.authService.getToken().then(token => {
      const pondOptionMaster: any = {
        dropOnPage: true,
        className: 'my-filepond',
        labelIdle: 'วางไฟล์ที่นี่',
        acceptedFileTypes: ['image/jpeg', 'image/png'],
        allowMultiple: true,
        name: 'file',
        server: {
          url: environment.backendUri,
          process: {
            // url: '/image/product/upload',
            url: '',
            method: 'POST',
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${token}`
            },
            timeout: 7000,
            onload: null,
            onerror: null,
            ondata: null
          }
        },
        allowImagePreview: true,
        imagePreviewMinHeight: 50,
        imagePreviewMaxHeight: 50,
      };

      this.pondOptions = pondOptionMaster;
      this.pondOptions['server']['process']['url'] = '/image/other/upload';
    });
  }


  async getBanner() {
    const data = await this.settingService.getAllBanner() as any;
    if (data && data.success) {
      this.mainBanners = data.payload.settings.mainBanners || [];
      this.seasonBanners = data.payload.settings.seasonBanners as ISeasonBanners;
      this.bottomBanner = data.payload.settings.bottomBanner;
      this.linkYoutube = data.payload.settings?.socialRefs?.yt_url;
      this.imageGallery = data.payload.settings?.socialRefs?.instaImages;
      this.setupData();
      this.setupDataForSeason();
      this.setupDataForBottom();
      this.setupGallery();
    }
  }

  setupData() {
    this.productImages = [];
    if (this.mainBanners) {
      for (const image of this.mainBanners) {
        const data = `${environment.backendUri}/image/` + image.img.id;
        const dataMobile = `${environment.backendUri}/image/` + image?.imgMobile?.id;
        this.productImages.push({
          loadImages: image.img,
          loadImagesMobile: image.imgMobile,
          img: data,
          imgMobile: dataMobile,
          bannerUrl: image.bannerUrl,
          bannerMobileUrl: image.bannerMobileUrl,
          orderNo: image.orderNo
        });
      }
      this.dataSource = new MatTableDataSource(this.productImages);
    }

  }

  setupDataForSeason() {
    this.productImagesSeason = [];

    if(!this.seasonBanners) {
      this.seasonBanners = {
          highlight: {
              title: 'highlight',
              img: null,
              bannerUrl: ''
          },
          seasonBanner1: {
              title: 'seasonBanner1',
              img: null,
              bannerUrl: ''
          },
          seasonBanner2: {
            title: 'seasonBanner2',
            img: null,
            bannerUrl: ''
          },
            seasonBanner3: {
              title: 'seasonBanner3',
              img: null,
              bannerUrl: ''
          },
          seasonBanner4: {
            title: 'seasonBanner4',
            img: null,
            bannerUrl: ''
        },
      }
    } 
    
    if (!!this.seasonBanners?.highlight?.img) {
      this.productImagesSeason.push({
        name: 'highlight',
        title: this.seasonBanners.highlight.title,
        loadImages: this.seasonBanners.highlight.img,
        img: `${environment.backendUri}/image/` + this.seasonBanners.highlight.img.id,
        bannerUrl: this.seasonBanners.highlight.bannerUrl
      })
    } else {
      this.productImagesSeason.push({
        name: 'highlight',
        title: '',
        loadImages: '',
        img: '',
        bannerUrl: ''
      })
    }

    if (!!this.seasonBanners?.seasonBanner1?.img) {
      this.productImagesSeason.push({
        name: 'seasonBanner1',
        title: this.seasonBanners.seasonBanner1.title,
        loadImages: this.seasonBanners.seasonBanner1.img,
        img: `${environment.backendUri}/image/` + this.seasonBanners.seasonBanner1.img.id,
        bannerUrl: this.seasonBanners.seasonBanner1.bannerUrl
      })
    } else {
      this.productImagesSeason.push({
        name: 'seasonBanner1',
        title: '',
        loadImages: '',
        img: '',
        bannerUrl: ''
      })
    }

    if (!!this.seasonBanners?.seasonBanner2?.img) {
      this.productImagesSeason.push({
        name: 'seasonBanner2',
        title: this.seasonBanners.seasonBanner2.title,
        loadImages: this.seasonBanners.seasonBanner2.img,
        img: `${environment.backendUri}/image/` + this.seasonBanners.seasonBanner2.img.id,
        bannerUrl: this.seasonBanners.seasonBanner2.bannerUrl
      })
    } else {
      this.productImagesSeason.push({
        name: 'seasonBanner2',
        title: '',
        loadImages: '',
        img: '',
        bannerUrl: ''
      })
    }

    if (!!this.seasonBanners?.seasonBanner3?.img) {
      this.productImagesSeason.push({
        name: 'seasonBanner3',
        title: this.seasonBanners.seasonBanner3.title,
        loadImages: this.seasonBanners.seasonBanner3.img,
        img: `${environment.backendUri}/image/` + this.seasonBanners.seasonBanner3.img.id,
        bannerUrl: this.seasonBanners.seasonBanner3.bannerUrl
      })
    } else {
      this.productImagesSeason.push({
        name: 'seasonBanner3',
        title: '',
        loadImages: '',
        img: '',
        bannerUrl: ''
      })
    }

    if (this.seasonBanners?.seasonBanner4?.img) {
      this.productImagesSeason.push({
        name: 'seasonBanner4',
        title: this.seasonBanners.seasonBanner4.title,
        loadImages: this.seasonBanners.seasonBanner4.img,
        img: `${environment.backendUri}/image/` + this.seasonBanners.seasonBanner4.img.id,
        bannerUrl: this.seasonBanners.seasonBanner4.bannerUrl
      })
    } else {
      this.productImagesSeason.push({
        name: 'seasonBanner4',
        title: '',
        loadImages: '',
        img: '',
        bannerUrl: ''
      })
    }
    

    this.dataSourceSeason = new MatTableDataSource(this.productImagesSeason);
  }

  setupDataForBottom() {
    this.productImagesBottom = [];
    if (this.bottomBanner) {
      const image = this.bottomBanner;
      const data = `${environment.backendUri}/image/` + image.img.id;
      this.productImagesBottom.push({
        loadImages: image.img,
        img: data,
        bannerUrl: image.bannerUrl
      });
    } else {
      this.productImagesBottom.push({
        loadImages: '',
        img: '',
        bannerUrl: ''
      });
    }
    this.dataSourceBottom = new MatTableDataSource(this.productImagesBottom);
  }

  setupGallery() {
    const imageGallery = [];
    if (this.imageGallery) {
      for (const image of this.imageGallery) {
        const data = `${environment.backendUri}/image/` + image.img.id;
        imageGallery.push({
          loadImages: image.img,
          img: data,
          bannerUrl: image.bannerUrl,
          orderNo: image.orderNo,
          title: image.title || 'test'
        });
      }
      this.imageGallery = imageGallery;
      this.dataSourceGallery = new MatTableDataSource(this.imageGallery);
    }

  }

  openDialog(action: string, obj: any, bywho) {
    obj.action = action;
    const dialogRef = this.dialog.open(BannerDialogContent, {
      data: [obj, this.pondOptions, bywho],
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!!result) {
        return;
      }
      if (result.event === 'Add') {
        this.addRowData(result);
      } else if (result.event === 'Update') {
        this.updateRowData(result);
      } else if (result.event === 'Delete') {
        this.deleteRowData(result);
      }
    });
  }

  async addRowData(row_obj: any) {
    if (row_obj.whereToPost === 'gallery') {
      const {data, files} = row_obj;
      const saveModel = {
        img: files[0],
        bannerUrl: data.bannerUrl,
        title: 'test'
      };
      let imageGallery = [];
      if(!!this.imageGallery) {
        imageGallery = this.imageGallery.map(it => {
          it.img = it.loadImages;
          return it;
        });
        imageGallery.push(saveModel);
      } else {
        imageGallery.push(saveModel);
      }
      const result = await this.settingService.updateSocial(this.linkYoutube, imageGallery) as SettingsResponse;
      if (result) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
        this.getBanner();
        this.selectIndex = 4;
      }
    } else {
      const {data, files, filesMobile} = row_obj;
      const saveModel = {
        img: files[0],
        imgMobile: filesMobile[0],
        bannerUrl: data.bannerUrl,
        bannerMobileUrl: data.bannerMobileUrl,
        orderNo: data.orderNo
      };

      this.mainBanners.push(saveModel);
      const result = await this.settingService.updateMainBanners({mainBanners: this.mainBanners}) as SettingsResponse;
      if (result) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
        this.mainBanners = result.payload.mainBanners;
        this.setupData();
      }
    }
  }

  updateRowData(row_obj: any) {
    if (row_obj.whereToPost === 'bottom') {
      this.updateBottomBanner(row_obj);
    } else if (row_obj.whereToPost === 'season') {
      this.updateSeasonBanner(row_obj);
    } else if (row_obj.whereToPost === 'gallery') {
      this.updateGallery(row_obj);
    } else {
      this.updateMainBanner(row_obj);
    }
  }

  async updateSeasonBanner(row_obj?: any) {
    const {data, files} = row_obj;
    const modelToSave = {
      img: files && !!files.length ? files[0] : data.loadImages,
      bannerUrl: data.bannerUrl,
      title: data.title
    }
    
    if (data.name === 'highlight') {
      this.seasonBanners.highlight = modelToSave;
    } else if (data.name === 'seasonBanner1') {
      this.seasonBanners.seasonBanner1 = modelToSave;
    } else if (data.name === 'seasonBanner2') {
      this.seasonBanners.seasonBanner2 = modelToSave;
    } else if (data.name === 'seasonBanner3') {
      this.seasonBanners.seasonBanner3 = modelToSave;
    } else if (data.name === 'seasonBanner4') {
      this.seasonBanners.seasonBanner4 = modelToSave;
    }

    if(!this.seasonBanners.highlight?.img) {
      this.seasonBanners.highlight = null;
    }
    if(!this.seasonBanners.seasonBanner1?.img) {
      this.seasonBanners.seasonBanner1 = null;
    }
    if(!this.seasonBanners.seasonBanner2?.img) {
      this.seasonBanners.seasonBanner2 = null;
    }
    if(!this.seasonBanners.seasonBanner3?.img) {
      this.seasonBanners.seasonBanner3 = null;
    }
    if(!this.seasonBanners.seasonBanner4?.img) {
      this.seasonBanners.seasonBanner4 = null;
    }

    const result = await this.settingService.updateSeasonBanners({seasonBanners: this.seasonBanners}) as SettingsResponse;
    if (result) {
      this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      this.seasonBanners = result.payload.seasonBanners;
      this.setupDataForSeason();
    }

    if (row_obj.files && !!row_obj.files.length) {
      const dataDelete = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (dataDelete) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      }
    }
  }

  async updateGallery(row_obj) {
    const {data, files} = row_obj;
    const mapDataToSave = this.imageGallery.map(it => {
      if (it.img.split('image/')[1].toString() === data.loadImages.id.toString()) {
        return {
          img: files && !!files.length ? files[0] : data.loadImages,
          bannerUrl: data.bannerUrl,
          orderNo: data.orderNo,
          title: data.title || 'test'
        }
      } else {
        it.img = it.loadImages;
        return it;
      }
    });
    const result = await this.settingService.updateSocial(this.linkYoutube, mapDataToSave) as any;
    if (result) {
      this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      this.getBanner();
      this.selectIndex = 4;
    }

    if (row_obj.files && !!row_obj.files.length) {
      const dataDelete = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (dataDelete) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      }
    }
  }

  async updateMainBanner(row_obj) {
    const {data, files, filesMobile} = row_obj;
    const mapDataToSave = this.mainBanners.map(it => {
      if (it.img.id === data.loadImages.id) {
        return {
          img: files && !!files.length ? files[0] : data.loadImages,
          imgMobile: filesMobile && !!filesMobile.length ? filesMobile[0] : data?.loadImagesMobile,
          bannerUrl: data.bannerUrl,
          bannerMobileUrl: data.bannerMobileUrl,
          title: data.title,
          orderNo: data.orderNo
        }
      } else {
        return it;
      }
    });

    const result = await this.settingService.updateMainBanners({mainBanners: mapDataToSave}) as SettingsResponse;
    if (result) {
      this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      this.mainBanners = result.payload.mainBanners;
      this.setupData();
    }

    if (row_obj.files && !!row_obj.files.length) {
      const dataDelete = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (dataDelete) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      }
    }
  }

  async updateBottomBanner(row_obj) {
    const {data, files} = row_obj;
    let mapDataToSave = this.bottomBanner;
    if(!!mapDataToSave) {
      mapDataToSave['bannerUrl'] = data.bannerUrl;
      if (files && !!files.length) {
        mapDataToSave['img'] = files[0];
      } else {
        mapDataToSave['img'] = data.loadImages;
      }
    } else {
      mapDataToSave = {
        bannerUrl: data.bannerUrl,
        img: files[0]
      } 
    }
    const result = await this.settingService.updateBottomBanner({bottomBanner: mapDataToSave}) as SettingsResponse;
    if (result) {
      this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      this.setupDataForBottom();
      this.bottomBanner = result.payload.bottomBanner;
    }

    if (row_obj.files && !!row_obj.files.length && !!row_obj.data?.loadImages?.id) {
      const dataDelete = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (dataDelete) {
        this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      }
    }
  }

  async saveYoutube() {
    let imageGallery = this.imageGallery.map(it => {
      it.img = it.loadImages;
      return it;
    });
    const result = await this.settingService.updateSocial(this.linkYoutube, imageGallery) as SettingsResponse;
    if (result) {
      this.alertService.success('คุณทำรายการสำเร็จแล้ว');
      this.getBanner();
    }
  }

  async deleteRowData(row_obj: any) {
    if (row_obj.whereToPost === 'gallery') {
      const result = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (result) {
        const {data, files} = row_obj;
        this.imageGallery = this.imageGallery.filter(it => it.img.split('image/')[1].toString() !== row_obj.data.loadImages.id.toString());
        const mapDataToSave = this.imageGallery.map(it => {
          if (it.img.split('image/')[1].toString() === data.loadImages.id.toString()) {
            return {
              img: files && !!files.length ? files[0] : data.loadImages,
              bannerUrl: data.bannerUrl,
              title: data.title
            }
          } else {
            it.img = it.loadImages;
            return it;
          }
        });
        const resultSave = await this.settingService.updateSocial(this.linkYoutube, mapDataToSave) as SettingsResponse;
        if (resultSave) {
          this.alertService.success('คุณทำรายการสำเร็จแล้ว');
          this.getBanner();
          this.selectIndex = 4;
        } else {
          this.alertService.error('คุณทำรายการไม่สำเร็จแล้ว');
        }
      } else {
        this.alertService.error('คุณทำรายการไม่สำเร็จแล้ว');
      }
    } else if(row_obj.whereToPost === '') {
      const result = await this.productService.deleteImageById(row_obj.data.loadImages.id);
      if (result) {
        const {data, files, filesMobile} = row_obj;
        this.mainBanners = this.mainBanners.filter(it => it.img.id !== row_obj.data.loadImages.id);
        const mapDataToSave = this.mainBanners.map(it => {
          if (it.img.id === data.loadImages.id) {
            return {
              img: files && !!files.length ? files[0] : data.loadImages,
              bannerUrl: data.bannerUrl,
              imgMobile: filesMobile && !!filesMobile.length ? filesMobile[0] : data.loadImagesMobile,
              bannerMobileUrl: data.bannerMobileUrl
            }
          } else {
            return it;
          }
        });
        const resultSave = await this.settingService.updateMainBanners({mainBanners: mapDataToSave}) as SettingsResponse;
        if (resultSave) {
          this.alertService.success('คุณทำรายการสำเร็จแล้ว');
          this.mainBanners = resultSave.payload.mainBanners;
          this.setupData();
        } else {
          this.alertService.error('คุณทำรายการไม่สำเร็จแล้ว');
        }
      } else {
        this.alertService.error('คุณทำรายการไม่สำเร็จแล้ว');
      }
    }
  }
}


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'banner-dialog',
  templateUrl: 'banner-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class BannerDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  filesAdd = [];
  filesAddMobile = [];
  @ViewChild('myPond') myPond: any;
  @ViewChild('myPond2') myPond2: any;
  pondOptions: any;
  pondFiles: Array<FilePondInitialFile | Blob | string> = [];
  pondFiles2: Array<FilePondInitialFile | Blob | string> = [];
  whereToPost;

  constructor(
    public dialogRef: MatDialogRef<BannerDialogContent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    this.local_data = {...data[0]};
    this.action = this.local_data.action;
    this.pondOptions = data[1];
    this.whereToPost = data[2];
    this.loadImages(this.local_data.loadImages);
    this.loadImagesMobile(this.local_data.loadImagesMobile);
  }


  loadImages(images) {
    if (!images) {
      return [];
    }

    this.pondFiles = [{
      source: images.id,
      options: {
        type: 'local',
        file: {
          name: images.original_fileName || images.file_name,
        },
        metadata: {
          poster: `${environment.backendUri}/image/` + images.id,
        },
      },
    }];
  }
  
  loadImagesMobile(images) {
    if (!images) {
      return [];
    }

    this.pondFiles2 = [{
      source: images.id,
      options: {
        type: 'local',
        file: {
          name: images.original_fileName || images.file_name,
        },
        metadata: {
          poster: `${environment.backendUri}/image/` + images.id,
        },
      },
    }];
  }

  pondHandleProcessFile(event: any) {
    this.filesAdd = [];
    console.debug('pondHandleProcessFile :', event);

    const {error, file, pond} = event;
    if (error) {
      console.error('Process file error :', error);
      return;
    }

    // Append file
    // Get file response -- filepond put it in serverId :(
    const fileResp = JSON.parse(file.serverId);
    if (!fileResp.success) {
      console.error('file upload resp error :', fileResp);
      return;
    }

    const {
      payload: {image},
    } = fileResp;

    this.filesAdd.push(image);

  }

  pondHandleProcessFileMobile(event: any) {
    this.filesAddMobile = [];
    console.debug('pondHandleProcessFileMobile :', event);

    const {error, file, pond} = event;
    if (error) {
      console.error('Process file error :', error);
      return;
    }

    // Append file
    // Get file response -- filepond put it in serverId :(
    const fileResp = JSON.parse(file.serverId);
    if (!fileResp.success) {
      console.error('file upload resp error :', fileResp);
      return;
    }

    const {
      payload: {image},
    } = fileResp;

    this.filesAddMobile.push(image);

  }


  pondHandleInit() {
  }

  pondHandleAddFile(event: any, action?: any) {
  }

  async pondHandleRemoveFile(event: any, action) {
  }


  doAction() {
    this.dialogRef.close({
      event: this.action,
      data: this.local_data,
      files: this.filesAdd,
      filesMobile: this.filesAddMobile,
      whereToPost: this.whereToPost
    });
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  addEvent(event) {
  }

  checkDisabled(data) {
    console.log(data);
    console.log(this.filesAdd);
    if (data.action === 'Add') {
      if (!!data.orderNo && this.filesAdd.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (data.action === 'Update' && data.whereToPost === '') {
      if (!!data.orderNo) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

}


