/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RETURNREASON = 'TOO_BIG' | 'TOO_SMALL' | 'PRODUCT_QUALITY' | 'PHOTO_DIFF' | 'WRONG_ITEM' | 'FAULTY_ITEM' | 'LATE_DELIVERY';

export const RETURNREASON = {
    TOOBIG: 'TOO_BIG' as RETURNREASON,
    TOOSMALL: 'TOO_SMALL' as RETURNREASON,
    PRODUCTQUALITY: 'PRODUCT_QUALITY' as RETURNREASON,
    PHOTODIFF: 'PHOTO_DIFF' as RETURNREASON,
    WRONGITEM: 'WRONG_ITEM' as RETURNREASON,
    FAULTYITEM: 'FAULTY_ITEM' as RETURNREASON,
    LATEDELIVERY: 'LATE_DELIVERY' as RETURNREASON
};

