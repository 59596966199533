/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PAYMENTSTATUS = 'SUCCESS' | 'PENDING' | 'CANCEL';

export const PAYMENTSTATUS = {
    SUCCESS: 'SUCCESS' as PAYMENTSTATUS,
    PENDING: 'PENDING' as PAYMENTSTATUS,
    CANCEL: 'CANCEL' as PAYMENTSTATUS
};

