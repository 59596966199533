<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="align-items-center">
          <div fxFlex="80">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหารายชื่อ">
              <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20" class="text-center m-l-10">
            <mat-form-field>
              <mat-label>ประเภทค้นหา</mat-label>
              <select matNativeControl (change)="selectedCustomerSearch($event)" [(ngModel)]="selectedCustomer">
                <option value="username">username</option>
                <option value="firstname">ชื่อ</option>
                <option value="lastname">นามสกุล</option>
                <option value="contactNum">เบอร์</option>
              </select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<app-blank *ngIf="this.userLength === 0"></app-blank>
<div fxLayout="row wrap" *ngIf="this.userLength !== 0">
<div fxFlex="100">
<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef> id </th>
          <td mat-cell *matCellDef="let item; let i = index;">  {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> ขื่อ </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <img class="mat-card-avatar" [src]='element.imagePath'> -->
              <div class="m-l-15">
                <p class="font-bold m-b-0 m-t-0">{{ element.firstName }} {{ element.lastName }}</p>
                <!-- <small>{{element.Position}}</small> -->
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef> ชื่อเล่น </th>
          <td mat-cell *matCellDef="let element"> {{element.nickName || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> เบอร์ </th>
          <td mat-cell *matCellDef="let element"> {{element.contactNum}} </td>
        </ng-container>

        <ng-container matColumnDef="lineid">
          <th mat-header-cell *matHeaderCellDef> email </th>
          <td mat-cell *matCellDef="let element"> {{element.email  }} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> วันที่สร้าง </th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/yyyy':'UTC+7'   }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> สถานะ </th>
          <td mat-cell *matCellDef="let element">  <mat-icon>{{ element.active ? 'check' : 'clear '}}</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="credit">
          <th mat-header-cell *matHeaderCellDef> total </th>
          <td mat-cell *matCellDef="let element">{{ element.creditTotal | number }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <a (click)="openCreditDialog(element)" class="m-r-10 cursor-pointer text-dark" *ngIf="element.creditTotal > 0"><mat-icon>open_in_new</mat-icon></a>
            <a (click)="openDialog(element)" class="m-r-10 cursor-pointer text-dark"><mat-icon>visibility</mat-icon></a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="pagination" [length]="userLength" [pageSize]="tableOptions.pageSize"
        [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
      </mat-paginator>
      <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
    </div>
  </mat-card-content>
</mat-card>
</div>
</div>
