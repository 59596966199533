/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DELIVERYMETHOD = 'DELIVERY' | 'PICK_UP' | 'MANUAL';

export const DELIVERYMETHOD = {
    DELIVERY: 'DELIVERY' as DELIVERYMETHOD,
    PICKUP: 'PICK_UP' as DELIVERYMETHOD,
    MANUAL: 'MANUAL' as DELIVERYMETHOD
};

