
<div class="flex-column-start text-left ps-5" style="height: 600px; overflow-y: auto;">
    <div class="position-relative w-100">
        <span class="position-absolute end-0" role="button">
          <span class="close-search" (click)="updateProfile()"><i
                    class="bi bi-x"></i></span>
        </span>
    </div>
    <ng-container *ngFor="let data of this.user.Addresses; let i = index;">
        <br>
        <h4 class="font20-re-66">{{ 'PROFILE.DETAIL.ADDRESS_TXT' | translate }} {{i+1}}</h4>
        <ng-container *ngIf="!data.isShow">
            {{ data.address }} {{ data.address2 }} {{ data.province }} {{ displayCountry(data?.country) }} {{ data.zipcode }}
            <div class="row w-100">
                <div class="col-12">
                    <div class="credit-box btn-green-black mt-4 w-100" (click)="onEditAddress(data)">
                        <label class="font16-re-ff cur-point">{{ 'PROFILE.DETAIL.EDIT_BTN' | translate }}</label>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="data.isShow" [formGroup]="addressForm">
            <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.ADDR_TXT' | translate }}</label>
            <input type="text" formControlName="address">
            <small *ngIf="!addressForm.get('address').valid" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
            <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.COUNTRY_TXT' | translate }}</label>
            <select formControlName="country">
                <option *ngFor="let country of countries;" value="{{ country.code }}">
                    {{ country.name }}
                </option>
            </select>
            <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.ADDRESS2_TXT' | translate }}</label>
            <input type="text" formControlName="address2">
            <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.PROVINCE_TXT' | translate }}</label>
            <input type="text" formControlName="province">
            <small *ngIf="!addressForm.get('province').valid" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
            <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.POSTAL_TXT' | translate }}</label>
            <input type="number" formControlName="zipcode" class="zipcode">
            <small *ngIf="!addressForm.get('zipcode').valid" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
            <div class="form-check mt-4">
                <input class="form-check-input rounded-0" type="checkbox" formControlName="isPrimaryAddress"
                    id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    <label class="font14-light-66">{{ 'PROFILE.DETAIL.SET_TXT' | translate }}</label>
                </label>
            </div>
            <!-- <span class="font14-re-66 mt-5 float-right pe-3 cur-point" (click)="onUpdateAddress(data)"><u>{{ 'PROFILE.DETAIL.UPDATE_BTN' | translate }}</u></span>
            <span class="font14-re-66 cur-point" (click)="onRemove(data)"><u>{{ 'PROFILE.DETAIL.DEL_BTN' | translate }}</u></span>
            <span class="font14-re-66 cur-point" (click)="onCancelAddress(data)" style="margin-bottom: 30px;"><u>{{ 'PROFILE.DETAIL.CANCEL_BTN' | translate }}</u></span> -->
            <div class="row w-100">
                <div class="col-6">
                    <div class="credit-box btn-green-black mt-4 w-100" (click)="onUpdateAddress(data)"  (mouseover)="isHover4 = true" [ngClass]="{'ishover': isHover4, 'btn-green-black-1': !isHover4 }" (mouseout)="isHover4 = false">
                        <label class="font16-re-ff cur-point">{{ 'PROFILE.DETAIL.UPDATE_BTN' | translate }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="credit-box btn-disable mt-4 w-100" (click)="onRemove(data)">
                        <label class="font16-re-ff cur-point" style="color: #666666;">{{ 'PROFILE.DETAIL.DEL_BTN' | translate }}</label>
                    </div>
                </div>
                <div class="col12">
                    <div (click)="onCancelAddress(data)" class="credit-box-1 mt-4 w-100 ishover"  (mouseover)="isHover5 = true" [ngClass]="{'ishover': !isHover5, 'btn-green-black-1': isHover5 }" (mouseout)="isHover5 = false">
                        <label class="font16-re-ff">{{ 'PROFILE.DETAIL.CANCEL_BTN' | translate }}</label>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isNew" [formGroup]="addressForm">
        <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.ADDR_TXT' | translate }}</label>
        <input type="text" formControlName="address">
        <small *ngIf="addressForm.get('address').hasError('required') && addressForm.get('address').touched" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
        <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.COUNTRY_TXT' | translate }}</label>
        <select formControlName="country">
            <option *ngFor="let country of countries;" value="{{ country.code }}">
                {{ country.name }}
            </option>
        </select>
        <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.ADDRESS2_TXT' | translate }}</label>
        <input type="text" formControlName="address2">
        <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.PROVINCE_TXT' | translate }}</label>
        <input type="text" formControlName="province">
        <small *ngIf="addressForm.get('province').hasError('required')  && addressForm.get('province').touched" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
        <label class="font12-re-99 mt-3">{{ 'PROFILE.DETAIL.POSTAL_TXT' | translate }}</label>
        <input type="number" formControlName="zipcode" class="zipcode">
        <small *ngIf="addressForm.get('zipcode').hasError('required')  && addressForm.get('zipcode').touched" class="text-danger support-text">{{ 'PROFILE.DETAIL.VALIDATION_TXT' | translate }}</small>
        <div class="form-check mt-4">
            <input class="form-check-input rounded-0" type="checkbox" formControlName="isPrimaryAddress"
                id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
                <label class="font14-light-66">{{ 'PROFILE.DETAIL.SET_TXT' | translate }}</label>
            </label>
        </div>
        <div class="row w-100">
            <div class="col-6">
                <div class="credit-box btn-green-black mt-4 w-100" (click)="onCreate()"  (mouseover)="isHover3 = true" [ngClass]="{'ishover': isHover3, 'btn-green-black-1': !isHover3 }" (mouseout)="isHover3 = false">
                    <label class="font16-re-ff cur-point" style="color: white;">{{ 'PROFILE.DETAIL.ADD_BTN' | translate }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="credit-box btn-disable mt-4 w-100" (click)="onCancel()">
                    <label class="font16-re-ff cur-point" style="color: #666666;">{{ 'PROFILE.DETAIL.CANCEL_BTN' | translate }}</label>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row w-100">
        <div class="col-12">
            <div class="credit-box btn-green-black mt-4 w-100" (click)="addAddress()">
                <label class="font16-re-ff cur-point">{{ 'PROFILE.DETAIL.ADD_ADDR_NEW_TXT' | translate }}</label>
            </div>
        </div>
    </div>
</div>