<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action != 'Delete'; else elseTemplate">
  <form #productForm="ngForm">
    <!-- <mat-form-field>
      <input type="text" matInput required id="firstName" name="firstName" [(ngModel)]="local_data.firstName"
        placeholder="ชื่อ">
    </mat-form-field> --> 
    <mat-form-field *ngIf="product_type === 'CLOTHES'">
        <mat-select placeholder="size" [(ngModel)]="local_data.size" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="data.value" *ngFor="let data of clothLists">
            {{ data.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="product_type !== 'CLOTHES'">
        <mat-select placeholder="size" [(ngModel)]="local_data.size" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="data" *ngFor="let data of shoeLists">
            {{ data }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="คลังสินค้า" [(ngModel)]="local_data.StockLocationId" [ngModelOptions]="{standalone: true}">
        <mat-option [value]="data.id" *ngFor="let data of stockLocationList">
          {{ data.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="number" placeholder="จำนวน" step="1" [(ngModel)]="local_data.quantity" [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <mat-form-field *ngIf="local_data.StockLocationId === 3">
      <input matInput type="number" placeholder="วันที่รอ preorder" step="1" [(ngModel)]="local_data.preOrderDays" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
