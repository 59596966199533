/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PRODUCTSTATUS = 'DRAFT' | 'PREVIEW' | 'SCHEDULE' | 'PUBLISH' | 'INACTIVE';

export const PRODUCTSTATUS = {
    DRAFT: 'DRAFT' as PRODUCTSTATUS,
    PREVIEW: 'PREVIEW' as PRODUCTSTATUS,
    SCHEDULE: 'SCHEDULE' as PRODUCTSTATUS,
    PUBLISH: 'PUBLISH' as PRODUCTSTATUS,
    INACTIVE: 'INACTIVE' as PRODUCTSTATUS
};

