/**
 * only-bu-BE
 * BE
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RETURNSTATUS = 'NONE' | 'OTW' | 'DONE' | 'CANCELLED' | 'REJECT';

export const RETURNSTATUS = {
    NONE: 'NONE' as RETURNSTATUS,
    OTW: 'OTW' as RETURNSTATUS,
    DONE: 'DONE' as RETURNSTATUS,
    CANCELLED: 'CANCELLED' as RETURNSTATUS,
    REJECT: 'REJECT' as RETURNSTATUS
};

