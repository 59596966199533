import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CurrencyResponse } from '../../../client';
import { TableDataSource } from '../common/interfaces/common.interface';
import { CurrencyService } from '../services/currency.service';
import { AlertService } from '../services/alert.service';
@Component({
  selector: 'app-admin-currency',
  templateUrl: './admin-currency.component.html',
  styleUrls: ['./admin-currency.component.scss']
})
export class AdminCurrencyComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'title', 'rate', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  currencyLength: any;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(private dialog: MatDialog,
              private currencyService: CurrencyService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getCurrency();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getCurrency(pageObj.pageIndex);
  }

  async getCurrency(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    const data = await this.currencyService.getAllCurrency(this.tableOptions) as CurrencyResponse;
    if(data && data.success) {
      this.dataSource = new MatTableDataSource(data.payload.currencies);
      this.dataSource._updateChangeSubscription();
      this.currencyLength = data.payload.listLength;
      if(this.tableOptions.pageNumber === 0) {
          this.paginator?.firstPage();
      }
    }
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
          this.getCurrency(0);
      }

    if (data.length > 2) {
      const result = await this.currencyService.getCurrencyBySearch(data, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.currencies);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          if (result.currencies.length === 0) {
              this.currencyLength = 0;
          } else {
              this.currencyLength = result.listLength;
          }
      } else {
          this.currencyLength = 0;
      }
    }
  }

  openDialog(action: string, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(CurrencyDialogContent, {
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        }
    });
  }

  // tslint:disable-next-line:variable-name
  async addRowData(row_obj: any) {
    const data = await this.currencyService.createCurrency(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getCurrency();
     }

  }
  // tslint:disable-next-line:variable-name
  async updateRowData(row_obj: any) {
    const data = await this.currencyService.updateCurrency(row_obj.id, row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getCurrency();
    }
  }
  // tslint:disable-next-line:variable-name
  async deleteRowData(row_obj: any) {
    const data = await this.currencyService.deleteCurrencyById(row_obj.id);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getCurrency();
    }
  }

}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'currency-dialog',
  templateUrl: 'currency-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class CurrencyDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  constructor(
      public dialogRef: MatDialogRef<CurrencyDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      this.local_data = { ...data };
      this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.status = event;
  }

  checkDisabled(data) {
    if (data.action === 'Add') {
        if (!!data.title && !!data.rate) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Update') {
      if (!!data.title && !!data.rate) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }
}


