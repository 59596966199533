<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
    <editor
    apiKey="fukxwabok6bqa0howwhwi0txrj7ydcuog1yj583e14tvbq6r"
    id="mytextarea34"
    [ngModelOptions]="{standalone: true}"
    [(ngModel)]="local_data.message"
    initialValue="Type Here...."
    (onInit)="handleEvent($event)"
    (onNodeChange)="handleEvent2($event)"
    [init]="{
      selector: 'textarea',
      width: 600,
      sheight: 300,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: 'undo redo | bold italic underline forecolor backcolor | \
      alignleft aligncenter alignright alignjustify'
    }"
  ></editor>
      <!-- <mat-form-field>
        <input type="text" matInput required id="message" name="message" [(ngModel)]="local_data.message"
          placeholder="message">
      </mat-form-field> -->
      <mat-form-field>
        <input type="text" matInput required id="url" name="url" [(ngModel)]="local_data.url"
          placeholder="url">
      </mat-form-field>
      <section class="example-section">
        <label style="margin: 0 10px;">status:</label>
        <mat-radio-group>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(true)" [checked]="local_data.active === true" value="true">Active</mat-radio-button>
          <mat-radio-button style="margin: 0 10px;" (change)="setAll(false)" [checked]="local_data.active === false" value="false">Deactive</mat-radio-button>
        </mat-radio-group>
      </section>
  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.message}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
