import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TableDataSource } from '../common/interfaces/common.interface';
import { PageEvent } from '@angular/material/paginator';
import { UserService } from '../services/user.service';
import { USER_TYPE } from '../common/constants';

@Component({
    selector: 'customer-search-dialog',
    templateUrl: 'customer-search-dialog.html',
  })
  export class CustomerSearchDialogContent {
    action: string;
    local_data: any;
    searchModel;
    tableOptions: TableDataSource;
    pageSizeOptions = ['10', '30', '50'];
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['select', 'name', 'surname', 'email', 'phone', 'status'];
    listLength: any;
    addressList;
    address;
    isAddress = false;
    selectedCustomer = 'username';
    searchText = null;
    isTaxAddress = false;
    constructor(
        public dialogRef: MatDialogRef<CustomerSearchDialogContent>,
        public userService: UserService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data) {
        const addressData = { ...data };
        this.isAddress = addressData[0];
        this.isTaxAddress = addressData[3] === 'taxAddress' ? true : false;
        let addressList = addressData[1];
        if(this.isTaxAddress) {
          const addr = [{
            id: 0,
            address: 'อื่นๆ',
            UserId: null,
            address2: null,
            addressFull: null,
            addressLang: "EN",
            country: null,
            province: null,
            zipcode: null,
          }];
          this.addressList = addressList.concat(addr);
          this.address = addressData[4];
        } else {
          this.addressList = addressList;
          this.address = addressData[2];
        }
        this.searchModel = {
          email: null,
          firstname: null,
          lastname: null,
          contactNum: null
        };
        this.setupTableOptions();
        this.fetchCustomerData();
    }
  
    setupTableOptions() {
      this.tableOptions = {
        pageNumber: 0,
        pageSize: 10,
        filter: null,
        sortOrder: 'desc'
      };
    }
  
    async fetchCustomerData(pageIndex?: number) {
      if (pageIndex) {
        this.tableOptions.pageNumber = pageIndex;
      } else {
        this.setupTableOptions();
      }
      const result = await this.userService.advanceSearchCustomer(this.searchModel, this.tableOptions) as any;
      if(!!result) {
        this.dataSource = new MatTableDataSource(result.users);
        this.listLength = result.userLength;
      }
    }

    async applyFilter(data) {
      this.setupTableOptions();
      if (data.length === 0) {
        this.searchModel = {
          email: null,
          firstname: null,
          lastname: null,
          contactNum: null
        };
        this.searchText = '';
        this.fetchCustomerData(0);
      }
  
      if (data.length > 2) {
          this.searchModel = {
            email: this.selectedCustomer === 'username' ? this.searchText : null,
            firstname: this.selectedCustomer === 'firstname' ? this.searchText : null,
            lastname: this.selectedCustomer === 'lastname' ? this.searchText : null,
            contactNum: this.selectedCustomer === 'contactNum' ? this.searchText : null
          };
      //   const result = await this.userService.getUserByTypeAndSearchData(USER_TYPE.CUSTOMER, data, '', '', this.tableOptions) as any;
      //   if (result) {
      //       this.dataSource = new MatTableDataSource(result.users);
      //       if (result.users.length === 0) {
      //           this.listLength = 0;
      //       } else {
      //           this.listLength = result.userLength;
      //       }
      //   } else {
      //       this.listLength = 0;
      //   }
        this.searchText = data;
        this.fetchCustomerData(0);
      }
      
    }
  
    onPageChanged(pageObj: PageEvent) {
      if (pageObj.pageIndex) {
        this.tableOptions.pageNumber = pageObj.pageIndex;
    } else {
        this.setupTableOptions();
    }
      this.fetchCustomerData(pageObj.pageIndex);
    }
  
    onSelectCustomer(item) {
      this.local_data = item;
    }
  
    doAction() {
      if(this.isAddress) {
        this.dialogRef.close({ event: 'SubmitAddress', data: this.address });
      } else {
        this.dialogRef.close({ event: 'Submit', data: this.local_data });
      }
    }
  
    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }
  
    handleChangeAddress(e, data) {
      this.address = data;
    }

    selectedCustomerSearch(e) {
      this.selectedCustomer = e.target.value;
      this.searchModel = {
        email: this.selectedCustomer === 'username' ? this.searchText : null,
        firstname: this.selectedCustomer === 'firstname' ? this.searchText : null,
        lastname: this.selectedCustomer === 'lastname' ? this.searchText : null,
        contactNum: this.selectedCustomer === 'contactNum' ? this.searchText : null
      };
      this.fetchCustomerData();
    }
  }
  