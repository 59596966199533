import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
    subchildren?: any;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMSFORADMIN = [
    {
        state: '',
        name: 'order',
        type: 'saperator',
        icon: ''
    },  
    {
        state: 'admin-order',
        name: 'สั่งซื้อสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'รายงาน',
        type: 'saperator',
        icon: ''
    },  
    {
        state: 'summary',
        name: 'dashboard',
        type: 'link',
        icon: ''
    },
    {
        state: 'sales-report',
        name: 'รายการส่งสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'สินค้า',
        type: 'saperator',
        icon: ''
    },
    {
        state: 'sales',
        name: 'รายการขาย',
        type: 'link',
        icon: ''
    },
    {
        state: 'sales-return',
        name: 'รายการขอคืน',
        type: 'link',
        icon: ''
    },
    {
        state: 'pending-payment',
        name: 'รายการยังไม่จ่าย',
        type: 'link',
        icon: ''
    },
    {
        state: 'stock',
        name: 'คลังสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: 'products',
        name: 'สินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'personal',
        type: 'saperator',
        icon: ''
    },
    {
        state: 'customer',
        name: 'สมาชิก',
        type: 'link',
        icon: ''
    },
    {
        state: 'credit',
        name: 'credit สมาชิก',
        type: 'link',
        icon: ''
    },
    {
        state: 'admin',
        name: 'พนักงาน',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'setting',
        type: 'saperator',
        icon: ''
    },
    {
        state: 'shipping',
        name: 'ค่าจัดส่ง',
        type: 'link',
        icon: ''
    },
    {
        state: 'currency',
        name: 'ค่าเงิน',
        type: 'link',
        icon: ''
    },
    {
        state: 'promocode',
        name: 'promocode',
        type: 'link',
        icon: ''
    },
    {
        state: 'notification',
        name: 'notification',
        type: 'link',
        icon: ''
    },
    {
        state: 'banner',
        name: 'banner',
        type: 'link',
        icon: ''
    },
    {
        state: 'newin',
        name: 'newin',
        type: 'link',
        icon: ''
    },
    {
        state: 'taxonomy',
        name: 'taxonomy',
        type: 'link',
        icon: ''
    },
    {
        state: 'content',
        name: 'content',
        type: 'link',
        icon: ''
    },
    {
        state: 'text/display',
        name: 'configuration',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'Logs',
        type: 'saperator',
        icon: ''
    }, {
        state: 'stock-log',
        name: 'stock',
        type: 'link',
        icon: ''
    },
    {
        state: 'score-log',
        name: 'คะแนน',
        type: 'link',
        icon: ''
    },
];


const MENUITEMSFORADMINSTAFF = [
    {
        state: '',
        name: 'order',
        type: 'saperator',
        icon: ''
    },  
    {
        state: 'admin-order',
        name: 'สั่งซื้อสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'สินค้า',
        type: 'saperator',
        icon: ''
    },
    {
        state: 'sales-report',
        name: 'รายการส่งสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: 'sales',
        name: 'รายการขาย',
        type: 'link',
        icon: ''
    },
    {
        state: 'sales-return',
        name: 'รายการขอคืน',
        type: 'link',
        icon: ''
    },
    {
        state: 'pending-payment',
        name: 'รายการยังไม่จ่าย',
        type: 'link',
        icon: ''
    },
    {
        state: 'stock',
        name: 'คลังสินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: 'products',
        name: 'สินค้า',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'personal',
        type: 'saperator',
        icon: ''
    },
    {
        state: 'customer',
        name: 'สมาชิก',
        type: 'link',
        icon: ''
    },
    {
        state: 'credit',
        name: 'credit สมาชิก',
        type: 'link',
        icon: ''
    },
    {
        state: '',
        name: 'Logs',
        type: 'saperator',
        icon: ''
    }, {
        state: 'stock-log',
        name: 'stock',
        type: 'link',
        icon: ''
    },
    {
        state: 'score-log',
        name: 'คะแนน',
        type: 'link',
        icon: ''
    }
];

@Injectable()
export class MenuItems {
    getMenuitemForAdmin(): Menu[] {
        return MENUITEMSFORADMIN;
    }

    getMenuitemForStaff(): Menu[] {
        return MENUITEMSFORADMINSTAFF;
    }
}
