import { Component, OnInit, Inject, Optional, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { AlertService } from '../services/alert.service';
import { USER_TYPE } from '../common/constants';
import {Router} from '@angular/router';
import { OrderService } from '../services/order.service';
import { ORDERSTATUS, PAYMENTSTATUS, SORTORDER, DELIVERYMETHOD, USERTYPE } from '../../../client';
import * as _ from 'lodash';
import { AuthService } from '../services/auth.service';
import { isBuffer } from 'lodash';
@Component({
  selector: 'app-admin-sales',
  templateUrl: './admin-sales.component.html',
  styleUrls: ['./admin-sales.component.scss']
})
export class AdminSalesComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index','date', 'name', 'customer', 'zipcode', 'price', 'orderDate', 'status', 'delivery', 'payment', 'promocode', 'credit', 'tracking', 'pickup', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  userLength: any;
  orderSum;
  selectedSort = 'invoiceId';
  selectedSortBy = SORTORDER.Desc;
  sortList = [{
    value: 'invoiceId',
    text: 'invoice'
  },{
    value: 'updatedAt',
    text: 'Last Updated'
  }, {
    value: 'confirmDate',
    text: 'Confirm Date'
  }];
  mapdata;
  selectedOrderStatus = '';
  selectedPayment = 'SUCCESS';
  selectedSource = '';
  selectedDelivery = '';
  isAdmin = false;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(public dialog: MatDialog,
    public router: Router,
    public alertService: AlertService,
    private authService: AuthService,
    public orderService: OrderService,
    public userService: UserService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getData();
    this.getUser();
  }

  async getUser() {
    const user = await this.authService.getLocalUser();
    if(!!user) {
      const userData = JSON.parse(user);
      if(userData.user_type === USERTYPE.ADMIN) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    }
  }

  async getData(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.tableOptions.pageNumber = 0;
    }
    const result = await this.orderService.getAllOrder(this.searchText, null, null, null,null, this.selectedPayment, this.tableOptions, this.selectedSource, this.selectedOrderStatus, this.selectedDelivery) as any;
    if(result && result.payload.orders.length > 0) {
      this.mapdata = result.payload.orders;
      let mapdata;
      if(!!result.payload.orders) {
        mapdata = result.payload.orders.map(it => {
          const addr = it.customer.Addresses.find(ff => ff.id === it.AddressId);
          return {
            id: it.id,
            date: it.updatedAt,
            confirmDate: it.confirmDate,
            name: it.invoiceId,
            customerName: `${it.customer?.firstName} ${it.customer?.lastName}`,
            zipCode: addr?.zipcode,
            customerId: it.customer?.id,
            price: it.total,
            orderDate: it.delivery.deliveryDate,
            active: it.orderStatus,
            paymentType: it.paymentType,
            paymentStatus: it.paymentStatus,
            payment: !!it.payment ? it.payment[0].channel : '-',
            creditTotal: it.creditTotal,
            trackingNo: it.trackingNo,
            invoiceId: it.invoiceId,
            delivery: it.delivery,
            orderSource: it.orderSource,
            promotion: it.promotion,
            isPre: it.orderProducts.some(ad => ad.stock?.StockLocation?.id === 3),
            pickupDate: it.pickupDate,
            isOnlineManual: it.isOnlineManual
          }
        });
        this.dataSource = new MatTableDataSource(mapdata);
      } else {
        mapdata = [];
        this.dataSource = new MatTableDataSource(null);
      }
      this.dataSource._updateChangeSubscription();
      this.userLength = result.payload.listLength;
      this.orderSum = result.payload.ordersSum;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
      }
    } else {
      this.dataSource = new MatTableDataSource(null);
      this.dataSource._updateChangeSubscription();
      this.userLength = 0;
      this.paginator?.firstPage();
    }
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortBy: this.selectedSort,
      sortOrder: this.selectedSortBy || SORTORDER.Desc
    };
  }

  gotoDetail(el) {
    this.router.navigate(['/dashboard/sales/'+el.id]);
  }

  gotoCustomerDetail(el) {
    this.router.navigate(['/dashboard/customer/'+el.customerId]);
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
          this.searchText = null;
          this.getData();
    } else {
      this.searchText = data;
    }

    if (data.length > 2) {
      const result = await this.orderService.getAllOrder(data,null,null,null, null,this.selectedPayment, this.tableOptions, this.selectedSource, this.selectedOrderStatus, this.selectedDelivery) as any;
      console.log(result);
      if (result) {
        if(result && result.payload.orders.length > 0) {
          this.mapdata = result.payload.orders;
          const mapdata = result.payload.orders.map(it => {
            const addr = it.customer.Addresses.find(ff => ff.id === it.AddressId);
            return {
              id: it.id,
              date: it.updatedAt,
              confirmDate: it.confirmDate,
              name: it.invoiceId,
              customerName: `${it.customer?.firstName} ${it.customer?.lastName}`,
              zipCode: addr?.zipcode,
              customerId: it.customer?.id,
              price: it.total,
              orderDate: it.delivery.deliveryDate,
              active: it.orderStatus,
              paymentType: it.paymentType,
              paymentStatus: it.paymentStatus,
              payment: !!it.payment ? it.payment[0]?.channel : '',
              creditTotal: it.creditTotal,
              trackingNo: it.trackingNo,
              invoiceId: it.invoiceId,
              delivery: it.delivery,
              orderSource: it.orderSource,
              promotion: it.promotion,
              isPre: it.orderProducts.some(ad => ad.stock?.StockLocation?.id === 3),
              pickupDate: it.pickupDate,
              isOnlineManual: it.isOnlineManual
            }
          });
          this.dataSource = new MatTableDataSource(mapdata);
          this.userLength = result.payload.listLength;
          this.orderSum = result.payload.ordersSum;
        }  else {
          this.dataSource = new MatTableDataSource();
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          this.userLength = 0;
        }
      } else {
        this.dataSource = new MatTableDataSource();
        this.dataSource._updateChangeSubscription();
        if(this.tableOptions.pageNumber === 0) {
          this.paginator?.firstPage();
        }
        this.userLength = 0;
      }
    }
  }

  openDialog(a, b) {

  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
        this.tableOptions.pageNumber = pageObj.pageIndex;
    }
    this.tableOptions.pageSize = pageObj.pageSize;
    this.getData(pageObj.pageIndex);
  }

  selectSort(e) {
    console.log(e.target.value);
    this.selectedSort = e.target.value;
    this.setupTableOptions();
    this.getData();
  }

  selectSortBy(e) {
    this.selectedSortBy = e.target.value;
    this.setupTableOptions();
    this.getData();
  }

  selectedDeliveryMethod(e) {
    this.selectedDelivery = e.target.value;
    this.setupTableOptions();
    this.getData();
  }

  addTracking(e) {
    console.log(e);
    const dialogRef = this.dialog.open(TrackingNoDialogContent, {
      data: [e.trackingNo, 'add', e.delivery]
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(!!!result || result.event === 'Cancel') {
        return;
      }

      if(e.delivery?.deliveryMethod !== 'PICK_UP') {
        const ddd = {
          orderId: e.id,
          trackingNo: result.data
         };
         const res = await this.orderService.updateOrderTracking(ddd);
         if(res) {
            this.getData();
         }
      } else {
        const ddd = {
          orderId: e.id,
          confirmPickupDate: result.data
         };
         const res = await this.orderService.updateOrderPickup(ddd);
         if(res) {
            this.getData();
         }
      }
    });
  }

  cancelOrder(e) {
    const dialogRef = this.dialog.open(TrackingNoDialogContent, {
      data: ['', 'delete']
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(!!!result || result.event === 'Cancel') {
        return;
      }
      const res = await this.orderService.cancelOrder(e.invoiceId, { cancelReason: result.data });
      if(res) {
        this.getData();
      }
    });
  }

  selectSortByed(e) {
    this.selectedOrderStatus = e.target.value;
    this.getData(0);
  }

  selectedPaymentBy(e) {
    this.selectedPayment = e.target.value;
    this.getData(0);
  }

  selectedSourceBy(e) {
    this.selectedSource = e.target.value;
    this.getData(0);
  }

}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'add-tracking-dialog',
  templateUrl: 'add-tracking-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class TrackingNoDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  trackingNo: string;
  status;
  deliveryMethod;
  pickupDate;
  constructor(
      public dialogRef: MatDialogRef<TrackingNoDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      this.local_data = { ...[data[0]] };
      this.status = data[1];
      this.deliveryMethod = data[2]?.deliveryMethod;
      this.action = this.local_data.action;

      if(this.status === 'add' && this.deliveryMethod === 'PICK_UP') {
        this.pickupDate = !!data[2]?.pickupDate ? data[2]?.pickupDate : new Date();
      } else {
        if(this.status === 'deleted') {
          this.trackingNo = 'cancel by admin';
        } else {
          this.trackingNo = data[0];
        }
      }
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.deliveryMethod === 'PICK_UP' ? this.pickupDate : this.trackingNo });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.active = event;
  }

  addEvent(event) {
    console.log(this.pickupDate);
  }

  checkDisabled(data) {
    if(this.deliveryMethod !== 'PICK_UP') {
      if (!!this.trackingNo) {
        return false;
      } else {
          return true;
      }
    } else {
      if (!!this.pickupDate) {
        return false;
      } else {
          return true;
      }
    }
  }

}
