import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {CLOTHE_SIZE, ALL_SHOE_SIZE, TAX_TYPE, PRODUCT_STATUS, PRODUCT_TYPE} from '../../common/constants/index';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {TaxonomyService} from '../../services/taxonomy.service';
import {TableDataSource} from '../../common/interfaces/common.interface';
import {StocksService} from '../../services/stock.service';
import {
    ISafeTaxonomy,
    StockLocationResponse,
    TaxonomyResponse,
    ISafeStockLocation,
    IStockLocation,
    ProductResponse,
    ProductPayload,
    SORTORDER
} from '../../../../client';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogsService} from '../../services/dialog/dialog.service';
import {AlertService} from '../../services/alert.service';
import {FilePondInitialFile} from 'filepond';
import * as FilePond from 'filepond';
import {environment} from '../../../environments/environment';
import {ProductService} from '../../services/product.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
// import FilepondPluginImagePreview from 'filepond-plugin-image-preview';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { keyframes } from '@angular/animations';

@Component({
    selector: 'app-product-add',
    templateUrl: './product-add.component.html',
    styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
    id;
    editMode: boolean;
    form: FormGroup;
    tagLists = [];
    clothLists = CLOTHE_SIZE;
    shoeLists = ALL_SHOE_SIZE;
    isLoading = false;
    stockLocationList: ISafeStockLocation[];
    taxCtrl = new FormControl();
    tagList = [];
    taxonomyList: ISafeTaxonomy[];
    // minDate = new Date(new Date().setDate(new Date().getDate()+1));
    minDate = new Date();
    stockLocations = [];

    @ViewChild('taxInput') taxInput: ElementRef<HTMLInputElement>;
    @ViewChild('taxauto') taxauto: MatAutocomplete;

    colorCtrl = new FormControl();
    colorLists = [];
    colorList: ISafeTaxonomy[] = [];
    filterColors: Observable<any[]>;
    @ViewChild('colorInput') colorInput: ElementRef<HTMLInputElement>;
    @ViewChild('colorauto') colorauto: MatAutocomplete;

    collectionCtrl = new FormControl();
    collectionLists = [];
    collectionList: ISafeTaxonomy[];
    @ViewChild('collectionInput') collectionInput: ElementRef<HTMLInputElement>;
    @ViewChild('collectionauto') collectionauto: MatAutocomplete;

    categoryCtrl = new FormControl();
    categoryLists = [];
    categoryList: ISafeTaxonomy[];
    @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
    @ViewChild('categoryauto') categoryauto: MatAutocomplete;

    brandCtrl = new FormControl();
    brandLists = [];
    brandList: ISafeTaxonomy[];
    @ViewChild('brandInput') brandInput: ElementRef<HTMLInputElement>;
    @ViewChild('brandauto') brandauto: MatAutocomplete;

    tableOptions: TableDataSource;
    displayedColumns: string[] = ['size', 'stock', 'quantity', 'preOrderDays', 'action'];
    dataSource = new MatTableDataSource();
    pondFiles: Array<FilePondInitialFile | Blob | string> = [];
    files = [];
    pondFiles11: Array<FilePondInitialFile | Blob | string> = [];
    files11 = [];
    pondFiles12: Array<FilePondInitialFile | Blob | string> = [];
    files12 = [];
    pondFiles13: Array<FilePondInitialFile | Blob | string> = [];
    files13 = [];
    pondFiles14: Array<FilePondInitialFile | Blob | string> = [];
    files14 = [];
    pondFiles15: Array<FilePondInitialFile | Blob | string> = [];
    files15 = [];
    pondFiles16: Array<FilePondInitialFile | Blob | string> = [];
    files16 = [];
    pondFiles17: Array<FilePondInitialFile | Blob | string> = [];
    files17 = [];
    pondFiles18: Array<FilePondInitialFile | Blob | string> = [];
    files18 = [];
    @ViewChild('myPond11') myPond11: any;
    @ViewChild('myPond12') myPond12: any;
    @ViewChild('myPond13') myPond13: any;
    @ViewChild('myPond14') myPond14: any;
    @ViewChild('myPond15') myPond15: any;
    @ViewChild('myPond16') myPond16: any;
    @ViewChild('myPond17') myPond17: any;
    @ViewChild('myPond18') myPond18: any;
    pondFiles2: Array<FilePondInitialFile | Blob | string> = [];
    files2 = [];
    @ViewChild('myPond2') myPond2: any;
    pondFiles3: Array<FilePondInitialFile | Blob | string> = [];
    files3 = [];
    @ViewChild('myPond3') myPond3: any;
    pondFiles4: Array<FilePondInitialFile | Blob | string> = [];
    files4 = [];
    @ViewChild('myPond3') myPond4: any;
    pondOptions: any;
    pondOptions2: any;
    pondOptions3: any;
    pondOptions4: any;
    relateCtrl = new FormControl();
    relateLists = [];
    relateList: ISafeTaxonomy[];
    @ViewChild('relateInput') relateInput: ElementRef<HTMLInputElement>;
    @ViewChild('relateauto') relateauto: MatAutocomplete;
    relatedProducts1: any;
    relatedProducts2: any;
    relatedProducts3: any;
    relatedProducts4: any;
    colorProducts1: any;
    colorProducts2: any;
    colorProducts3: any;
    colorProducts4: any;
    details = '';
    careInstruction = '';
    productImg = {
        pond11: 0,
        pond12: 0,
        pond13: 0,
        pond14: 0,
        pond15: 0,
        pond16: 0,
        pond17: 0,
        pond18: 0
    };

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                public dialogService: DialogsService,
                public alertService: AlertService,
                public router: Router,
                private taxonomyService: TaxonomyService,
                private productService: ProductService,
                private authService: AuthService,
                private stockService: StocksService,
                private snackBar: MatSnackBar) {
    }

    filter(name) {
        if(!!name && name.length > 0) {
            return this.colorList.filter(fruit =>
                fruit?.title?.toLowerCase().indexOf(name?.toLowerCase()) === 0);
        } else {
            return this.colorList;
        }
      }

    ngOnInit() {
        this.setupTableOptions();
        this.initialForm();
        this.getTagList();
        this.setupFilePond();
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.id = params.get('id');
            if (!!this.id) {
                this.getData(this.id);
                this.editMode = true;
            } else {
                this.getStockLocationList();
                this.editMode = false;
            }
        });
    }

    productStatusChange(event) {
        if(this.form.get('product_status').value === PRODUCT_STATUS.SCHEDULE && !this.form.get('publishDate').value) {
            this.form.get('publishDate').setValue(new Date());
        } else {
            this.form.get('publishDate').setValue(null);
        }
    }

    selectPromoEligibility(e) {
    }

    setupFilePond() {
        this.authService.getToken().then(token => {
            const pondOptionMaster: any = {
                dropOnPage: true,
                className: 'my-filepond',
                labelIdle: 'วางไฟล์ที่นี่',
                acceptedFileTypes: ['image/jpeg', 'image/png'],
                allowMultiple: true,
                name: 'file',
                server: {
                    url: environment.backendUri,
                    process: {
                        // url: '/image/product/upload',
                        url: '',
                        method: 'POST',
                        withCredentials: false,
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        timeout: 7000,
                        onload: null,
                        onerror: null,
                        ondata: null
                    }
                },
                allowImagePreview: true,
                styleItemPanelAspectRatio: 0.5,
                imageCropAspectRatio: 0.5
            };

            this.pondOptions = pondOptionMaster;
            this.pondOptions['server']['process']['url'] = '/image/product/upload';
            this.pondOptions2 = pondOptionMaster;
            this.pondOptions2['server']['process']['url'] = '/image/sizechart/upload';
            this.pondOptions2['imagePreviewMaxHeight'] = 250;
            this.pondOptions3 = pondOptionMaster;
            this.pondOptions3['server']['process']['url'] = '/image/modelsize/upload';
            this.pondOptions3['imagePreviewMaxHeight'] = 250;
            
        });
    }

    async getData(id) {
        await this.getStockLocationList();
        const result = await this.productService.getProductById(id, 4) as ProductResponse;
        if (result) {
            const data = result.payload.product;
            console.log(data);
            // this.form.patchValue(data);

            this.form.get('modelSizeText').setValue(data?.modelSizeText);
            this.form.get('sizeChartText').setValue(data?.sizeChartText);
            this.form.get('careInstruction').setValue(data?.careInstruction);
            this.form.get('product_status').setValue(data?.product_status);
            this.form.get('product_type').setValue(data?.product_type);
            this.form.get('price').setValue(data?.price);
            this.form.get('sku').setValue(data?.sku);
            this.form.get('title').setValue(data?.title);
            this.form.get('details').setValue(data?.details);
            this.form.get('publishDate').setValue(data?.publishDate);
            this.form.get('title').setValue(data?.title);

            if(data?.salesDiscount) {
                this.form.get('salesDiscount.discountAmt').setValue(data.salesDiscount?.discountAmt);
                this.form.get('salesDiscount.amt_type').setValue(data.salesDiscount?.amt_type);
            }
            this.stockLocations = data.Stocks;
            this.refreshStockLocation();
            this.colorLists = data.Colors;
            this.categoryLists = data.Cats;
            this.tagLists = data.Tags;
            this.collectionLists = data.Collections;
            this.brandLists = data.Brands;
            this.loadImages2(data.SizeChartImg);
            this.loadImages3(data.ModelSizeImg);
            this.form.get('modelSizeImg').setValue(data.ModelSizeImg);
            this.form.get('sizeChartImg').setValue(data.SizeChartImg);
            this.form.get('productImgs').setValue(data.ProductImgs);
            this.loadImages(this.form.get('productImgs').value);
            this.details = data.details;
            this.careInstruction = data.careInstruction;
            this.form.get('promoEligibility').setValue(!data?.promoEligibility);
            this.getRelatedData(data.id);
        }
    }

    async getRelatedData(id) {
        const resultColor = await this.productService.getProductColorById(id) as any;
        if(resultColor) {
          if(resultColor.payload.productRelatedColors.length) {
            this.colorProducts1 = resultColor.payload.productRelatedColors[0];
            this.colorProducts2 = resultColor.payload.productRelatedColors[1];
            this.colorProducts3 = resultColor.payload.productRelatedColors[2];
            this.colorProducts4 = resultColor.payload.productRelatedColors[3];
          } else {
            this.colorProducts1 = null;
            this.colorProducts2 = null;
            this.colorProducts3 = null;
            this.colorProducts4 = null;
          }
        }

        const resultRelated = await this.productService.getProductRelatedById(id) as any;
        if(resultRelated) {
          if(resultRelated.payload.productRelatedProducts.length) {
            this.relatedProducts1 = resultRelated.payload.productRelatedProducts[0];
            this.relatedProducts2 = resultRelated.payload.productRelatedProducts[1];
            this.relatedProducts3 = resultRelated.payload.productRelatedProducts[2];
            this.relatedProducts4 = resultRelated.payload.productRelatedProducts[3];
          } else {
            this.relatedProducts1 = null;
            this.relatedProducts2 = null;
            this.relatedProducts3 = null;
            this.relatedProducts4 = null;
          }
        }
    }

    loadImages3(images) {
        if (!images) {
            return;
        }

        this.pondFiles3 = [{
            source: images.id,
            options: {
                type: 'local',
                file: {
                    name: images.original_fileName || images.file_name,
                },
                metadata: {
                    poster: `${environment.backendUri}/image/` + images.id,
                },
            },
        }];
    }

    loadImages2(images) {
        if (!images) {
            return;
        }

        this.pondFiles2 = [{
            source: images.id,
            options: {
                type: 'local',
                file: {
                    name: images.original_fileName || images.file_name,
                },
                metadata: {
                    poster: `${environment.backendUri}/image/` + images.id,
                },
            },
        }];
    }

    loadImages(images) {
        if (!images) {
            return [];
        }
        
        if(!!images[0]) {
            this.pondFiles11 = [{
                source: images[0].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[0].original_fileName || images[0].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[0].id,
                    },
                },
            }];
            this.productImg.pond11 = images[0].order;
        }


        if(!!images[1]) {
            this.pondFiles12 = [{
                source: images[1].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[1].original_fileName || images[1].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[1].id,
                    },
                },
            }];
            this.productImg.pond12 = images[1].order;
        }

        if(!!images[2]) {
            this.pondFiles13 = [{
                source: images[2].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[2].original_fileName || images[2].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[2].id,
                    },
                },
            }];
            this.productImg.pond13 = images[2].order;
        }

        if(!!images[3]) {
            this.pondFiles14 = [{
                source: images[3].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[3].original_fileName || images[3].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[3].id,
                    },
                },
            }];
            this.productImg.pond14 = images[3].order;
        }

        if(!!images[4]) {
            this.pondFiles15 = [{
                source: images[4].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[4].original_fileName || images[4].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[4].id,
                    },
                },
            }];
            this.productImg.pond15 = images[4].order;
        }

        if(!!images[5]) {
            this.pondFiles16 = [{
                source: images[5].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[5].original_fileName || images[5].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[5].id,
                    },
                },
            }];
            this.productImg.pond16 = images[5].order;
        }

        if(!!images[6]) {
            this.pondFiles17 = [{
                source: images[6].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[6].original_fileName || images[6].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[6].id,
                    },
                },
            }];
            this.productImg.pond17 = images[6].order;
        }

        if(!!images[7]) {
            this.pondFiles18 = [{
                source: images[7].id,
                options: {
                    type: 'local',
                    file: {
                        name: images[7].original_fileName || images[7].file_name,
                    },
                    metadata: {
                        poster: `${environment.backendUri}/image/` + images[7].id,
                    },
                },
            }];
            this.productImg.pond18 = images[7].order;
        }
    }

    setupTableOptions() {
        this.tableOptions = {
            pageNumber: 0,
            pageSize: 10,
            filter: null,
            sortOrder: 'desc'
        };
    }

    initialForm() {
        this.form = this.formBuilder.group({
            modelSizeText: [''],
            modelSizeImg: [],
            sizeChartImg: [],
            sizeChartText: [''],
            stocks: [],
            categories: [],
            tags: [],
            colors: [''],
            brands: [],
            productImgs: [[]],
            collections: [],
            product_status: PRODUCT_STATUS.DRAFT,
            product_type: PRODUCT_TYPE.CLOTHES,
            price: ['', Validators.required],
            sku: ['', Validators.required],
            title: ['', Validators.required],
            details: [''],
            location: [''],
            relatedProducts: [],
            relatedColors: [],
            careInstruction: [''],
            publishDate: [null],
            promoEligibility: [''],
            salesDiscount: this.formBuilder.group({
                discountAmt: [0],
                amt_type: ['PERCENTAGE']
            })
        });

        console.log(this.form);
    }

    calDiscount() {
        const price = this.form.get('price').value || 0;
        const discount = this.form.get('salesDiscount.discountAmt').value || 0;

        const totalValue = price * ( (100-discount) / 100 );
        const discountValue = price - totalValue;
        return [discountValue, totalValue];
    }

    async getTagList() {
        const tableOption = {
            pageNumber: 0,
            pageSize: 500,
            filter: null,
            sortOrder: SORTORDER.Desc
        };
        const data = await this.taxonomyService.getAllTaxonomies(tableOption) as TaxonomyResponse;
        if (data) {
            this.taxonomyList = data.payload.taxonomies.filter(it => it.tax_type === TAX_TYPE.TAG);
            this.colorList = data.payload.taxonomies.filter(it => it.tax_type === TAX_TYPE.COLOR);
            this.categoryList = data.payload.taxonomies.filter(it => it.tax_type === TAX_TYPE.CATEGORY);
            this.collectionList = data.payload.taxonomies.filter(it => it.tax_type === TAX_TYPE.COLLECTION);
            this.brandList = data.payload.taxonomies.filter(it => it.tax_type === TAX_TYPE.BRAND);
        }
        this.filterColors = this.colorCtrl.valueChanges.pipe(
            startWith(null),
         map((fruit:any | null) => (fruit ? this.filter(fruit) : this.colorList)),
        );
    }

    async getStockLocationList() {
        const tableOptions = {
            pageNumber: 0,
            pageSize: 100,
            filter: null,
            sortOrder: SORTORDER.Desc
        };
        const data = await this.stockService.getStockLocation(tableOptions) as StockLocationResponse;
        console.log(data.payload.stockLocations)
        this.stockLocationList = data.payload.stockLocations.map(it => {
            return it;
        });
    }

    radioChange(e) {
        if (e) {
            if (this.stockLocations.length > 0) {
                const data = {
                    title: 'คำเตือน',
                    message: 'ข้อมูลที่เคยทำไว้จะหายทันที ? โอเคไหม?',
                    cancelText: 'cancel',
                    confirmText: 'ok'
                };
                this.dialogService.open(data);
                this.dialogService.confirmed().subscribe((result) => {
                    if (result) {
                        this.stockLocations = [];
                        this.dataSource = new MatTableDataSource();
                    }
                });
            }
        }
    }

    uploadDocument(e) {

    }

    uploadDocumentMore(e) {

    }

    openDialog(action: string, obj: any) {
        obj.action = action;
        const dialogRef = this.dialog.open(ProductDialogContent, {
            data: [obj, this.stockLocationList, this.form.get('product_type').value],
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!!result) {
                return;
            }
            if (result.event === 'Add') {
                this.addRowData(result.data);
            } else if (result.event === 'Update') {
                this.updateRowData(result.data);
            } else if (result.event === 'Delete') {
                this.deleteRowData(result.data);
            }
        });
    }

    async addRowData(row_obj: any) {
        const dup = this.stockLocations?.some(it => it.size === row_obj.size && it.StockLocationId === row_obj.StockLocationId);
        if (!!this.stockLocations && dup) {
            const data = {
                title: 'คำเตือน',
                message: 'คุณได้ทำการเพิ่มข้อมูลซ้ำ กรุณากรอกใหม่',
                cancelText: 'cancel',
                confirmText: 'ok'
            };
            this.dialogService.open(data);
            this.dialogService.confirmed().subscribe((result) => {
                if (result) {
                    return;
                }
                return;
            });
        } else {
            row_obj.id = !!!this.stockLocations ? 'new1' : 'new'+(this.stockLocations.length + 1);
            this.stockLocations.push(row_obj);
            console.log(this.stockLocations)
            this.alertService.success('ทำรายการสำเร็จ');
            this.refreshStockLocation();
        }
    }

    refreshStockLocation() {
        this.stockLocations = this.stockLocations.map(it => {
            const findModel = this.stockLocationList.find(dd => dd.id === it.StockLocationId);
            console.log(findModel)
            it['sizeDis'] = CLOTHE_SIZE.find(dd => dd.value === it.size).text;
            it.stockName = findModel.title;
            return it;
        });
        console.log(this.stockLocations)
        this.dataSource = new MatTableDataSource(this.stockLocations);
    }

    async updateRowData(row_obj: any) {
        console.log(row_obj);
        console.log(this.stockLocations);
        const find = this.stockLocations.find(it => it.id == row_obj.id);
        if (find) {
            find.size = row_obj.size;
            find['sizeDis'] = CLOTHE_SIZE.find(dd => dd.value === row_obj.size).text;
            find.quantity = row_obj.quantity;
            find.StockLocationId = row_obj.StockLocationId;
            find.preOrderDays = row_obj.preOrderDays;
        }
        ;
        this.alertService.success('ทำรายการสำเร็จ');
        this.refreshStockLocation();
    }

    async deleteRowData(row_obj: any) {
        this.stockLocations = this.stockLocations.filter(it => it.size !== row_obj.size);
        this.alertService.success('ทำรายการสำเร็จ');
        this.refreshStockLocation();
    }

    checkError() {
        let returnStatus = true;

        if(!this.colorLists || this.colorLists?.length === 0) {
            
            this.snackBar.open('กรุณาเพิ่ม color', '', {
                duration: 2000,
                panelClass: 'blue-snackbar',
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
            returnStatus = false;
        }

        // if(!this.collectionLists || this.collectionLists?.length === 0) {
           
        //     this.snackBar.open('กรุณาเพิ่ม collection', '', {
        //         duration: 2000,
        //         panelClass: 'blue-snackbar',
        //         horizontalPosition: this.horizontalPosition,
        //         verticalPosition: this.verticalPosition,
        //     });
        //     returnStatus = false;
        // }

        if(!this.categoryLists || this.categoryLists?.length === 0) {
            
            this.snackBar.open('กรุณาเพิ่ม category', '', {
                duration: 2000,
                panelClass: 'blue-snackbar',
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
            returnStatus = false;
        }

        if(!this.brandLists || this.brandLists?.length === 0) {
            
            this.snackBar.open('กรุณาเพิ่ม brand', '', {
                duration: 2000,
                panelClass: 'blue-snackbar',
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
            returnStatus = false;
        }

        return returnStatus;
    }

    onSubmit() {

        const isError = this.checkError();

        if(!isError) {
            return;
        } else {
            this.onSave();
        }

    }

    async onSave() {
        this.form.get('colors').setValue(this.colorLists);
        this.form.get('collections').setValue(this.collectionLists);
        this.form.get('categories').setValue(this.categoryLists);
        this.form.get('tags').setValue(this.tagLists);
        this.form.get('details').setValue(this.details);
        this.form.get('brands').setValue(this.brandLists);
        this.form.get('careInstruction').setValue(this.careInstruction);
        this.form.get('promoEligibility').setValue(!this.form.get('promoEligibility').value);
        const stocks = this.stockLocations.map(it => {
            if(it.id.toString().includes("new")) {
                return {
                    StockLocationId: it.StockLocationId,
                    quantity: it.quantity,
                    size: it.size,
                    preOrderDays: it.preOrderDays,
                    sortingKey: it.sortingKey
                }
            } else {
                if(!!!it.preOrderDays) {
                    delete it['preOrderDays'];
                }
                return it;
            }
        });
        this.form.get('stocks').setValue(stocks);

        if (this.editMode) {
            this.setRelatedProductToSave();
            this.setColorProductToSave();
            this.setProductToSave();
            
            if (this.files2.length) {
                const filessize = this.files2.map(it => (
                    {
                        ...it,
                        SizeChartImgId: it.id,
                        original_fileName: it.file_name,
                    }));
                this.form.get('sizeChartImg').setValue(filessize[0]);
            }

            if (this.files3.length) {
                const filesmodel = this.files3.map(it => (
                    {
                        ...it,
                        ModelSizeImgId: it.id,
                        original_fileName: it.file_name,
                    }));
                this.form.get('modelSizeImg').setValue(filesmodel[0]);
            }
            console.log(this.form.getRawValue());

            if(!this.form.get('salesDiscount.discountAmt').value) {
                this.form.get('salesDiscount.discountAmt').setValue(0);
            }

        try {
            const sub = await this.productService.updateProductById(this.id, this.form.getRawValue());
            if (sub) {
                this.router.navigate(['/dashboard/products']);
            }
        } catch (err) {
            console.log(err);
            if(err?.error?.code === 'not_unique_sku') {
                this.snackBar.open('sku ซ้ำกับเลขอื่น กรุณากรอกใหม่อีกครั้ง', '', {
                    duration: 2000,
                    panelClass: 'blue-snackbar',
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
            }
            this.form.get('promoEligibility').setValue(!this.form.get('promoEligibility').value);
        }
        } else {
            const files11 = this.files11.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond11
             }));

             const files12 = this.files12.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond12
             }));

             const files13 = this.files13.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond13
             }));

             const files14 = this.files14.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond14
             }));

             const files15 = this.files15.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond15
             }));

             const files16 = this.files16.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond16
             }));

             const files17 = this.files17.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond17
             }));

             const files18 = this.files18.map(it => (
                {
                    ...it,
                    original_fileName: it.file_name,
                    order: this.productImg.pond18
             }));

                
            const filessize = this.files2.map(it => (
                {
                    ...it,
                    SizeChartImgId: it.id,
                    original_fileName: it.file_name,
                }));

            const filesmodel = this.files3.map(it => (
                {
                    ...it,
                    ModelSizeImgId: it.id,
                    original_fileName: it.file_name,
                }));

                
            let files = [].concat(files11[0], files12[0], files13[0], files14[0], files15[0], files16[0], files17[0], files18[0]);
            console.log(files);
            files = files.filter(dd => dd);
            this.form.get('productImgs').setValue(files);
            this.form.get('sizeChartImg').setValue(filessize[0]);
            this.form.get('modelSizeImg').setValue(filesmodel[0]);
            this.form.get('relatedProducts').setValue([]);
            this.form.get('relatedColors').setValue([]);
            this.form.get('publishDate').setValue(new Date());

            if(!this.form.get('salesDiscount.discountAmt').value) {
                this.form.get('salesDiscount.discountAmt').setValue(0);
            }
            
            // Backend set promoEligibility as false
            // this.form.get('promoEligibility').setValue(!this.form.get('promoEligibility').value);

            try {
                const sub = await this.productService.createProduct(this.form.getRawValue());
                if (sub) {
                    this.router.navigate(['/dashboard/products']);
                }
            } catch (err) {
                console.log(err);
                if(err?.error?.code === 'not_unique_sku') {
                    this.snackBar.open('sku ซ้ำกับเลขอื่น กรุณากรอกใหม่อีกครั้ง', '', {
                        duration: 2000,
                        panelClass: 'blue-snackbar',
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                }
                this.form.get('promoEligibility').setValue(!this.form.get('promoEligibility').value);
            }
        }
    }

    setProductToSave() {
        const files11 = this.files11.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));

         const files12 = this.files12.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));


         const files13 = this.files13.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));


         const files14 = this.files14.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));

         const files15 = this.files15.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));

         const files16 = this.files16.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));


         const files17 = this.files17.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));


         const files18 = this.files18.map(it => (
            {
                ...it,
                // ProductId: it.id,
                original_fileName: it.file_name,
         }));


         let newArr = [];
         if(files11.length > 0) {
            files11[0].order = this.productImg.pond11;
            newArr.push(files11[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[0];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond11;
            }
            newArr.push(dataToSave);
         }

         if(files12.length > 0) {
            files12[0].order = this.productImg.pond12;
            newArr.push(files12[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[1];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond12;
            }
            newArr.push(dataToSave);
         }

         if(files13.length > 0) {
            files13[0].order = this.productImg.pond13;
            newArr.push(files13[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[2];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond13;
            }
            newArr.push(dataToSave);
         }

         if(files14.length > 0) {
            files14[0].order = this.productImg.pond14;
            newArr.push(files14[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[3];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond14;
            }
            newArr.push(dataToSave);
         }

         if(files15.length > 0) {
            files15[0].order = this.productImg.pond15;
            newArr.push(files15[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[4];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond15;
            }
            newArr.push(dataToSave);
         }

         if(files16.length > 0) {
            files16[0].order = this.productImg.pond16;
            newArr.push(files16[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[5];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond16;
            }
            newArr.push(dataToSave);
         }

         if(files17.length > 0) {
            files17[0].order = this.productImg.pond17;
            newArr.push(files17[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[6];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond17;
            }
            newArr.push(dataToSave);
         }

         if(files18.length > 0) {
            files18[0].order = this.productImg.pond18;
            newArr.push(files18[0]);
         } else {
            const dataToSave = this.form.get('productImgs').value[7];
            if(!!dataToSave) {
                dataToSave.order = this.productImg.pond18;
            }
            newArr.push(dataToSave);
         }

         newArr = newArr.filter(it => it);
         this.form.get('productImgs').setValue(newArr);
    }

    setRelatedProductToSave() {
        const newObj = [];
            if(!!this.relatedProducts1) {
                newObj.push(this.relatedProducts1);
            }
            if(!!this.relatedProducts2) {
                newObj.push(this.relatedProducts2);
            }
            if(!!this.relatedProducts3) {
                newObj.push(this.relatedProducts3);
            }
            if(!!this.relatedProducts4) {
                newObj.push(this.relatedProducts4);
            }
            console.log(newObj);
            this.form.get('relatedProducts').setValue(newObj);
    }

    setColorProductToSave() {
        const newObj = [];
        if(!!this.colorProducts1) {
            newObj.push(this.colorProducts1);
        }
        if(!!this.colorProducts2) {
            newObj.push(this.colorProducts2);
        }
        if(!!this.colorProducts3) {
            newObj.push(this.colorProducts3);
        }
        if(!!this.colorProducts4) {
            newObj.push(this.colorProducts4);
        }
        this.form.get('relatedColors').setValue(newObj);
    }

    addCollection(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const findDup = this.collectionLists.some(it => it.title === value);
        if (findDup) {
            this.categoryCtrl.setValue(null);
            return;
        }

        if ((value || '').trim()) {
            this.collectionLists.push({
                title: value.trim(),
                title_th: value.trim(),
                tax_type: TAX_TYPE.COLLECTION
            });
        }
        if (input) {
            input.value = '';
        }

        this.collectionCtrl.setValue(null);
    }

    removeCollection(data): void {
        this.collectionLists = this.collectionLists.filter(it => it !== data);
    }

    selectedCollection(event: MatAutocompleteSelectedEvent): void {
        const findDup = this.collectionLists.some(it => it.title === event.option.value.title);
        if (findDup) {
            this.collectionCtrl.setValue(null);
            return;
        }
        this.collectionLists.push(event.option.value);
        this.collectionInput.nativeElement.value = '';
        this.collectionCtrl.setValue(null);
    }


    addColor(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if(this.colorLists.length === 1) {
            if (input) {
                input.value = '';
            }
            return;
        }
        const findDup = this.colorLists.some(it => it.title === value);
        if (findDup) {
            this.colorCtrl.setValue(null);
            return;
        }

        if ((value || '').trim()) {
            this.colorLists.push({
                title: value.trim(),
                title_th: value.trim(),
                tax_type: TAX_TYPE.COLOR,

            });
        }
        if (input) {
            input.value = '';
        }

        this.colorCtrl.setValue(null);
    }

    removeColor(data): void {
        this.colorLists = this.colorLists.filter(it => it !== data);
    }

    selectedColor(event: MatAutocompleteSelectedEvent): void {
        const findDup = this.colorLists.some(it => it.title === event.option.value.title);
        if (findDup || this.colorLists.length > 0) {
            this.colorCtrl.setValue(null);
            return;
        }
        this.colorLists.push(event.option.value);
        this.colorInput.nativeElement.value = '';
        this.colorCtrl.setValue(null);
    }


    addCategory(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const findDup = this.categoryLists.some(it => it.title === value);
        if (findDup) {
            this.categoryCtrl.setValue(null);
            return;
        }

        if ((value || '').trim()) {
            this.categoryLists.push({
                title: value.trim(),
                title_th: value.trim(),
                tax_type: TAX_TYPE.CATEGORY
            });
        }
        if (input) {
            input.value = '';
        }

        this.categoryCtrl.setValue(null);
    }

    removeCategory(data): void {
        this.categoryLists = this.categoryLists.filter(it => it !== data);
    }

    selectedCategory(event: MatAutocompleteSelectedEvent): void {
        const findDup = this.categoryLists.some(it => it.title === event.option.value.title);
        if (findDup) {
            this.categoryCtrl.setValue(null);
            return;
        }
        this.categoryLists.push(event.option.value);
        this.categoryInput.nativeElement.value = '';
        this.categoryCtrl.setValue(null);
    }

    addBrand(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const findDup = this.brandLists.some(it => it.title === value);
        if (findDup) {
            this.brandCtrl.setValue(null);
            return;
        }

        if ((value || '').trim()) {
            this.brandLists.push({
                title: value.trim(),
                title_th: value.trim(),
                tax_type: TAX_TYPE.BRAND
            });
        }
        if (input) {
            input.value = '';
        }

        this.brandCtrl.setValue(null);
    }

    removeBrand(data): void {
        this.brandLists = this.brandLists.filter(it => it !== data);
    }

    selectedBrand(event: MatAutocompleteSelectedEvent): void {
        const findDup = this.brandLists.some(it => it.title === event.option.value.title);
        if (findDup) {
            this.brandCtrl.setValue(null);
            return;
        }
        this.brandLists.push(event.option.value);
        this.brandInput.nativeElement.value = '';
        this.brandCtrl.setValue(null);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const findDup = this.tagLists.some(it => it.title === value);
        if (findDup) {
            this.categoryCtrl.setValue(null);
            return;
        }

        if ((value || '').trim()) {
            this.tagLists.push({
                title: value.trim(),
                tax_type: TAX_TYPE.TAG
            });
        }
        if (input) {
            input.value = '';
        }

        this.taxCtrl.setValue(null);
    }

    remove(data): void {
        this.tagLists = this.tagLists.filter(it => it !== data);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const findDup = this.tagLists.some(it => it.title === event.option.value.title);
        if (findDup) {
            this.taxCtrl.setValue(null);
            return;
        }
        this.tagLists.push(event.option.value);
        this.taxInput.nativeElement.value = '';
        this.taxCtrl.setValue(null);
    }

    pondHandleInit() {
        // console.log('FilePond has initialised', this.myPond);
    }

    pondHandleAddFile(event: any, action?: any) {
        console.log(event);
    }


    pondHandleRemoveFile(event: any, action) {
        console.log(event);
        const id = event.file.getMetadata().poster.split('image/')[1];
        const options = {
            title: 'Are you sure to delete this?',
            message: 'Please select to confirm.',
            cancelText: 'cancel',
            confirmText: 'ok'
        };
        this.dialogService.open(options);
        this.dialogService.confirmed().subscribe(async (rr) => {
            if (rr) {
                const result = await this.productService.deleteImageById(id);
                if (result) {
                    console.log(result);
                    console.log(this.pondFiles);
                    this.alertService.success('คุณทำรายการสำเร็จแล้ว');
                    this.setupTableOptions();
                }
            }
        });
    }

    pondHandleProcessFile(event: any, action) {
        console.debug('pondHandleProcessFile :', event);

        const {error, file, pond} = event;
        if (error) {
            console.error('Process file error :', error);
            return;
        }

        // Append file
        // Get file response -- filepond put it in serverId :(
        const fileResp = JSON.parse(file.serverId);
        if (!fileResp.success) {
            console.error('file upload resp error :', fileResp);
            return;
        }

        const {
            payload: {image},
        } = fileResp;
        
        if (action === 2) {
            this.files2.push(image);
        } else if (action === 3) {
            this.files3.push(image);
        } else if (action === 11) {
            this.files11.push(image);
        }  else if (action === 12) {
            this.files12.push(image);
        }  else if (action === 13) {
            this.files13.push(image);
        }  else if (action === 14) {
            this.files14.push(image);
        } else if (action === 15) {
            this.files15.push(image);
        } else if (action === 16) {
            this.files16.push(image);
        } else if (action === 17) {
            this.files17.push(image);
        } else if (action === 18) {
            this.files18.push(image);
        }
    }

    selectProduct(val) {
        const dialogRef = this.dialog.open(ProductAddDialogContent, {
            minWidth: 900,
            data: [val, this.id]
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!!result) {
                return;
            }

            if(result.event) {
                console.log(result.data);
                if(result.data[1] === 0) {
                    this.relatedProducts1 = result.data[0];
                } else if(result.data[1] === 1) {
                    this.relatedProducts2 = result.data[0];
                } else if(result.data[1] === 2) {
                    this.relatedProducts3 = result.data[0];
                } else if(result.data[1] === 3) {
                    this.relatedProducts4 = result.data[0];
                } 
            }
        });
    }


    selectColorProduct(val) {
        const dialogRef = this.dialog.open(ProductAddDialogContent, {
            data: [val, this.id],
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!!result) {
                return;
            }

            if(result.event) {
                if(result.data[1] === 0) {
                    this.colorProducts1 = result.data[0];
                } else if(result.data[1] === 1) {
                    this.colorProducts2 = result.data[0];
                } else if(result.data[1] === 2) {
                    this.colorProducts3 = result.data[0];
                } else if(result.data[1] === 3) {
                    this.colorProducts4 = result.data[0];
                } 
            }
        });
    }

    handleEvent(e) {
        if(e.event) {
          setTimeout(() => 
            this.toDoSome()
          , 1000);
    
        }
      }
    
      handleEvent2(e) {
        setTimeout(() => 
          this.toDoSome()
      , 1000);
      }
    
      toDoSome() {
        $('.tox-notifications-container').attr('style', 'display:none')  // option 2
        $('.tox-notifications-container').attr('style', 'visibility:hidden') // option 3
        $('.tox-notifications-container').remove();  // option 4
        $('.tox-notifications-container').hide();   // option 5
      }

}

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'product-dialog',
    templateUrl: 'product-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class ProductDialogContent {
    action: string;
    // tslint:disable-next-line:variable-name
    local_data: any;
    clothLists = CLOTHE_SIZE;
    shoeLists = ALL_SHOE_SIZE;
    stockLocationList: IStockLocation[];
    product_type: string;
    forSearch = false;
    constructor(
        public dialogRef: MatDialogRef<ProductDialogContent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data) {
        this.forSearch = data[3];
        if(!this.forSearch) {
            this.local_data = {...data[0]};
            this.action = this.local_data.action;
            this.stockLocationList = data[1];
            this.product_type = data[2];
        }
    }

    doAction() {
        this.local_data['sizeDis'] = CLOTHE_SIZE.find(dd => dd.value === this.local_data.size).text;
        this.dialogRef.close({event: this.action, data: this.local_data});
    }

    closeDialog() {
        this.dialogRef.close({event: 'Cancel'});
    }

    setAll(event) {
        this.local_data.status = event;
    }

    checkDisabled(data) {
        if (data.action === 'Add' || data.action === 'Update') {
            if ((!!data.quantity || data.quantity === 0)&& !!data.StockLocationId && !!data.size) {
                if(data.StockLocationId === 3) {
                    if(data.preOrderDays > 0) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

}



@Component({
    // tslint:disable-next-line: component-selector
    selector: 'product-add-dialog',
    templateUrl: 'product-add-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class ProductAddDialogContent {
    local_data;
    displayedColumns: string[] = ['action', 'index', 'name'];
    dataSource = new MatTableDataSource();
    tableOptions: TableDataSource;
    pageSizeOptions = ['10', '30', '50'];
    listLength;
    selected;
    productId;
    @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
    constructor(
        public productService: ProductService,
        public dialogRef: MatDialogRef<ProductDialogContent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data) {
        this.local_data = data[0];
        this.productId = data[1];
        console.log(this.productId);
        this.setupTableOptions();
        this.getProduct();
    }

    setupTableOptions() {
        this.tableOptions = {
          pageNumber: 0,
          pageSize: 10,
          filter: null,
          sortOrder: 'desc'
        };
      }

    doAction() {
        this.dialogRef.close({event: true, data: [this.selected, this.local_data]});
    }

    async getProduct(pageIndex?: number) {
        if (pageIndex) {
            this.tableOptions.pageNumber = pageIndex;
          } else {
            this.setupTableOptions();
          }
        const result = await this.productService.getAllProduct(this.tableOptions)as ProductResponse;
        if (result.success) {
            const productList = result.payload.products.filter(it => it.id.toString() !== this.productId.toString());
            this.dataSource = new MatTableDataSource(productList);
            this.listLength = result.payload.listLength - 1;
          }

    }

    closeDialog() {
        this.dialogRef.close({event: false});
    }
    

    onPageChanged(pageObj: PageEvent) {
        if (pageObj.pageIndex) {
          this.tableOptions.pageNumber = pageObj.pageIndex;
      } else {
          this.setupTableOptions();
      }
        this.getProduct(pageObj.pageIndex);
      }

      async applyFilter(data) {
        this.setupTableOptions();
        if (data.length === 0) {
              this.getProduct(0);
          }
    
        if (data.length > 2) {
          const result = await this.productService.searchProductName(data, this.tableOptions) as ProductPayload;
          if (result) {
            const productList = result.products.filter(it => it.id.toString() !== this.productId.toString());
            this.dataSource = new MatTableDataSource(productList);
            this.dataSource._updateChangeSubscription();
            if(this.tableOptions.pageNumber === 0) {
                this.paginator?.firstPage();
            }
            if (result.products.length === 0) {
                this.listLength = 0;
            } else {
                this.listLength = result.listLength - 1;
            }
          } else {
              this.listLength = 0;
          }
        }
      }



    displayColor(color) {
        return !!color.Colors ? `(${color.Colors[0]?.title})` : '';
    }

}