<h2 mat-dialog-title>กรุณาเลือก :: <strong>สินค้า</strong></h2>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap">
        <div fxFlex="100">
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหาสินค้า">
                <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <app-blank *ngIf="this.listLength === 0"></app-blank>
    <div fxLayout="row wrap" *ngIf="this.listLength !== 0 && !isNoAction">
        <div fxFlex="100">
            <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
                    <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                        <input type="radio" value="item" [checked]="item.productObj.id === this.local_data?.id" 
                        [disabled]="item.stock.length === 0" (change)="onSelectProduct(item)" >
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef> code </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                        {{ item.sku }} 
                    </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef> name </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.title }} 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef> color </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.detail }} 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="stock">
                        <th mat-header-cell *matHeaderCellDef> size </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            <mat-form-field>
                                <mat-select placeholder="ขนาด" [ngModelOptions]="{standalone: true}"
                                [disabled]="!!!this.local_data || this.local_data.id !== item.id"
                                [(ngModel)]="item.size" (selectionChange)="onChangeSize($event.value)">
                                  <mat-option [value]="data" *ngFor="let data of item.stock;">
                                    {{ data.size }} ({{ data.quantity }})
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> price </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.price }} 
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator class="pagination" [length]="listLength" [pageSize]="tableOptions.pageSize"
                    [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
                </mat-paginator>
                </div>
            </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div fxLayout="row wrap" *ngIf="this.listLength !== 0 && isNoAction">
        <div fxFlex="100">
            <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
                    <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                        <input type="radio" value="item" 
                        [checked]="item.productObj.id === this.local_data?.id"  
                        (change)="onSelectProduct(item)" >
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef> code </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> 
                        {{ item.sku }} 
                    </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef> name </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.title }} 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef> color </th>
                        <td mat-cell *matCellDef="let item; let i = index;"> 
                            {{ item.detail }} 
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
                <mat-paginator class="pagination" [length]="listLength" [pageSize]="tableOptions.pageSize"
                    [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
                </mat-paginator>
                </div>
            </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisable()">Select</button>
    <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
  